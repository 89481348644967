import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { FaSpinner } from 'react-icons/fa'
import { config } from '../../Constants'

import { BASIC, PRO } from '../License'
import proPlan from '../../assets/images/pro_plan.svg'
import essentialsPlan from '../../assets/images/essentials.svg'
import { useAuth } from '../../providers/AuthProvider'
import ButtonMMM from '../ButtonMMM'

export default function PlanData({ onNext, storedData, setStoredData }) {
  const { t } = useTranslation()

  return (
    <Row className="justify-center">
      <Col className="mb-4" xs={12}>
        <div className="text-3xl font-bold text-center mb-2">
          {t('Essential Plan Trial Activated')}
        </div>
        <div className="text-center mb-3 text-sm">
          <span>{t('You are using a')}</span>{' '}
          <strong>{t('limited trial')}</strong>. {t('Want full access to all')}{' '}
          <strong>{t('Pro Plan features')}</strong>?{' '}
          <a
            className="text-white"
            href={
              'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Request Pro Plan access')}
          </a>{' '}
          {t('now')}.
        </div>
      </Col>
      <Col xs={'auto'} className="mb-14">
        <div className="min-w-[286px] max-w-[286px] border-1 border-white rounded-[24px] bg-billingBg px-[20px] py-6 relative min-h-full">
          <div className="absolute flex items-center justify-center h-[34px] top-[-17px] w-full ms-[-20px]">
            <div className="flex items-center justify-center font-bold text-[20px] h-[34px]  bg-[#FFBF00] text-black text-center px-2 rounded-[8px]">
              {t('ACTIVE TRIAL')}
            </div>
          </div>
          <Row className="mb-3 mt-3">
            <Col
              className="text-billingPurple text-[32px] font-bold flex flex-nowrap items-center"
              xs={12}
            >
              <img src={essentialsPlan} className="w-[50px] me-3" />
              ESSENTIAL
            </Col>
            <Col xs={12} className="mt-4 text-[16px] font-bold">
              {t("What's included")}
            </Col>
            {BASIC.map((v, i) => (
              <Col className="mt-2" key={i} xs={12}>
                <Row className="flex-nowrap">
                  <Col
                    xs="auto"
                    className="pe-0 max-w-[28px] min-w-[28px]  flex items-center"
                  >
                    <IoIosCheckmarkCircle size={28} color="white" />
                  </Col>
                  <Col
                    xs={12}
                    className="flex items-center text-[12px]"
                    style={{ maxWidth: 'calc(100% - 28px)' }}
                  >
                    {t(v)}
                  </Col>
                </Row>
              </Col>
            ))}
            <Col className="mt-3" xs={12}>
              <Row className="flex-nowrap">
                <Col
                  xs="auto"
                  className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                >
                  <IoIosCheckmarkCircle size={28} color="white" />
                </Col>
                <Col
                  xs={12}
                  className="flex items-center text-billingPurple underline text-[12px]"
                  style={{ maxWidth: 'calc(100% - 28px)' }}
                  as={Link}
                  to={
                    'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('15 min training call')}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col className="mb-14" xs={'auto'}>
        <div className="min-w-[286px] max-w-[286px] border-1 border-white rounded-[24px] bg-billingBg px-[20px] py-6 relative min-h-full">
          <div className="absolute flex items-center justify-center h-[34px] top-[-17px] w-full ms-[-20px]">
            <a
              className="flex items-center justify-center font-bold text-[20px] h-[34px]  bg-[#C300FF] text-black text-center px-2 rounded-[8px] !duration-100 hover:scale-105 active:scale-100 no-underline"
              href={
                'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('REQUEST ACCESS')}
            </a>
          </div>
          <Row className="mb-3 mt-3">
            <Col
              className="text-billingPurple text-[32px] font-bold flex flex-nowrap items-center"
              xs={12}
            >
              <img src={proPlan} className="w-[50px] me-3" />
              PRO
            </Col>
            <Col xs={12} className="mt-4 text-[16px] font-bold mb-1">
              {t("What's included")}
            </Col>
            {PRO.map((v, i) => (
              <Col className="mt-2 mb-1" key={i} xs={12}>
                <Row className="flex-nowrap">
                  <Col
                    xs="auto"
                    className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                  >
                    <IoIosCheckmarkCircle size={28} color="white" />
                  </Col>
                  <Col
                    xs={12}
                    className="flex items-center text-[12px]"
                    style={{ maxWidth: 'calc(100% - 28px)' }}
                  >
                    {t(v)}
                  </Col>
                </Row>
              </Col>
            ))}
            <Col className="mt-2 mb-2" xs={12}>
              <Row className="flex-nowrap">
                <Col
                  xs="auto"
                  className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                >
                  <IoIosCheckmarkCircle size={28} color="white" />
                </Col>
                <Col
                  xs={12}
                  className="flex items-center text-billingPurple underline text-[12px]"
                  style={{ maxWidth: 'calc(100% - 28px)' }}
                  as={Link}
                  to={
                    'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('30 min training call')}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="absolute flex items-center justify-center h-[34px] bottom-[-17px] w-full ms-[-20px]">
            <a
              className="flex items-center justify-center font-bold text-[17px] h-[34px]  bg-white text-black text-center px-4 py-4 rounded-full !duration-100 hover:scale-105 active:scale-100 no-underline"
              href={
                'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('REQUEST ACCESS')}
            </a>
          </div>
        </div>
      </Col>
      <Col xs={12} className="flex justify-between">
        <span>
          <Form.Check
            label={t(`Do not show this again`)}
            defaultChecked={storedData?.hidePlan ?? false}
            onChange={(e) => {
              setStoredData({
                ...storedData,
                hidePlan: e.target.checked,
              })
            }}
          />
        </span>
        <ButtonMMM onClick={onNext}>{t('Next')}</ButtonMMM>
      </Col>
    </Row>
  )
}
