import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Row, Col, Image, OverlayTrigger, Popover, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FcCheckmark, FcOk } from 'react-icons/fc'
import {
  FaChevronRight,
  FaMagnifyingGlass,
  FaPlus,
  FaRegCircle,
  FaSpinner,
} from 'react-icons/fa6'
import { ImSpinner9 } from 'react-icons/im'
import { RxCross1 } from 'react-icons/rx'
import { IoTrashOutline } from 'react-icons/io5'
import { useQuery, useQueryClient } from 'react-query'
import { MdError, MdSubdirectoryArrowRight } from 'react-icons/md'
import { useDebouncedCallback } from 'use-debounce'
import { toast } from 'react-toastify'

import ConnectorItem from './ConnectorItem'
import { config } from '../../Constants'
import { FaCheckCircle, FaFileAlt, FaUpload } from 'react-icons/fa'
import { getAccounts } from '../../services/dataslayer'
import { useAuth } from '../../providers/AuthProvider'
import UseTask from '../../hooks/UseTask'
import {
  coming_soon,
  special_connection_account_datasources,
  special_connection_datasources,
  single_hierarchy_connectors,
  special_id_accounts,
  special_connection_account_subaccount_datasources,
  datasource_uses_special_key_for_subaccounts,
  AVAILABLE_ENRICHMENTS,
  ESSENTIALS_KPIS,
} from './config'
import { checkLocation, deleteModelLocalFile } from '../../services/model'
import arrowWhite from '../../assets/images/arrow-white.gif'
import { CiLocationOn } from 'react-icons/ci'
import HelpTooltip from '../HelpTooltip'
import KpiEssentials from '../../assets/images/kpi_icon_essentials.svg'
import CustomSelect from '../CustomSelect'
import { countries } from './CountrySelector'

function compareAccountKey(acc1, acc2) {
  for (let i = 0; i < acc1.length; i++) if (acc1[i] !== acc2[i]) return false
  return true
}

function SubAccounts({
  datasource,
  superAccount,
  accounts,
  config,
  setConfig,
  selectedAccounts,
  onClick,
  filter,
}) {
  const { t } = useTranslation()
  const [activeSpecialItems, setActiveSpecialItems] = useState({})

  let singleHierarchy = false
  if (Array.isArray(accounts)) {
    singleHierarchy = true
    accounts = accounts.reduce(
      (acc, account) => {
        acc[''].push(account)
        return acc
      },
      { '': [] },
    )
  }

  if (filter && accounts) {
    const lwFilter = filter.toLocaleLowerCase()
    const filteredAccounts = {}
    Object.keys(accounts).forEach((k) => {
      const matches = accounts[k].filter((acc) =>
        `${acc?.name}`.toLocaleLowerCase().includes(lwFilter),
      )
      if (matches.length) filteredAccounts[k] = matches
      else if (`${k}`.toLocaleLowerCase().includes(lwFilter))
        filteredAccounts[k] = accounts[k]
    })
    accounts = filteredAccounts
  }

  const ActiveConfigItem =
    special_connection_account_subaccount_datasources[datasource?.api_entry]

  const isEmpty = !accounts || Object.keys(accounts).length === 0

  if (isEmpty)
    return (
      <Row className="ms-4 mt-1">
        <Col xs={12} className="text-base font-bold">
          {filter ? t('No accounts available') : t('No accounts match filter')}
        </Col>
      </Row>
    )

  return (
    <Row
      className="sub-accounts-import-dataslayer ms-4 mt-1"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {Object.keys(accounts).map((key, i) => (
        <Col key={key} xs={12} className="mb-2">
          <Row className="justify-end">
            <Col xs={12} className="font-bold text-base">
              {key}
            </Col>
            {Array.isArray(accounts[key]) &&
              accounts[key].map((account) => {
                const k =
                  datasource_uses_special_key_for_subaccounts?.[
                    datasource?.api_entry
                  ] ??
                  ((acc) => {
                    acc.id
                  })
                const subkey = k(account)

                return (
                  <Col
                    key={account.id}
                    xs={12}
                    style={{
                      maxWidth: `calc(100% - ${singleHierarchy ? 0 : 25}px)`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (ActiveConfigItem) {
                        setActiveSpecialItems({
                          ...activeSpecialItems,
                          [subkey]: !activeSpecialItems[subkey],
                        })
                      } else onClick(key, account)
                    }}
                  >
                    {ActiveConfigItem ? null : selectedAccounts?.[key]?.[
                        account?.id
                      ] ? (
                      <FcOk className="inline-block me-1" size={14} />
                    ) : (
                      <FaRegCircle
                        className="inline-block me-1 mb-1"
                        size={14}
                      />
                    )}
                    {account.name}
                    {ActiveConfigItem && activeSpecialItems[subkey] && (
                      <Row className="py-2">
                        <Col xs={12}>
                          <ActiveConfigItem
                            account={account}
                            config={
                              config?.[datasource.api_entry]?.[
                                superAccount.id
                              ]?.[subkey] ?? {}
                            }
                            setConfig={(newConfig) => {
                              config[datasource.api_entry] =
                                config[datasource.api_entry] ?? {}
                              config[datasource.api_entry][superAccount.id] =
                                config[datasource.api_entry][superAccount.id] ??
                                {}
                              newConfig.name = superAccount.id
                              newConfig.accountData = account
                              config[datasource.api_entry][superAccount.id][
                                subkey
                              ] = newConfig
                              setConfig({ ...config })
                              setActiveSpecialItems({
                                ...activeSpecialItems,
                                [subkey]: false,
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                )
              })}
          </Row>
        </Col>
      ))}
    </Row>
  )
}

function ConnectorAccount({
  openAccounts,
  setOpenAccounts,
  datasource,
  account,
  selectedAccounts,
  setSelectedAccounts,
  config,
  setConfig,
  filter,
}) {
  const { token } = useAuth()
  const active = openAccounts[datasource?.api_entry]?.[account.id]

  const { registerTask, getResult } = UseTask()

  const { data, isLoading } = useQuery(
    ['account-data', datasource.api_entry, account.id, active],
    async () => {
      if (!active) return null
      const res = await getAccounts({
        datasource: datasource.api_entry,
        connection: account.id,
        token,
      })
      if (res.ok) {
        const data = await res.json()
        if (data.task_id) {
          await new Promise((resolve, reject) => {
            registerTask({
              task_id: data.task_id,
              callback: resolve,
              error_callback: reject,
              errorMessage: 'Error fetching account data',
            })
          })
          const result = getResult(data.task_id)

          if (single_hierarchy_connectors.includes(datasource.api_entry)) {
            return Object.entries(result?.accounts ?? {}).reduce(
              (acc, [key, value]) => {
                acc[key] = Object.values(value)
                return acc
              },
              {},
            )
          } else if (special_id_accounts[datasource.api_entry]) {
            const key = special_id_accounts[datasource.api_entry]
            const accounts = result?.accounts ?? {}
            Object.keys(accounts?.[account.id] ?? {}).forEach((k) => {
              accounts[account.id][k].forEach((i) => {
                const id = i.id
                i.id = i[key]
                i[key] = id
              })
            })
          }
          return result?.accounts
        }
      }
      return null
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      retryOnMount: false,
      refetchIntervalInBackground: false,
    },
  )

  const ConfigElement =
    special_connection_account_datasources?.[datasource?.api_entry]

  return (
    <Col
      className={`text-sm  inline-block text-truncate mb-2 relative ${isLoading ? 'pointer-events-none opacity-65' : ''}`}
      xs={11}
      title={account.id}
      onClick={() => {
        openAccounts[datasource.api_entry] =
          openAccounts[datasource.api_entry] ?? {}
        openAccounts[datasource.api_entry][account.id] =
          !openAccounts[datasource.api_entry]?.[account.id]
        setOpenAccounts({ ...openAccounts })
      }}
    >
      <span className="pe-4">
        <span className="text-slate-400 hover:text-white">{account.id}</span>
      </span>
      {active &&
        !isLoading &&
        (ConfigElement ? (
          <ConfigElement
            config={config?.[datasource.api_entry]?.[account.id] ?? {}}
            setConfig={(newConfig) => {
              config[datasource.api_entry] = config[datasource.api_entry] ?? {}
              newConfig.name = account.id
              config[datasource.api_entry][account.id] = newConfig
              setConfig({ ...config })
              openAccounts[datasource.api_entry] =
                openAccounts[datasource.api_entry] ?? {}
              openAccounts[datasource.api_entry][account.id] = false
              setOpenAccounts({ ...openAccounts })
            }}
          />
        ) : (
          <SubAccounts
            datasource={datasource}
            superAccount={account}
            accounts={data?.[account.id]}
            config={config}
            setConfig={setConfig}
            filter={filter}
            selectedAccounts={
              selectedAccounts?.[datasource.api_entry]?.[account.id]
            }
            onClick={(key, subAccount) =>
              setSelectedAccounts(
                datasource.api_entry,
                account.id,
                key,
                subAccount,
              )
            }
          />
        ))}

      {isLoading ? (
        <ImSpinner9 size={15} className="absolute right-0 top-0 animate-spin" />
      ) : (
        <FaChevronRight
          size={17}
          className={`absolute right-0 top-0 duration-300 ${active ? 'rotate-90' : ''}`}
        />
      )}
    </Col>
  )
}

function getImage(datasource) {
  return datasource?.api_entry ?? datasource?.path
}

function Connector({
  datasource,
  forceName = null,
  selectedAccounts,
  setSelectedAccounts,
  customConfigurations,
  setCustomConfigurations,
  alter = false,
  itemProps = {},
  disabled = null,
  onNavigate,
  nameRegister,
}) {
  const { t } = useTranslation()
  const [openAccounts, setOpenAccounts] = useState({})
  const [filter, setFilter] = useState('')
  const updateFilter = useDebouncedCallback(setFilter, 300)

  const nameRef = useRef()
  const connectorProps = datasource.accounts.length
    ? {}
    : {
        onClick: () => {
          if (onNavigate) onNavigate()
          window.open(
            `${config.DATASLAYER_URL}/hub/${datasource?.path ?? datasource.name}?full=1`,
            '_blank',
          )
        },
      }

  const image = useMemo(() => getImage(datasource), [])

  const [count, singleAccount] = useMemo(() => {
    if (customConfigurations?.[datasource?.api_entry]) return [1, '']
    if (!datasource.accounts.length) return [0, '']
    const items = selectedAccounts?.[datasource.api_entry] ?? {}
    let userAccId = ''
    const docount = (data) => {
      if (typeof data === 'boolean') return data ? 1 : 0
      return Object.entries(data).reduce((acc, [id, item]) => {
        const count = docount(item)
        if (typeof item === 'boolean' && item) {
          userAccId = id
        }
        return acc + count
      }, 0)
    }
    const count = docount(items)
    return [count, nameRegister?.[userAccId]?.name]
  }, [selectedAccounts, datasource, customConfigurations])

  const ConfigElement = special_connection_datasources?.[datasource?.api_entry]

  const item = (
    <ConnectorItem
      {...itemProps}
      alter={alter}
      name={
        <Row
          className="inline-flex flex-nowrap justify-between w-full"
          ref={nameRef}
        >
          <Col xs="7" className="inline-flex flex-nowrap">
            {forceName || datasource.name}
          </Col>
          <Col xs="5" className="pe-0">
            {count === 1 && singleAccount && (
              <div
                className="text-sm font-bold inline-block whitespace-nowrap text-ellipsis overflow-hidden max-w-full min-w-full text-end"
                title={singleAccount}
              >
                {singleAccount}
              </div>
            )}
            {(count > 1 || (count === 1 && !singleAccount)) && (
              <div className="text-sm font-bold max-w-full min-w-full text-end">
                {count} Selected
              </div>
            )}
          </Col>
        </Row>
      }
      icon={
        <Image
          src={`/dataslayer_datasources/${image}.png`}
          width={alter ? 35 : 25}
        />
      }
      {...connectorProps}
    >
      <Row
        className="justify-center mt-1 mb-3"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {!special_connection_account_datasources?.[datasource?.api_entry] &&
          !ConfigElement && (
            <Col className="py-2" xs={11}>
              <div className="relative">
                <FaMagnifyingGlass
                  size={18}
                  style={{ top: 'calc(50% - 9px)' }}
                  className="absolute left-[10px]"
                />
                <Form.Control
                  className="text-input-mmm ps-[20px]"
                  defaultValue={filter}
                  onChange={(e) => updateFilter(e.target.value)}
                />
              </div>
            </Col>
          )}
        {ConfigElement
          ? openAccounts && (
              <Col xs={12} className="py-2">
                <Row>
                  <Col xl={10} md={11} xs={12} className="offset-1">
                    <ConfigElement
                      config={customConfigurations[datasource.api_entry]}
                      setConfig={(config) => {
                        setCustomConfigurations({
                          ...customConfigurations,
                          [datasource.api_entry]: config,
                        })
                        nameRef.current?.click()
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )
          : datasource?.accounts?.map((account) => (
              <ConnectorAccount
                key={account.id}
                datasource={datasource}
                account={account}
                openAccounts={openAccounts}
                setOpenAccounts={setOpenAccounts}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                config={customConfigurations}
                setConfig={setCustomConfigurations}
                filter={filter}
              />
            ))}
      </Row>
    </ConnectorItem>
  )

  const commingSoon = coming_soon.has(datasource?.api_entry)

  if (commingSoon)
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={({ ...props }) => (
          <Popover {...props} style={{ ...props.style, minWidth: '200px' }}>
            <div
              style={{
                width: '200px',
                backgroundColor: 'var(--mmm-background)',
                borderRadius: 5,
              }}
            >
              <div className="text-center py-1">{t('Coming soon')}</div>
            </div>
          </Popover>
        )}
      >
        <div>
          <div className="opacity-70 cursor-default pointer-events-none">
            {item}
          </div>
        </div>
      </OverlayTrigger>
    )

  if (disabled)
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={({ ...props }) => (
          <Popover {...props} style={{ ...props.style, minWidth: '200px' }}>
            <div
              style={{
                width: '200px',
                backgroundColor: 'var(--mmm-background)',
                borderRadius: 5,
              }}
            >
              {disabled}
            </div>
          </Popover>
        )}
      >
        <div>
          <div className="opacity-70 cursor-default pointer-events-none">
            {item}
          </div>
        </div>
      </OverlayTrigger>
    )

  return item
}

function FileErrors({ file }) {
  const { t } = useTranslation()
  const datetimeColumn = file?.columns?.find((c) => c.type === 'datetime')
  if (!datetimeColumn && file.status === 'processed')
    return (
      <span className="text-red-400 mx-1 text-sm mt-1">
        {t('The file does not include a valid datetime column')}
      </span>
    )
}

function ConnectorLocalFile({
  localFiles,
  isUploadingFiles,
  model,
  setFile,
  fileStatus,
  setFileStatus,
  alter = false,
  single = false,
  children,
}) {
  const queryClient = useQueryClient()
  const { token } = useAuth()
  const { t, i18n } = useTranslation()
  const inputRef = useRef()
  const [forceUpdate, setForceUpdate] = useState(0)

  const deleteFile = (i, f) => {
    localFiles?.splice(i, 1)
    setForceUpdate(forceUpdate + 1)
    deleteModelLocalFile({
      modelId: model.id,
      file: f.filename,
      token,
    }).then((r) => {
      if (r.ok) {
        queryClient.invalidateQueries(['model-local-files', model?.id])
        const filename = f.filename
        if (fileStatus?.active === filename) fileStatus.active = null
        if (fileStatus?.selected?.[0]?.[filename])
          delete fileStatus.selected[0][filename]
        if (fileStatus?.selected?.[1]?.[filename])
          delete fileStatus.selected[1][filename]
        if (fileStatus?.selected?.[2]?.[filename])
          delete fileStatus.selected[2][filename]
        if (fileStatus?.selectedDateCol?.[filename])
          delete fileStatus.selectedDateCol[filename]
        if (fileStatus?.selectedOption?.[filename])
          delete fileStatus.selectedOption[filename]
        setFileStatus({ ...fileStatus })
      } else toast.error(t('Error deleting file'))
    })
  }
  const taglessFiles = localFiles?.filter((f) => !f?.tag_type) ?? []

  return (
    <>
      <input
        type={'file'}
        ref={inputRef}
        accept=".csv,.xls,.xlsx,.xlsm,.xlsb,.odf,.ods,.odt,.json"
        className="hidden"
        onChange={(e) => {
          if (inputRef.current.files.length) {
            setFile([...inputRef.current.files])
            inputRef.current.value = []
          }
        }}
        multiple={!single}
      />
      {single && (localFiles?.length || isUploadingFiles) ? (
        <Col xs={12} className="pe-none opacity-50">
          <ConnectorItem
            alter={alter}
            name={
              <>
                {taglessFiles?.length
                  ? taglessFiles.length + ' ' + t('files selected')
                  : t('Upload your local file')}
              </>
            }
            icon={<FaUpload size={alter ? 35 : 25} />}
            onClick={() => inputRef.current.click()}
          />
        </Col>
      ) : (
        <ConnectorItem
          alter={alter}
          name={
            <>
              {taglessFiles?.length
                ? taglessFiles.length + ' ' + t('files selected')
                : t('Upload your local file')}
            </>
          }
          icon={<FaUpload size={alter ? 35 : 25} />}
          onClick={() => inputRef.current.click()}
        />
      )}
      {Array.isArray(localFiles) && (
        <Row>
          {isUploadingFiles ? (
            <Col className="inline-flex my-2 items-center" xs={12}>
              <FaSpinner size={25} className="animate-spin me-2" />
              {t('Uploading files')}
            </Col>
          ) : (
            (localFiles ?? [])
              .filter((f) => !f.tag_type)
              .map((f, i) => (
                <Col
                  key={i}
                  xs={12}
                  className="mt-2 inline-flex flex-nowrap items-center"
                >
                  <div className="max-w-10 min-w-10 inline-flex items-center justify-between pe-0">
                    <span className="bg-[#ddffdd] rounded-full shadow-sm">
                      <FaCheckCircle className="text-[#38b44b]" size={14} />
                    </span>
                    <FaFileAlt className="ms-2 " />
                  </div>
                  <div
                    style={{ maxWidth: 'calc(100% - 48px)' }}
                    className="inline-block text-truncate ps-0"
                  >
                    {f.name}
                  </div>
                  {single && <FileErrors file={f} />}
                  {f.status === 'not_processed' ? (
                    <div className="max-w-6 min-w-6 opacity-75">
                      <FaSpinner size={15} className="animate-spin  ms-2" />
                    </div>
                  ) : f?.status === 'processed' ? (
                    <div
                      xs={12}
                      className="max-w-6 min-w-6"
                      onClick={() => deleteFile(i, f)}
                    >
                      <IoTrashOutline
                        className="ms-1 text-[#b5304b] hover:scale-105 cursor-pointer active:scale-95 active:duration-75"
                        size={25}
                      />
                    </div>
                  ) : (
                    <>
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        placement="top"
                        delay={{ show: 100, hide: 100 }}
                        overlay={({ ...props }) => (
                          <Popover
                            {...props}
                            style={{ ...props.style, minWidth: '200px' }}
                          >
                            <div
                              style={{
                                width: '200px',
                                backgroundColor: 'var(--mmm-background)',
                                borderRadius: 5,
                              }}
                            >
                              <div className="text-center py-1">
                                {t('File could not be processed')}
                              </div>
                            </div>
                          </Popover>
                        )}
                      >
                        <div xs={12} className="mx-2">
                          <MdError className="text-red-500" size={25} />
                        </div>
                      </OverlayTrigger>
                      <div
                        xs={12}
                        className="max-w-6"
                        onClick={() => deleteFile(i, f)}
                      >
                        <RxCross1
                          className="ms-2 text-red-600 cursor-pointer active:scale-95 duration-75"
                          size={20}
                        />
                      </div>
                    </>
                  )}
                </Col>
              ))
          )}
        </Row>
      )}
      <Col xs={12}>{children}</Col>
      <Col className="text-sm mt-1" xs={12}>
        {t('Learn how your data format should be')}{' '}
        <a
          href={
            i18n.language === 'en'
              ? 'https://support.dataslayer.ai/understanding-the-uploaded-local-file-format-in-morpheus'
              : 'https://support.dataslayer.ai/es/entendiendo-el-formato-del-archivo-local-que-cargamos-en-morpheus'
          }
          target="_blank"
          rel="noreferrer"
        >
          {t('here')}
        </a>
        .
      </Col>
    </>
  )
}

function EnrichmentDatasourceSelector({
  enrichmentDatasources,
  setEnrichmentDatasources,
}) {
  const { t } = useTranslation()

  return (
    <Row className="px-3">
      {AVAILABLE_ENRICHMENTS.map(({ name, icon }) => (
        <Col xs={12} className="mb-2" key={name}>
          <Row
            onClick={() => {
              if (enrichmentDatasources[name]) {
                delete enrichmentDatasources[name]
                setEnrichmentDatasources({ ...enrichmentDatasources })
              } else {
                setEnrichmentDatasources({
                  ...enrichmentDatasources,
                  [name]: {},
                })
              }
            }}
            className={`connector-item py-2 px-2 rounded-3xl cursor-pointer bg-connectorItem hover:bg-connectorItemHover`}
          >
            <Col xs={12}>
              <Row className="connector-item-head inline-flex flex-nowrap">
                <Col xs={12} className="flex items-center justify-end ">
                  {icon}
                </Col>
                <Col
                  className="font-bold text-white flex items-center ps-0"
                  xs={12}
                >
                  {t(name)}
                </Col>
                <Col className="flex items-center px-0" xs={12}>
                  {enrichmentDatasources[name] ? (
                    <FcCheckmark
                      className="animate-[pulse_0.5s_ease-in-out_1]"
                      size={20}
                    />
                  ) : (
                    <FaPlus
                      className="animate-[pulse_0.5s_ease-in-out_1]"
                      size={20}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

const ALL_CONNECTORS = [
  { name: 'Google Sheets', path: 'google_sheets' },
  { name: 'Database', path: 'database' },
  { name: 'Google Trends', path: 'google_trends' },
  { name: 'HubSpot Basic', path: 'hubspot_basic' },
  { name: 'Stripe', path: 'stripe' },
  { name: 'Tik Tok', path: 'tiktok' },
  { name: 'Facebook', path: 'facebook' },
  { name: 'Microsoft Advertising', path: 'bing' },
  { name: 'DV360', path: 'dbm' },
  { name: 'DCM', path: 'dcm' },
  { name: 'Google Search Console', path: 'searchconsole' },
  { name: 'Criteo', path: 'criteo' },
  { name: 'YouTube', path: 'youtube' },
  { name: 'BigQuery', path: 'bigquery' },
  { name: 'JSON/CSV/XML', path: 'json_csv_xml' },
  { name: 'LinkedIn', path: 'linkedin' },
  { name: 'Google My Business', path: 'googlemybusiness' },
  { name: 'Instagram', path: 'instagram' },
  { name: 'Facebook Insights', path: 'facebookinsights' },
  { name: 'X Ads (Twitter)', path: 'twitter' },
  { name: 'Shopify', path: 'shopify' },
  { name: 'Mailchimp', path: 'mailchimp' },
  { name: 'Linkedin Pages', path: 'linkedinpages' },
  { name: 'Snapchat', path: 'snapchat' },
  //{ name: 'Instagram Public Data', path: 'instagram_public_data' },
  { name: 'WooCommerce', path: 'woocommerce' },
  { name: 'MongoDB', path: 'mongodb' },
  { name: 'Apple Search Ads', path: 'applesearchads' },
  { name: 'Google Ads', path: 'ads' },
  //{ name: 'Facebook Public Data', path: 'facebook_public_data' },
  { name: 'Google Analytics V4', path: 'analytics_v4' },
  { name: 'Pinterest', path: 'pinterest' },
  { name: 'TikTok Insights', path: 'tiktok_insights' },
  { name: 'X Insights Premium (Twitter)', path: 'twitter_insights' },
  { name: 'X Insights (Twitter)', path: 'twitter_organic' },
  { name: 'Klaviyo', path: 'klaviyo' },
  { name: 'Amazon ads', path: 'amazon_ads' },
  { name: 'Google Ad Manager', path: 'google_ad_manager' },
  { name: 'HubSpot Marketing Pro', path: 'hubspot' },
  { name: 'Search Ads 360 NEW', path: 'search_ads_360' },
]

function ProConnections({
  file,
  setFile,
  fileStatus,
  setFileStatus,
  selectedAccounts,
  setSelectedAccounts,
  connectors,
  nameRegister,
  countAccounts,
  customConfigurations,
  setCustomConfigurations,
  localFiles,
  isUploadingFiles,
  model,
  enrichmentDatasources,
  setEnrichmentDatasources,
  setMediaChannelDatasources,
  setContextVariables,
  setKpis,
  setFilterPlaceholder,
  filterValue,
}) {
  const { t } = useTranslation()
  const { planUsage, user } = useAuth()

  useEffect(() => {
    setFilterPlaceholder('Search datasources...')
    return () => setFilterPlaceholder('')
  }, [])

  const disabledByLimits = useMemo(() => {
    return (
      connectors?.reduce((acc, c) => {
        const maxAccounts =
          user?.plan_data?.morpheus_limits?.[`max_${c.api_entry}_accounts`] ??
          Infinity
        const usedAccounts = planUsage?.[c.api_entry]?.length ?? 0
        acc[c.api_entry] = {
          remaining: maxAccounts - usedAccounts,
          hardLimit:
            user?.plan_data?.morpheus_limits?.[
              `max_${c.api_entry}_accounts_per_model`
            ] ?? Infinity,
        }
        if (usedAccounts >= maxAccounts)
          acc[c.api_entry].disabled = (
            <div className="text-center p-2">
              {t('You have reached the limit of accounts for') + ` ${c.name}`}
            </div>
          )
        return acc
      }, {}) ?? {}
    )
  }, [planUsage, user])

  connectors = Array.isArray(connectors) ? connectors : []
  const suggestedConnectors = useMemo(() => {
    const suggested = ALL_CONNECTORS
    return suggested.map((s) => ({ ...s, accounts: [] }))
  }, [connectors])

  const updatedSelectedAccounts = (datasource, account, key, subaccount) => {
    const isSelected =
      !selectedAccounts?.[datasource]?.[account]?.[key]?.[subaccount.id]
    if (isSelected) {
      const currentCount = Object.entries(
        selectedAccounts?.[datasource] ?? {},
      ).reduce((acc, [account, data]) => {
        Object.values(data).forEach((key) => {
          acc += Object.values(key).filter((v) => v).length
        })
        return acc
      }, 0)
      if (currentCount + 1 > disabledByLimits?.[datasource]?.remaining) {
        toast.info(
          t(
            'Your current plan only has {{remaining}} accounts left for this datasource',
            { remaining: disabledByLimits?.[datasource]?.remaining },
          ),
        )
        return
      }
      if (currentCount + 1 > disabledByLimits?.[datasource]?.hardLimit) {
        {
          toast.info(
            t('You can select at max {{max}} accounts for this datasource', {
              max: disabledByLimits?.[datasource]?.hardLimit,
            }),
          )
          return
        }
      }
    }
    selectedAccounts[datasource] = selectedAccounts[datasource] ?? {}
    selectedAccounts[datasource][account] =
      selectedAccounts[datasource][account] ?? {}
    selectedAccounts[datasource][account][key] =
      selectedAccounts[datasource][account][key] ?? {}
    selectedAccounts[datasource][account][key][subaccount.id] = isSelected
    countAccounts.current += isSelected ? 1 : -1
    selectedAccounts[datasource][account][key] = {
      ...selectedAccounts[datasource][account][key],
    }

    try {
      //Datasource : account_name : subgroup : subaccount
      const noAccountsSelected = !Object.values(
        selectedAccounts[datasource] || {},
      )
        .map((v) => Object.values(v))
        .map((v) => Object.values(v))
        .flat()
        .map((v) => Object.values(v))
        .flat()
        .some((v) => v)

      if (noAccountsSelected) {
        setMediaChannelDatasources((c) => c.filter((v) => v !== datasource))
        setContextVariables((c) => ({ ...c, [datasource]: {} }))
        setKpis((c) => ({ ...c, [datasource]: {} }))
      }
    } catch (e) {
      console.error(e, 'Error updating selected accounts')
    }

    nameRegister[subaccount.id] = subaccount
    setSelectedAccounts({ ...selectedAccounts })
  }

  const filter = filterValue?.toLowerCase() ?? ''

  return (
    <Row>
      <Col xs={12}>
        <Row className="">
          <Col xs={6}>
            <Row className="max-h-full overflow-auto">
              <Col
                className="text-xl font-bold mb-4 flex justify-center"
                xs={12}
              >
                <span className="">{t('Detected Dataslayer Connectors')}</span>
              </Col>
              <Col xs={12}>
                <Row className="overflow-auto px-3 flex-col flex-nowrap min-h-96 max-h-96">
                  {!!connectors?.length &&
                    connectors.map((connector, i) => {
                      const match =
                        !filter || connector.name.toLowerCase().includes(filter)
                      return (
                        <Col
                          xs={12}
                          key={connector.api_entry}
                          className={`${match ? 'opacity-1 mb-2' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none'} duration-300`}
                        >
                          <Connector
                            datasource={connector}
                            selectedAccounts={selectedAccounts}
                            setSelectedAccounts={updatedSelectedAccounts}
                            customConfigurations={customConfigurations}
                            setCustomConfigurations={setCustomConfigurations}
                            disabled={
                              disabledByLimits[connector.api_entry]?.disabled
                            }
                            nameRegister={nameRegister}
                          />
                        </Col>
                      )
                    })}
                  {!connectors?.length && (
                    <>
                      <Col
                        className="min-h-[300px] flex flex-col items-center justify-center text-center text-sm overflow-hidden"
                        xs={12}
                      >
                        <span className="max-h-12 align-self-end max-w-full">
                          <Image
                            style={{
                              filter: 'blur(0.5px)',
                              transform:
                                'rotateY(180deg) rotateX(180deg) rotateZ(-20deg) translateY(60px) translateX(-30px)',
                            }}
                            className="max-h-40 max-w-full align-self-end"
                            src={arrowWhite}
                          />
                        </span>
                        <span>
                          {t(`Oops! You haven't connected any datasources yet`)}
                          .
                        </span>
                        <span>
                          {t(`Please`)} <strong>{t('sign in')}</strong>{' '}
                          {t('to start linking your connectors.')}
                        </span>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col xs={12}>
                    <Row className="overflow-auto px-3 flex-col flex-nowrap  mt-3">
                      <Col
                        className="text-xl font-bold flex justify-center mt-3"
                        xs={12}
                      >
                        <span className="">{t('Or...')}</span>
                      </Col>
                      <div>
                        <div className="mt-2">
                          <Col xs={12} className="mb-2">
                            <ConnectorLocalFile
                              localFiles={localFiles}
                              isUploadingFiles={isUploadingFiles}
                              model={model}
                              setFile={setFile}
                              fileStatus={fileStatus}
                              setFileStatus={setFileStatus}
                            />
                          </Col>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="max-h-full overflow-auto">
              <Col
                className="text-xl font-bold mb-4 flex justify-center"
                xs={12}
              >
                <span className="">{t('Recommended Conections')}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row className="min-h-96 max-h-96 overflow-auto px-3 flex-col flex-nowrap">
                  {suggestedConnectors.map((connector) => {
                    const match =
                      !filter || connector.name.toLowerCase().includes(filter)
                    return (
                      <Col
                        xs={12}
                        key={connector.name}
                        className={`${match ? 'opacity-1 mb-2' : 'max-h-0 overflow-hidden opacity-0 pointer-events-none'} duration-300`}
                      >
                        <Connector
                          datasource={connector}
                          selectedAccounts={selectedAccounts}
                          setSelectedAccounts={updatedSelectedAccounts}
                          nameRegister={nameRegister}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row className="mt-3">
                  <Col
                    className="text-xl font-bold flex justify-center mt-3"
                    xs={12}
                  >
                    <span className="">{t('Public Connections')}</span>
                  </Col>
                  <Col className="mt-2" xs={12}>
                    <EnrichmentDatasourceSelector
                      enrichmentDatasources={enrichmentDatasources}
                      setEnrichmentDatasources={setEnrichmentDatasources}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export function EssentialConnections({
  file,
  setFile,
  fileStatus,
  setFileStatus,
  selectedAccounts,
  setSelectedAccounts,
  connectors,
  nameRegister,
  countAccounts,
  customConfigurations,
  setCustomConfigurations,
  localFiles,
  isUploadingFiles,
  model,
  kpis,
  setKpis,
  essentialsLocation,
  setEnrichmentDatasources,
}) {
  const { t } = useTranslation()
  const { token, planUsage, user, isEssential } = useAuth()

  const maxGA4Accounts =
    user?.plan_data?.morpheus_limits?.max_analytics_v4_accounts ?? 0
  const usedGA4Accounts = planUsage?.analytics_v4?.length ?? 0

  connectors = Array.isArray(connectors) ? connectors : []
  const suggestedConnectors = useMemo(() => {
    const suggested = ALL_CONNECTORS
    return suggested.map((s) => ({ ...s, accounts: [] }))
  }, [connectors])

  let connector = connectors.find((c) => c.api_entry === 'analytics_v4')
  let suggestedConnector = suggestedConnectors.find(
    (c) => c.path === 'analytics_v4',
  )
  const isEditing = model?.model_visible === true

  const updatedSelectedAccounts = (datasource, account, key, subaccount) => {
    const value =
      !selectedAccounts?.[datasource]?.[account]?.[key]?.[subaccount.id]

    selectedAccounts[datasource] = {
      [account]: {
        [key]: {
          [subaccount.id]: value,
        },
      },
    }
    countAccounts.current = value ? 1 : 0
    nameRegister[subaccount.id] = subaccount
    if (value)
      setTimeout(
        () =>
          document
            .querySelector(
              '.datasource-essentials-connector.open-conector-item',
            )
            ?.click(),
        500,
      )
    setSelectedAccounts({ ...selectedAccounts })
  }

  const locationRef = useRef()
  const [location, setLocation] = useState(
    essentialsLocation?.current?.location ?? '',
  )
  const updateLocation = useDebouncedCallback((v) => setLocation(v), 500)

  const { data: locationData, isLoading: isLoadingLocation } = useQuery(
    ['location', location, token],
    async () => {
      if (!location) return null
      const check = await checkLocation({
        location,
        country_code: true,
        token,
      })
      if (check.ok) {
        const result = await check.json()
        if (result.ok) {
          essentialsLocation.current = {
            location,
            locationResolution: result.location,
            country_code: result.country_code,
          }
          const enrichments = {
            'Weather data': {
              kwargs: {
                location,
              },
              contextVariables: {
                tavg: true,
                tmin: true,
                tmax: true,
              },
            },
          }
          if (result.country_code)
            enrichments['Holidays data'] = {
              contextVariables: { holidays: true },
              kwargs: {
                country: {
                  value: result.country_code,
                  label:
                    countries.find(
                      ([name, code]) => code === result.country_code,
                    )?.[0] ?? location,
                },
              },
            }
          setEnrichmentDatasources(enrichments)
        } else {
          setEnrichmentDatasources({})
          essentialsLocation.current = null
        }
        return result
      }
      essentialsLocation.current = null
      return { ok: false, location: `${location} ${t('not found')}` }
    },
    { staleTime: Infinity },
  )
  useEffect(() => {
    if (locationData) locationRef?.current?.focus()
  }, [locationData])

  const kpi = Object.entries(kpis.analytics_v4 ?? {})?.find((v) => v[1])?.[0]
  let valueKPI = null
  if (kpi) {
    const target = ESSENTIALS_KPIS.find((v) => v.id === kpi)
    if (target) valueKPI = { label: target.name, value: target.id }
  }

  let disabled = null
  if (isEssential && usedGA4Accounts >= maxGA4Accounts)
    disabled = (
      <div className="text-center py-1">
        {t(
          'You have reached the maximum number of GA4 accounts allowed for your plan.',
        )}
      </div>
    )

  if (isEditing)
    disabled = (
      <div className="text-center py-1">
        {t('You cannot change the accounts of this model.')}
      </div>
    )

  const [autoOpen, setAutoOpen] = useState(false)

  return (
    <div
      className={`mx-20`}
      style={{ minHeight: `${window.innerHeight - 300}px` }}
    >
      <Row className="justify-center">
        <Col className="text-3xl text-center font-bold" xs={12}>
          {t('Connect your GA4 account')}
        </Col>
        <Col className="text-center text-sm my-3" xs={8}>
          {t(
            'To start building your attribution model, you’ll need to connect your Google Analytics 4 account for digital data and upload a local file for offline channels if you want to include this data in the analysis.',
          )}
        </Col>
        <Col className="mt-3" xs={12}>
          {connector ? (
            <Connector
              key={0}
              alter={true}
              datasource={connector}
              forceName={
                <span>
                  {connector.name}
                  <span className="ms-1 text-red-300 !text-xl inline-block !-translate-y-1">
                    *
                  </span>
                </span>
              }
              itemProps={{
                className: 'datasource-essentials-connector',
                autoOpen: autoOpen,
                maxH: 'max-h-[50vh]',
              }}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={updatedSelectedAccounts}
              customConfigurations={customConfigurations}
              setCustomConfigurations={setCustomConfigurations}
              disabled={disabled}
              nameRegister={nameRegister}
            />
          ) : (
            <Connector
              key={1}
              alter={true}
              forceName={
                <span>
                  {suggestedConnector.name}
                  <span className="ms-1 text-red-300 !text-xl inline-block !-translate-y-1">
                    *
                  </span>
                </span>
              }
              datasource={suggestedConnector}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={updatedSelectedAccounts}
              onNavigate={() => setAutoOpen(true)}
              nameRegister={nameRegister}
            />
          )}
        </Col>
        <Col xs={12} className="my-2 relative">
          <div>
            <HelpTooltip
              message={
                <div className="text-sm mt-1">
                  <strong>{t('Optional')}</strong>:{' '}
                  {t(
                    'Upload a CSV file containing data from offline marketing channels (e.g., TV, Radio, Print). Make sure your file includes essential information like date, channel name, and performance metrics such as spend or impressions.',
                  )}
                  <div>
                    <strong>
                      {t(
                        'Limited to the first 3 columns, excluding the date-type column.',
                      )}
                    </strong>
                  </div>
                </div>
              }
              className="ms-2 mt-1 absolute top-[10px] left-[-40px]"
              iconProps={{ size: 24, color: 'white' }}
            />
          </div>
          <ConnectorLocalFile
            alter={true}
            localFiles={localFiles}
            isUploadingFiles={isUploadingFiles}
            model={model}
            setFile={setFile}
            fileStatus={fileStatus}
            setFileStatus={setFileStatus}
            single={true}
          >
            <div className="text-sm mt-1">
              <i>
                {t(
                  'Limited to 3 data columns and only the last 12 months of information will be used.',
                )}
              </i>
            </div>
          </ConnectorLocalFile>
        </Col>
        <Col xs={12}>
          <Row className="justify-center mt-5">
            <Col
              className="mt-2 px-0 !max-w-[340px] !min-w-[340px] mx-3"
              xs={6}
            >
              <Row>
                <Col xs={12}>
                  <div className="flex flex-nowrap text-xl whitespace-nowrap items-center justify-center">
                    <span className="min-w-[30px] ms-1 me-2 relative">
                      {isLoadingLocation ? (
                        <FaSpinner className="animate-spin " size={30} />
                      ) : (
                        <CiLocationOn className="" size={30} />
                      )}
                    </span>
                    <strong>{t('Enter a location')}</strong>
                    <HelpTooltip
                      message={t(
                        '(Optional) We might need a location in order to consider that place as a contextual variable (weather or holidays).',
                      )}
                      className="ms-2 mt-1"
                      iconProps={{ size: 12, color: 'white' }}
                    />
                  </div>
                </Col>
                <Col className="mt-2 relative" xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Form.Control
                        className="bg-transparent border-1 !border-connectorSecondary !rounded-[1.5em] py-2 text-white"
                        defaultValue={location}
                        ref={locationRef}
                        onChange={(e) => updateLocation(e.target.value)}
                        disabled={isLoadingLocation}
                        placeholder={t('e.g. New York')}
                      />
                      <Col
                        xs={12}
                        className={`${locationData ? '' : 'opacity-0 user-select-none'}`}
                      >
                        {locationData && (
                          <span
                            className={`min-w-full inline-flex align-items-center text-xs ms-2 font-bold ${locationData?.ok ? 'text-green-500' : 'text-red-500'}`}
                          >
                            <MdSubdirectoryArrowRight />
                            {locationData?.location}
                          </span>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              className="mt-2 px-0 !max-w-[340px] !min-w-[340px] mx-3"
              xs={6}
            >
              <Row className="flex-col  h-full">
                <Col xs={12}>
                  <div className="flex flex-nowrap text-xl whitespace-nowrap items-center justify-center">
                    <img src={KpiEssentials} width={30} className="me-2 " />
                    <strong>{t('Select a KPI')}</strong>
                    <span className="ms-1 text-red-300 !text-xl">*</span>
                    <HelpTooltip
                      message={t(
                        'The most critical metric used to measure the effectiveness of your marketing efforts..',
                      )}
                      className="ms-2 mt-1"
                      iconProps={{ size: 12, color: 'white' }}
                    />
                  </div>
                </Col>
                <Col className="mt-2" xs={12}>
                  <Row>
                    <Col xs={12}>
                      <CustomSelect
                        type="highImpactRounded"
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder={t('Select a KPI')}
                        onChange={(e) => {
                          setKpis({
                            analytics_v4: {
                              [e.value]: true,
                            },
                          })
                        }}
                        options={ESSENTIALS_KPIS.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                        value={valueKPI}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row></Row>
    </div>
  )
}

export default function Connections({ plan, ...props }) {
  return plan === 'Essential' ? (
    <EssentialConnections {...props} />
  ) : (
    <ProConnections {...props} />
  )
}
