import { Row, Col } from 'react-bootstrap'
import { IoClose } from 'react-icons/io5'
import useStoreState from '../hooks/UseStoreState'

export default function ModelContentInfobox({
  children,
  applyInfoboxClass,
  name = 'insights_infobox',
  collapsable = false,
  padding = 'ps-4',
  ...props
}) {
  const [hidenInfoboxes, setHiddenInfoboxes] = useStoreState({
    key: 'hiddenInfoboxes',
  })
  const isCollapsable = collapsable && name
  if (isCollapsable && hidenInfoboxes?.[name]) return null

  return (
    <Row
      {...props}
      className={`py-3 rounded-2xl ${padding} font-thin ${applyInfoboxClass ? 'model-content-infobox' : ''} ${props?.className ?? ''}`}
    >
      <Col
        xs={12}
        style={{ maxWidth: `calc(100% - ${isCollapsable ? '60' : '0'}px)` }}
      >
        {children}
      </Col>
      {isCollapsable && (
        <Col
          xs={12}
          style={{ maxWidth: '60px' }}
          className="pe-0 flex items-center cursor-pointer"
          onClick={() => {
            setHiddenInfoboxes({
              ...hidenInfoboxes,
              [name]: true,
            })
          }}
        >
          <IoClose size={25} />
        </Col>
      )}
    </Row>
  )
}
