import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import ModelContentInfobox from './ModelContentInfobox'
import { getModelOptimizeProgress } from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { toast } from 'react-toastify'

export default function OptimizeProgress({ model }) {
  const queryClient = useQueryClient()
  const { token } = useAuth()
  const { data: trainProgress } = useQuery(
    ['model-progress-optimize', model.id],
    async () => {
      const response = await getModelOptimizeProgress({
        modelId: model.id,
        token,
      })
      if (response.ok)
        try {
          return await response.json()
        } catch (e) {}
      return null
    },
    { refetchInterval: 1500 },
  )
  const parseProgress = parseInt(trainProgress?.percent ?? 0)
  const progress = Number.isNaN(parseProgress) ? 0 : parseProgress

  useEffect(() => {
    if (trainProgress?.finished) {
      queryClient.invalidateQueries(['model', model.id])
      if (model.status === 'optimizing' && trainProgress?.level === 'ERROR') {
        toast.error(trainProgress?.message || 'Optimization failed')
      }
    }
  }, [trainProgress])

  return (
    <Row className="justify-content-center">
      <Col
        className="h-100 mt-5 d-flex align-items-center justify-content-center"
        xs={12}
      >
        <div style={{ width: '250px' }}>
          <CircularProgressbar
            value={Math.min(progress, 96)}
            text={`${Math.min(progress, 99)}%`}
            strokeWidth={10}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: 'rounded',
              pathTransitionDuration: 0.5,
              pathColor: `#4240B5`,
              trailColor: 'var(--mmm-background2)',
              textColor: 'var(--mmm-white-color)',
              text: {
                fontSize: '25px',
              },
            })}
          />
        </div>
      </Col>
      <Col className="text-center mt-2" xxl={8} md={10} xs={10}>
        <ModelContentInfobox>
          {trainProgress?.message ?? 'Optimizing...'}
        </ModelContentInfobox>
      </Col>
    </Row>
  )
}
