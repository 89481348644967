import { useTranslation } from 'react-i18next'
import CustomSelect from '../CustomSelect'

export const countries = [
  ['Albania', 'AL'],
  ['Andorra', 'AD'],
  ['Argentina', 'AR'],
  ['Armenia', 'AM'],
  ['Australia', 'AU'],
  ['Austria', 'AT'],
  ['Bahamas', 'BS'],
  ['Barbados', 'BB'],
  ['Belarus', 'BY'],
  ['Belgium', 'BE'],
  ['Belize', 'BZ'],
  ['Benin', 'BJ'],
  ['Bolivia', 'BO'],
  ['Bosnia and Herzegovina', 'BA'],
  ['Botswana', 'BW'],
  ['Brazil', 'BR'],
  ['Bulgaria', 'BG'],
  ['Canada', 'CA'],
  ['Chile', 'CL'],
  ['China', 'CN'],
  ['Colombia', 'CO'],
  ['Costa Rica', 'CR'],
  ['Croatia', 'HR'],
  ['Cuba', 'CU'],
  ['Cyprus', 'CY'],
  ['Czechia', 'CZ'],
  ['Denmark', 'DK'],
  ['Dominican Republic', 'DO'],
  ['Ecuador', 'EC'],
  ['Egypt', 'EG'],
  ['El Salvador', 'SV'],
  ['Estonia', 'EE'],
  ['Faroe Islands', 'FO'],
  ['Finland', 'FI'],
  ['France', 'FR'],
  ['Gabon', 'GA'],
  ['Gambia', 'GM'],
  ['Georgia', 'GE'],
  ['Germany', 'DE'],
  ['Gibraltar', 'GI'],
  ['Greece', 'GR'],
  ['Greenland', 'GL'],
  ['Grenada', 'GD'],
  ['Guatemala', 'GT'],
  ['Guernsey', 'GG'],
  ['Guyana', 'GY'],
  ['Haiti', 'HT'],
  ['Honduras', 'HN'],
  ['Hong Kong', 'HK'],
  ['Hungary', 'HU'],
  ['Iceland', 'IS'],
  ['Indonesia', 'ID'],
  ['Ireland', 'IE'],
  ['Isle of Man', 'IM'],
  ['Italy', 'IT'],
  ['Jamaica', 'JM'],
  ['Japan', 'JP'],
  ['Jersey', 'JE'],
  ['Kazakhstan', 'KZ'],
  ['Latvia', 'LV'],
  ['Lesotho', 'LS'],
  ['Liechtenstein', 'LI'],
  ['Lithuania', 'LT'],
  ['Luxembourg', 'LU'],
  ['Madagascar', 'MG'],
  ['Malta', 'MT'],
  ['Mexico', 'MX'],
  ['Moldova', 'MD'],
  ['Monaco', 'MC'],
  ['Mongolia', 'MN'],
  ['Montenegro', 'ME'],
  ['Montserrat', 'MS'],
  ['Morocco', 'MA'],
  ['Mozambique', 'MZ'],
  ['Namibia', 'NA'],
  ['Netherlands', 'NL'],
  ['New Zealand', 'NZ'],
  ['Nicaragua', 'NI'],
  ['Niger', 'NE'],
  ['Nigeria', 'NG'],
  ['North Macedonia', 'MK'],
  ['Norway', 'NO'],
  ['Panama', 'PA'],
  ['Papua New Guinea', 'PG'],
  ['Paraguay', 'PY'],
  ['Peru', 'PE'],
  ['Poland', 'PL'],
  ['Portugal', 'PT'],
  ['Puerto Rico', 'PR'],
  ['Romania', 'RO'],
  ['Russia', 'RU'],
  ['San Marino', 'SM'],
  ['Serbia', 'RS'],
  ['Singapore', 'SG'],
  ['Slovakia', 'SK'],
  ['Slovenia', 'SI'],
  ['South Africa', 'ZA'],
  ['South Korea', 'KR'],
  ['Spain', 'ES'],
  ['Suriname', 'SR'],
  ['Svalbard and Jan Mayen', 'SJ'],
  ['Sweden', 'SE'],
  ['Switzerland', 'CH'],
  ['Tunisia', 'TN'],
  ['Turkey', 'TR'],
  ['Ukraine', 'UA'],
  ['United Kingdom', 'GB'],
  ['United States', 'US'],
  ['Uruguay', 'UY'],
  ['Vatican City', 'VA'],
  ['Venezuela', 'VE'],
  ['Vietnam', 'VN'],
  ['Zimbabwe', 'ZW'],
  ['Åland Islands', 'AX'],
]
const options = countries.map(([country, code]) => ({
  value: code,
  label: country,
}))

const currency_countries = [
  ['Afghanistan', 'AFN'],
  ['Åland Islands', 'EUR'],
  ['Albania', 'ALL'],
  ['Algeria', 'DZD'],
  ['American Samoa', 'USD'],
  ['Andorra', 'EUR'],
  ['Angola', 'AOA'],
  ['Anguilla', 'XCD'],
  ['Antigua and Barbuda', 'XCD'],
  ['Argentina', 'ARS'],
  ['Armenia', 'AMD'],
  ['Aruba', 'AWG'],
  ['Australia', 'AUD'],
  ['Austria', 'EUR'],
  ['Azerbaijan', 'AZN'],
  ['Bahamas', 'BSD'],
  ['Bahrain', 'BHD'],
  ['Bangladesh', 'BDT'],
  ['Barbados', 'BBD'],
  ['Belarus', 'BYN'],
  ['Belgium', 'EUR'],
  ['Belize', 'BZD'],
  ['Benin', 'XOF'],
  ['Bermuda', 'BMD'],
  ['Bhutan', 'BTN'],
  ['Bolivia], Plurinational State of', 'BOB'],
  ['Bonaire, Sint Eustatius and Saba', 'USD'],
  ['Bosnia and Herzegovina', 'BAM'],
  ['Botswana', 'BWP'],
  ['Bouvet Island', 'NOK'],
  ['Brazil', 'BRL'],
  ['British Indian Ocean Territory', 'USD'],
  ['Brunei Darussalam', 'BND'],
  ['Bulgaria', 'BGN'],
  ['Burkina Faso', 'XOF'],
  ['Burundi', 'BIF'],
  ['Cabo Verde', 'CVE'],
  ['Cambodia', 'KHR'],
  ['Cameroon', 'XAF'],
  ['Canada', 'CAD'],
  ['Cayman Islands', 'KYD'],
  ['Central African Republic', 'XAF'],
  ['Chad', 'XAF'],
  ['Chile', 'CLP'],
  ['China', 'CNY'],
  ['Christmas Island', 'AUD'],
  ['Cocos (Keeling) Islands', 'AUD'],
  ['Colombia', 'COP'],
  ['Comoros', 'KMF'],
  ['Congo', 'XAF'],
  ['Congo, The Democratic Republic of the', 'CDF'],
  ['Cook Islands', 'NZD'],
  ['Costa Rica', 'CRC'],
  ["Côte d'Ivoire", 'XOF'],
  ['Croatia', 'HRK'],
  ['Cuba', 'CUP'],
  ['Curaçao', 'ANG'],
  ['Cyprus', 'EUR'],
  ['Czechia', 'CZK'],
  ['Denmark', 'DKK'],
  ['Djibouti', 'DJF'],
  ['Dominica', 'XCD'],
  ['Dominican Republic', 'DOP'],
  ['Ecuador', 'USD'],
  ['Egypt', 'EGP'],
  ['El Salvador', 'SVC'],
  ['Equatorial Guinea', 'XAF'],
  ['Eritrea', 'ERN'],
  ['Estonia', 'EUR'],
  ['Eswatini', 'SZL'],
  ['Ethiopia', 'ETB'],
  ['Falkland Islands (Malvinas)', 'FKP'],
  ['Faroe Islands', 'DKK'],
  ['Fiji', 'FJD'],
  ['Finland', 'EUR'],
  ['France', 'EUR'],
  ['French Guiana', 'EUR'],
  ['French Polynesia', 'XPF'],
  ['French Southern Territories', 'EUR'],
  ['Gabon', 'XAF'],
  ['Gambia', 'GMD'],
  ['Georgia', 'GEL'],
  ['Germany', 'EUR'],
  ['Ghana', 'GHS'],
  ['Gibraltar', 'GIP'],
  ['Greece', 'EUR'],
  ['Greenland', 'DKK'],
  ['Grenada', 'XCD'],
  ['Guadeloupe', 'EUR'],
  ['Guam', 'USD'],
  ['Guatemala', 'GTQ'],
  ['Guernsey', 'GGP'],
  ['Guinea', 'GNF'],
  ['Guinea-Bissau', 'XOF'],
  ['Guyana', 'GYD'],
  ['Haiti', 'HTG'],
  ['Heard Island and McDonald Islands', 'AUD'],
  ['Holy See (Vatican City State)', 'EUR'],
  ['Honduras', 'HNL'],
  ['Hong Kong', 'HKD'],
  ['Hungary', 'HUF'],
  ['Iceland', 'ISK'],
  ['India', 'INR'],
  ['Indonesia', 'IDR'],
  ['Iran], Islamic Republic of', 'IRR'],
  ['Iraq', 'IQD'],
  ['Ireland', 'EUR'],
  ['Isle of Man', 'IMP'],
  ['Israel', 'ILS'],
  ['Italy', 'EUR'],
  ['Jamaica', 'JMD'],
  ['Japan', 'JPY'],
  ['Jersey', 'JEP'],
  ['Jordan', 'JOD'],
  ['Kazakhstan', 'KZT'],
  ['Kenya', 'KES'],
  ['Kiribati', 'AUD'],
  ["Korea], Democratic People's Republic of", 'KPW'],
  ['Korea], Republic of', 'KRW'],
  ['Kuwait', 'KWD'],
  ['Kyrgyzstan', 'KGS'],
  ["Lao People's Democratic Republic", 'LAK'],
  ['Latvia', 'EUR'],
  ['Lebanon', 'LBP'],
  ['Lesotho', 'LSL'],
  ['Liberia', 'LRD'],
  ['Libya', 'LYD'],
  ['Liechtenstein', 'CHF'],
  ['Lithuania', 'EUR'],
  ['Luxembourg', 'EUR'],
  ['Macao', 'MOP'],
  ['Madagascar', 'MGA'],
  ['Malawi', 'MWK'],
  ['Malaysia', 'MYR'],
  ['Maldives', 'MVR'],
  ['Mali', 'XOF'],
  ['Malta', 'EUR'],
  ['Marshall Islands', 'USD'],
  ['Martinique', 'EUR'],
  ['Mauritania', 'MRU'],
  ['Mauritius', 'MUR'],
  ['Mayotte', 'EUR'],
  ['Mexico', 'MXN'],
  ['Micronesia, Federated States of', 'USD'],
  ['Moldova], Republic of', 'MDL'],
  ['Monaco', 'EUR'],
  ['Mongolia', 'MNT'],
  ['Montenegro', 'EUR'],
  ['Montserrat', 'XCD'],
  ['Morocco', 'MAD'],
  ['Mozambique', 'MZN'],
  ['Myanmar', 'MMK'],
  ['Namibia', 'NAD'],
  ['Nauru', 'AUD'],
  ['Nepal', 'NPR'],
  ['Netherlands', 'EUR'],
  ['New Caledonia', 'XPF'],
  ['New Zealand', 'NZD'],
  ['Nicaragua', 'NIO'],
  ['Niger', 'XOF'],
  ['Nigeria', 'NGN'],
  ['Niue', 'NZD'],
  ['Norfolk Island', 'AUD'],
  ['North Macedonia', 'MKD'],
  ['Northern Mariana Islands', 'USD'],
  ['Norway', 'NOK'],
  ['Oman', 'OMR'],
  ['Pakistan', 'PKR'],
  ['Palau', 'USD'],
  ['Palestine, State of', 'ILS'],
  ['Panama', 'PAB'],
  ['Papua New Guinea', 'PGK'],
  ['Paraguay', 'PYG'],
  ['Peru', 'PEN'],
  ['Philippines', 'PHP'],
  ['Pitcairn', 'NZD'],
  ['Poland', 'PLN'],
  ['Portugal', 'EUR'],
  ['Puerto Rico', 'USD'],
  ['Qatar', 'QAR'],
  ['Réunion', 'EUR'],
  ['Romania', 'RON'],
  ['Russian Federation', 'RUB'],
  ['Rwanda', 'RWF'],
  ['Saint Barthélemy', 'EUR'],
  ['Saint Helena], Ascension and Tristan da Cunha', 'SHP'],
  ['Saint Kitts and Nevis', 'XCD'],
  ['Saint Lucia', 'XCD'],
  ['Saint Martin (French part)', 'EUR'],
  ['Saint Pierre and Miquelon', 'EUR'],
  ['Saint Vincent and the Grenadines', 'XCD'],
  ['Samoa', 'WST'],
  ['San Marino', 'EUR'],
  ['Sao Tome and Principe', 'STN'],
  ['Saudi Arabia', 'SAR'],
  ['Senegal', 'XOF'],
  ['Serbia', 'RSD'],
  ['Seychelles', 'SCR'],
  ['Sierra Leone', 'SLL'],
  ['Singapore', 'SGD'],
  ['Sint Maarten (Dutch part)', 'ANG'],
  ['Slovakia', 'EUR'],
  ['Slovenia', 'EUR'],
  ['Solomon Islands', 'SBD'],
  ['Somalia', 'SOS'],
  ['South Africa', 'ZAR'],
  ['South Georgia and the South Sandwich Islands', 'GBP'],
  ['South Sudan', 'SSP'],
  ['Spain', 'EUR'],
  ['Sri Lanka', 'LKR'],
  ['Sudan', 'SDG'],
  ['Suriname', 'SRD'],
  ['Svalbard and Jan Mayen', 'NOK'],
  ['Sweden', 'SEK'],
  ['Switzerland', 'CHF'],
  ['Syrian Arab Republic', 'SYP'],
  ['Taiwan, Province of China', 'TWD'],
  ['Tajikistan', 'TJS'],
  ['Tanzania], United Republic of', 'TZS'],
  ['Thailand', 'THB'],
  ['Timor-Leste', 'USD'],
  ['Togo', 'XOF'],
  ['Tokelau', 'NZD'],
  ['Tonga', 'TOP'],
  ['Trinidad and Tobago', 'TTD'],
  ['Tunisia', 'TND'],
  ['Türkiye', 'TRY'],
  ['Turkmenistan', 'TMT'],
  ['Turks and Caicos Islands', 'USD'],
  ['Tuvalu', 'AUD'],
  ['Uganda', 'UGX'],
  ['Ukraine', 'UAH'],
  ['United Arab Emirates', 'AED'],
  ['United Kingdom', 'GBP'],
  ['United States', 'USD'],
  ['United States Minor Outlying Islands', 'USD'],
  ['Uruguay', 'UYU'],
  ['Uzbekistan', 'UZS'],
  ['Vanuatu', 'VUV'],
  ['Venezuela, Bolivarian Republic of', 'VES'],
  ['Viet Nam', 'VND'],
  ['Virgin Islands, British', 'USD'],
  ['Virgin Islands, U.S.', 'USD'],
  ['Wallis and Futuna', 'XPF'],
  ['Western Sahara', 'MAD'],
  ['Yemen', 'YER'],
  ['Zambia', 'ZMW'],
  ['Zimbabwe', 'ZWL'],
]

const currencyOptions = [...new Set(currency_countries.map(([_, c]) => c))].map(
  (c) => ({
    value: c,
    currency: c,
    label: c,
  }),
)

export default function CountrySelector({ currency = false, ...props }) {
  const { t } = useTranslation()

  return (
    <CustomSelect
      className="basic-single mt-2 min-w-full ps-4"
      isSearchable={true}
      placeholder={t(currency ? 'Select a currency' : 'Select a country')}
      isClearable={true}
      {...props}
      options={currency ? currencyOptions : options}
    />
  )
}
