import React, { useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonMMM from '../../ButtonMMM'
import { toast } from 'react-toastify'

export default function BigQuery({ config, setConfig, account }) {
  const { t } = useTranslation()
  const ref = useRef()
  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Query SQL')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          placeholder="Query SQL"
          rows={4}
          defaultValue={config?.kwargs?.query ?? ''}
          ref={ref}
        />
      </Col>
      <Col className="flex justify-end" xs={12}>
        <ButtonMMM
          onClick={() => {
            const query = ref.current.value
            if (!query) toast.error(t('Please input query'))
            else {
              if (config.associatedTag && query !== config?.kwargs?.query) {
                config.previousAssociatedTag = config.associatedTag
                delete config.associatedTag
              }
              setConfig({
                ...config,
                kwargs: {
                  query,
                  account_name: account?.name,
                  project_id: account?.id,
                },
              })
            }
          }}
        >
          {t('Save')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}
