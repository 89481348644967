import { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { config } from '../Constants'
import { FaSpinner } from 'react-icons/fa'

import proPlan from '../assets/images/pro_plan.svg'
import essentialsPlan from '../assets/images/essentials.svg'
import { useAuth } from '../providers/AuthProvider'
import ButtonMMM from './ButtonMMM'
import CustomSelect from './CustomSelect'
import {
  cancelRenewal,
  changeMorpheusPlan,
  continueRenewal,
  getPlanPriceDifference,
} from '../services/dataslayer'

export const BASIC = [
  '2 data sources (GA4 and Local File Upload)',
  '1 GA4 account',
  'Contextual Variables (Weather and Holidays)',
  'Unlimited users: 1 import per Company per month',
  'Up to 3 refreshes per dataset',
  'Basic Marketing Channel Insights',
  '24h/5 live-chat support',
]

export const PRO = [
  'All data sources',
  'All Contextual Variables',
  'Unlimited users',
  'Custom number of imports',
  'Custom number of refreshes',
  'All Marketing Channels Insights',
  'Optimization and Planning',
  'Dedicated Account Manager',
  '24h/5 live-chat support',
]

function isTimeZoneEuroZone() {
  var euroZone = [
    'Europe/Amsterdam', // Países Bajos
    'Europe/Athens', // Grecia
    'Europe/Berlin', // Alemania
    'Europe/Bratislava', // Eslovaquia
    'Europe/Brussels', // Bélgica
    'Europe/Dublin', // Irlanda
    'Europe/Helsinki', // Finlandia
    'Europe/Lisbon', // Portugal
    'Europe/Ljubljana', // Eslovenia
    'Europe/Luxembourg', // Luxemburgo
    'Europe/Madrid', // España
    'Europe/Malta', // Malta
    'Europe/Nicosia', // Chipre
    'Europe/Paris', // Francia
    'Europe/Riga', // Letonia
    'Europe/Rome', // Italia
    'Europe/Tallinn', // Estonia
    'Europe/Vienna', // Austria
    'Europe/Vilnius', // Lituania
    'Europe/Zagreb', // Croacia
  ]

  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return euroZone.includes(timezone)
}

function SwitchPlan({ monthlyToYearly = true, currency, onClose }) {
  const { token } = useAuth()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  const { data: price } = useQuery(
    ['cost-switch-plan', monthlyToYearly, token],
    async () => {
      const res = await getPlanPriceDifference({
        token,
        currency,
        type: monthlyToYearly ? 'YEARLY' : 'MONTHLY',
      })
      if (res.ok) return await res.json()
      throw new Error('Error calculating price')
    },
    { staleTime: Infinity },
  )

  const { t } = useTranslation()
  const price1 = monthlyToYearly ? 250 : 2500
  const price2 = monthlyToYearly ? 2500 : 250

  return (
    <Row>
      <Col className="text-xs" xs={12}>
        {t(
          'Switching to a higher plan will change the price difference with your current plan immediately, while switching to a lower plan it will be activated at the end of the current plan.',
        )}
      </Col>
      <Col xs={12}>
        <table className="min-w-full my-3 table-license">
          <thead>
            <tr className="">
              <th>{t('')}</th>
              <th>{t('Current plan')}</th>
              <th>{t('New plan')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{t('Service')}</th>
              <td>{t('Morpheus')}</td>
              <td>{t('Morpheus')}</td>
            </tr>
            <tr>
              <th>{t('Plan name')}</th>
              <td>{t('Essential')}</td>
              <td>{t('Essential')}</td>
            </tr>
            <tr>
              <th>{t('Billing cycle')}</th>
              <td>{t(monthlyToYearly ? 'MONTHLY' : 'YEARLY')}</td>
              <td>{t(monthlyToYearly ? 'YEARLY' : 'MONTHLY')}</td>
            </tr>
            <tr>
              <th>{t('Price')}</th>
              <td>
                {price1} {currency}*
              </td>
              <td>
                {price2} {currency}*
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col xs={12}>* {t('NOT included applicable taxes')}</Col>
      <Col className="text-center mt-2 mb-4" xs={12}>
        <strong>{t('Final Amount')}:</strong>{' '}
        <>
          {price ? (
            <>
              {price?.price}
              {currency}
            </>
          ) : (
            <FaSpinner className="animate-spin inline-block" />
          )}
        </>
      </Col>
      <Col className="flex justify-end mt-3" xs={12}>
        <ButtonMMM
          disabled={loading}
          onClick={(e) => {
            setLoading(true)
            changeMorpheusPlan({
              currency,
              type: monthlyToYearly ? 'YEARLY' : 'MONTHLY',
              token,
            })
              .then(async (e) => {
                if (e.ok) {
                  await new Promise((r) => setTimeout(r, 2000))
                  toast.info('Plan changed successfully')
                  queryClient.invalidateQueries(['user-data', token])
                  queryClient.invalidateQueries(['user-plan-usage', token])
                  onClose()
                } else {
                  const error = await e.text()
                  toast.error(`Error changing plan ${error}`)
                }
              })
              .finally(() => {
                setLoading(false)
              })
          }}
        >
          {t('Confirm payment')}
          {loading && (
            <FaSpinner className="animate-spin inline-block mx-2" size={30} />
          )}
        </ButtonMMM>
        <ButtonMMM className="ms-3" type="white" onClick={onClose}>
          {t('Close')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}

function CancelSubscription({ onClose }) {
  const { t } = useTranslation()
  const { token } = useAuth()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const [finished, setFinished] = useState(false)
  const reasonRef = useRef()

  const cancelOptions = useMemo(() => {
    return [
      {
        value: 'customer_service',
        label: t('Customer service was insufficient'),
      },
      { value: 'low_quality', label: t('Low quality') },
      { value: 'missing_features', label: t('Missing features') },
      {
        value: 'switched_service',
        label: t('Switched to a different service'),
      },
      { value: 'too_expensive', label: t('Too expensive') },
      { value: 'unused', label: t('Unused') },
    ]
  }, [t])

  const [selectedValue, setSelectedValue] = useState(null)
  const [disableFinish, setDisableFinish] = useState(true)

  return (
    <Row>
      {finished ? (
        <Col className="text-center" xs={12}>
          {t('Your subscription has been canceled.')}{' '}
          {t('You can continue it again at any time')}
          <br />
          <div className="strong text-2xl mt-3">
            {t('Thank you for using our service')}
          </div>
        </Col>
      ) : (
        <>
          <Col className="text-center my-1" xs={12}>
            {t('Reason')}
          </Col>
          <Col xs={12}>
            <CustomSelect
              value={cancelOptions?.find((e) => e.value === selectedValue)}
              options={cancelOptions}
              onChange={(e) => setSelectedValue(e?.value)}
              placeholder={t('Select a reason...')}
            />
          </Col>
          <Col className="mt-4 text-center mb-1" xs={12}>
            {t('Comment')}
          </Col>
          <Col xs={12}>
            <Form.Control
              className="text-input-mmm !px-3 py-2"
              as="textarea"
              rows={7}
              ref={reasonRef}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setDisableFinish(false)
                } else {
                  setDisableFinish(true)
                }
              }}
            />
          </Col>
        </>
      )}
      <Col className="flex justify-end mt-5" xs={12}>
        {!finished && (
          <ButtonMMM
            type="red"
            disabled={!selectedValue || disableFinish || disableFinish}
            onClick={() => {
              setLoading(true)
              cancelRenewal({
                token,
                reason: selectedValue ?? 'not specified',
                comment: reasonRef.current.value || 'not specified',
              })
                .then(async (e) => {
                  if (e.ok) {
                    await new Promise((r) => setTimeout(r, 2000))
                    setFinished(true)
                    queryClient.invalidateQueries(['user-data', token])
                    queryClient.invalidateQueries(['user-plan-usage', token])
                  } else {
                    const error = await e.text()
                    toast.error(`Error canceling plan ${error}`)
                  }
                })
                .finally(() => setLoading(false))
            }}
          >
            {t('Yes, I would like to unsubscribe')}
            {loading && (
              <FaSpinner className="animate-spin inline-block mx-2" size={30} />
            )}
          </ButtonMMM>
        )}
        <ButtonMMM className="ms-3" type="white" onClick={onClose}>
          {t('Close')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}

function ContinueSubscription({ onClose }) {
  const { t } = useTranslation()
  const { token } = useAuth()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const [finished, setFinished] = useState(false)

  return (
    <Row>
      {finished && (
        <Col xs={12}>
          <div className="text-center mb-3">
            {t('Your subscription has been continued.')}
            <br />
          </div>
        </Col>
      )}
      <Col className="flex justify-center mt-1" xs={12}>
        {!finished && (
          <ButtonMMM
            onClick={() => {
              setLoading(true)
              continueRenewal({
                token,
              })
                .then(async (e) => {
                  if (e.ok) {
                    await new Promise((r) => setTimeout(r, 2000))
                    setFinished(true)
                    queryClient.invalidateQueries(['user-data', token])
                    queryClient.invalidateQueries(['user-plan-usage', token])
                  } else {
                    toast.error('Error canceling plan')
                  }
                })
                .finally(() => setLoading(false))
            }}
            disabled={loading}
          >
            {t('Continue subscription')}
            {loading && (
              <FaSpinner className="animate-spin inline-block mx-2" size={30} />
            )}
          </ButtonMMM>
        )}
        <ButtonMMM className="ms-3" type="white" onClick={onClose}>
          {t('Close')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}

export default function License({}) {
  const { user, isEssential, token } = useAuth()
  const { t } = useTranslation()
  const [yearlyBilling, setYearlyBilling] = useState(true)
  const [showSwitchPlan, setShowSwitchPlan] = useState(null)
  const [menuUnsubscribe, setMenuUnsubscribe] = useState(false)
  const [menuSubscribe, setMenuSubscribe] = useState(false)
  const tailRef = useRef()
  const queryClient = useQueryClient()
  useEffect(() => {
    setInterval(() => {
      queryClient.invalidateQueries(['user-data', token])
    }, 5000)
  }, [])

  const billingName = yearlyBilling ? 'YEARLY' : 'MONTHLY'
  const prices = isTimeZoneEuroZone()
    ? ['250.00', '208.34']
    : ['270.00', '225.00']

  const showUnsubscribe =
    (user?.plan === 'Essential' || user?.plan === 'Pro') &&
    user?.plan_data?.plan_subscription_type === 'stripe'

  useEffect(() => {
    tailRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [])

  return (
    <Row>
      {/* <Col className="py-2 flex justify-start mb-2" xs={12}>
        <div className="me-4 absolute ">
          <span className="me-1">
            {t('Current plan')}<strong>{`: ${user?.plan}`}</strong>
          </span>
          (
          <span className="me-1 ms-1 text-sm">
            {
              t(
                '{{start}}',
                {
                  start: formatDateddmmyy(user?.plan_data?.plan_start_date),
                }
              )
            }
          </span>
          -
          <span className="ms-1 text-sm">
            {t(`{{end}}`,
              {
                end: formatDateddmmyy(user?.plan_data?.expiration_date)
              })}
          </span>
          )
        </div>
      </Col> */}
      <Col
        className="justify-center flex flex-nowrap items-center text-lg"
        xs={12}
      >
        <span className="ms-[100px]">{t('Monthly')}</span>
        <Form.Check
          type="switch"
          checked={yearlyBilling}
          className="form-switch-billing mx-3"
          onChange={async (e) => setYearlyBilling(e.target.checked)}
        />
        <span>{t('Annually')}</span>
        <strong className="text-sm ms-2 italic flex items-center mt-[2px]">
          {t('2 months free!')}
        </strong>
      </Col>
      <Col xs={12} className="!mt-6">
        <Row className="justify-center">
          <Col xs={'auto'} className="mb-14">
            <div className="min-w-[386px] max-w-[386px] border-2 border-white rounded-[24px] bg-billingBg px-[40px] py-10 relative min-h-full">
              <Row className="mb-3">
                <Col
                  className="text-billingPurple text-[30px] font-bold flex flex-nowrap items-center"
                  xs={12}
                >
                  <img src={essentialsPlan} className="w-[72px] me-3" />
                  ESSENTIAL
                </Col>
                <Col className="mt-4" xs={12}>
                  <Row className="flex-nowrap">
                    <Col
                      className="text-[45px] font-bold flex items-center ps-0"
                      xs={7}
                    >
                      {prices[yearlyBilling ? 1 : 0]}
                      {isTimeZoneEuroZone() ? '€' : '$'}
                    </Col>
                    <Col
                      className="pe-0 flex items-center text-[14px]  text-[#898989]"
                      xs={5}
                    >
                      <Row className="leading-4">
                        <Col xs={12}>/{t('monthly')}</Col>
                        {yearlyBilling && (
                          <Col xs={12}>{t('billed annually')}</Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mt-2 text-[18px] font-bold">
                  {t("What's included")}
                </Col>
                {BASIC.map((v, i) => (
                  <Col className="mt-2" key={i} xs={12}>
                    <Row className="flex-nowrap">
                      <Col
                        xs="auto"
                        className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                      >
                        <IoIosCheckmarkCircle size={28} color="white" />
                      </Col>
                      <Col
                        xs={12}
                        className="flex items-center"
                        style={{ maxWidth: 'calc(100% - 28px)' }}
                      >
                        {t(v)}
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Col className="mt-3" xs={12}>
                  <Row className="flex-nowrap">
                    <Col
                      xs="auto"
                      className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                    >
                      <IoIosCheckmarkCircle size={28} color="white" />
                    </Col>
                    <Col
                      xs={12}
                      className="flex items-center text-billingPurple underline"
                      style={{ maxWidth: 'calc(100% - 28px)' }}
                      as={Link}
                      to={
                        'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('15 min training call')}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {user?.plan !== 'Essential' ? (
                <a
                  className="absolute min-w-[50%] max-w-[50%] px-5 py-3 bg-white left-[25%] rounded-[96px] text-lg bottom-[-35px] text-black text-center font-bold cursor-pointer hover:text-white hover:bg-billingbg duration-200"
                  href={`${config.DATASLAYER_URL}/billing-payment?plan=ESSENTIAL&type=${yearlyBilling ? 'YEARLY' : 'MONTHLY'}&currency=${isTimeZoneEuroZone() ? 'EUR' : 'USD'}&service_id=9`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Select')}
                </a>
              ) : user?.plan_data?.billing_cycle === billingName ? (
                <>
                  <a className="absolute min-w-[70%] max-w-[70%] px-5 py-3 bg-white left-[15%] rounded-[96px] text-lg bottom-[-35px] text-black text-center font-bold cursor-pointer hover:text-white hover:bg-billingbg duration-200">
                    {t('Current plan')}
                  </a>
                </>
              ) : (
                <a
                  className="absolute min-w-[70%] max-w-[70%] px-1 py-3 bg-white left-[15%] rounded-[96px] text-lg bottom-[-35px] text-black text-center font-bold cursor-pointer hover:text-white hover:bg-billingbg duration-200"
                  href={``}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const monthToYearly =
                      user?.plan_data?.billing_cycle === 'MONTHLY'
                    setShowSwitchPlan({
                      monthlyToYearly: monthToYearly,
                      currency: isTimeZoneEuroZone() ? 'EUR' : 'USD',
                    })
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Change to this plan')}
                </a>
              )}
            </div>
          </Col>
          <Col className="mb-14" xs={'auto'}>
            <div className="min-w-[386px] max-w-[386px] border-2 border-white rounded-[24px] bg-billingBg px-[45px] py-10 relative min-h-full">
              <Row className="mb-3">
                <Col
                  className="text-billingPurple text-[30px] font-bold flex flex-nowrap items-center"
                  xs={12}
                >
                  <img src={proPlan} className="w-[72px] me-3" />
                  PRO
                </Col>
                <Col className="mt-4" xs={12}>
                  <Row>
                    <Col
                      className="text-[45px] font-bold flex items-center"
                      xs={'auto'}
                    >
                      {t('Contact us')}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mt-2 text-[18px] font-bold mb-1">
                  {t("What's included")}
                </Col>
                {PRO.map((v, i) => (
                  <Col className="mt-2 mb-1" key={i} xs={12}>
                    <Row className="flex-nowrap">
                      <Col
                        xs="auto"
                        className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                      >
                        <IoIosCheckmarkCircle size={28} color="white" />
                      </Col>
                      <Col
                        xs={12}
                        className="flex items-center"
                        style={{ maxWidth: 'calc(100% - 28px)' }}
                      >
                        {t(v)}
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Col className="mt-3" xs={12}>
                  <Row className="flex-nowrap">
                    <Col
                      xs="auto"
                      className="pe-0 max-w-[28px] min-w-[28px] flex items-center"
                    >
                      <IoIosCheckmarkCircle size={28} color="white" />
                    </Col>
                    <Col
                      xs={12}
                      className="flex items-center text-billingPurple underline"
                      style={{ maxWidth: 'calc(100% - 28px)' }}
                      as={Link}
                      to={
                        'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('30 min training call')}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <a
                className="absolute min-w-[50%] max-w-[50%] px-2 py-3 bg-white left-[25%] rounded-[96px] text-lg bottom-[-35px] text-black text-center font-bold cursor-pointer hover:text-white hover:bg-billingbg duration-200"
                href={`https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm`}
                target="_blank"
                rel="noopener noreferrer"
                ref={tailRef}
              >
                {t('Contact us')}
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          {showUnsubscribe &&
            (user?.plan_data?.plan_subscription_cancelled ? (
              <>
                <Col className="flex justify-center mt-4 mb-2" xs={12}>
                  <div className="text-center">
                    {t(
                      'If you choose to continue. All existing data will be kept on renovation.',
                    )}
                  </div>
                </Col>
                <Col className="flex justify-center my-2" xs={12}>
                  <a
                    className="text-[#2ca3ac] border-[#2ca3ac] border-2 px-4 py-2 rounded-[24px] cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setMenuSubscribe(true)
                    }}
                  >
                    {t('Continue subscription')}
                  </a>
                </Col>
              </>
            ) : (
              <>
                <Col className="flex justify-center mt-4 mb-2" xs={12}>
                  <div className="text-center">
                    {t(
                      'If you choose to unsubscribe. All information regarding will be removed.',
                    )}
                  </div>
                </Col>
                <Col className="flex justify-center my-2 " xs={12}>
                  <a
                    className="text-[#971b2e] border-[#971b2e] border-2 px-4 py-2 rounded-[24px] cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setMenuUnsubscribe(true)
                    }}
                  >
                    {t('Yes, I would like to unsubscribe')}
                  </a>
                </Col>
              </>
            ))}
        </Row>
      </Col>
      <Modal show={!!showSwitchPlan} onHide={() => setShowSwitchPlan(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Plan summary')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showSwitchPlan && (
            <SwitchPlan
              monthlyToYearly={showSwitchPlan.monthlyToYearly}
              currency={showSwitchPlan.currency}
              onClose={() => setShowSwitchPlan(null)}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={menuUnsubscribe}
        onHide={() => setMenuUnsubscribe(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Are you sure you want to unsubscribe?')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {menuUnsubscribe && (
            <CancelSubscription onClose={() => setMenuUnsubscribe(null)} />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={menuSubscribe}
        onHide={() => setMenuSubscribe(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Are you sure you want to Resubscribe?')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {menuSubscribe && (
            <ContinueSubscription onClose={() => setMenuSubscribe(null)} />
          )}
        </Modal.Body>
      </Modal>
    </Row>
  )
}
