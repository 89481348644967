import React, { forwardRef } from 'react'

const ButtonMMM = forwardRef(({ disabled, children, type, ...props }, ref) => {
  return (
    <button
      {...props}
      disabled={!!disabled}
      className={`button-mmm${type ? `-${type}` : ''} ${disabled ? 'opacity-50 pointer-events-none' : ''} rounded-3xl ${props?.className ?? ''}`}
    >
      {children}
    </button>
  )
})

export default ButtonMMM
