import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Row, Col } from 'react-bootstrap'
import { getMMMFunnelEffect } from '../../services/model'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import { round } from '../../utility/format'
import { useAuth } from '../../providers/AuthProvider'
import { placeholderFunnel } from './placeholders'
import UpgradeToPro from './UpgradeToPro'

const MARGIN_TOP = 10
const MARGIN_RIGHT = 80
const MARGIN_BOTTOM = 20
const MARGIN_LEFT = 10

export default function FunnelEffect({
  model,
  inView = true,
  isInView,
  ...props
}) {
  const { token, isEssential } = useAuth()
  const { t } = useTranslation()
  const requirePro = isEssential
  const { isLoading: loadingFunnel, data } = useQuery(
    ['mmm-funnel-effect', model?.id],
    async () => {
      if (requirePro) return placeholderFunnel
      if (model?.id) {
        const response = await getMMMFunnelEffect({ modelId: model.id, token })
        if (response?.ok) return await response.json()
      }
    },
    { staleTime: Infinity },
  )
  const heatmaps = useMemo(() => {
    if (data && data?.columns && data?.data) {
      const res = data.data.map((v, i) => {
        return {
          id: v[0],
          data: v.slice(1).map((vv, j) => {
            return {
              x: data.columns[j + 1],
              y: v[0] === data.columns[j + 1] ? null : vv,
            }
          }),
        }
      })
      return res
    }
    return null
  }, [data])

  const csvData = useMemo(() => {
    if (data && data?.columns && data?.data) {
      return encodeURIComponent(JSON.stringify([data.columns, ...data.data]))
    }
    return null
  }, [data])
  const columns = heatmaps?.[0]?.data?.length ?? 0

  if (!loadingFunnel && !heatmaps) return null

  const columnCount = heatmaps?.length ?? 0
  const fontSize = Math.max(9, 18 - columnCount * 0.5)

  return !heatmaps ? (
    <Loader />
  ) : (
    <Row className={`relative ${requirePro ? 'blur-under' : ''}`}>
      {requirePro && <UpgradeToPro />}
      <div
        className="data-holder hidden"
        data-csv={csvData}
        data-filename={`Cross_channel_impact__${model.id}`}
      ></div>

      <Col xs={12}></Col>
      <Col
        className={`${columns ? '' : 'd-none'}`}
        xs={12}
        style={{
          marginTop: '-30px',
          minHeight: '120px',
          marginLeft: `${MARGIN_LEFT + 410}px`,
          maxWidth: `calc(100% - ${MARGIN_LEFT + MARGIN_RIGHT + 410}px)`,
        }}
      >
        <Row style={{ minHeight: '100%' }}>
          {heatmaps?.[0]?.data?.map((d, i) => (
            <Col
              key={d.x}
              style={{ maxWidth: `${100 / columns}%` }}
              className="flex items-end legend-funnel relative"
            >
              <span
                className="inline-block text-truncate"
                style={{
                  transformOrigin: 'bottom left',
                  transform: 'rotate(-22deg)',
                  marginLeft: '50%',
                  minWidth: '200%',
                }}
                title={d.x}
              >
                {d.x}
              </span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        className="flex items-center pe-0"
        style={{ maxWidth: '220px' }}
        xs={3}
      >
        <Row>
          <Col xs={12}>
            <strong className="flex justify-center">{t('Upstream ads')}</strong>
          </Col>
          <Col className="flex justify-center text-center" xs={12}>
            <span className="small">{t('Investment in this ad channels')}</span>
          </Col>
        </Row>
      </Col>
      <Col
        className="pe-0 "
        xs={3}
        style={{
          maxWidth: '190px',
          marginTop: `${MARGIN_TOP}px`,
          marginBottom: `${MARGIN_BOTTOM}px`,
          marginRight: '0px',
        }}
      >
        <Row className="w-100" style={{ minHeight: '100%' }}>
          {heatmaps?.map((d, i) => (
            <Col
              className="flex items-center justify-end pe-0 legend-funnel"
              key={d.id}
              xs={12}
              title={d.id}
            >
              <span className="inline-block text-truncate">{d.id}</span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col
        {...props}
        className={`${props?.className ?? ''} px-0`}
        style={{
          maxWidth: 'calc(100% - 410px)',
          ...(props?.style ?? {}),
          minHeight: `${heatmaps?.[0]?.data?.length * 30 + 50}px`,
        }}
        xs={11}
      >
        <ResponsiveHeatMapCanvas
          data={heatmaps}
          margin={{
            top: MARGIN_TOP,
            right: MARGIN_RIGHT,
            bottom: MARGIN_BOTTOM,
            left: MARGIN_LEFT,
          }}
          valueFormat=">-.2f"
          label={({ data }) => {
            const absV = Math.abs(data.y)
            return (data.y || data.y === 0) && absV < 100
              ? `${round(data.y, absV < 1 ? (absV < 0.1 ? 2 : 1) : 0)}%`
              : ''
          }}
          labelTextColor="#000"
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={null}
          emptyColor="#1c242d"
          colors={{
            type: 'diverging',
            scheme: 'red_blue',
            divergeAt: 0.5,
            minValue: -20,
            maxValue: 20,
          }}
          legends={[]}
          theme={{
            fontSize: fontSize,
            textColor: 'black',
            tooltip: {
              container: {
                fontSize: 11,
                color: 'black',
              },
            },
          }}
        />
      </Col>
      <Col
        xs={11}
        style={{ maxWidth: 'calc(100% - 150px)', marginLeft: '150px' }}
        className=" flex justify-center mb-3"
      >
        <Row>
          <Col xs={12}>
            <strong
              className="flex justify-center"
              style={{ transformOrigin: 'top left' }}
            >
              {t('Downstream ads')}
            </strong>
          </Col>
          <Col className="flex justify-center" xs={12}>
            <span className="small">
              {t('Impacts the levels of this other ad channel')}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
