import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonMMM from '../../ButtonMMM'
import { toast } from 'react-toastify'

export default function GoogleSheets({ config, setConfig }) {
  const { t } = useTranslation()
  const [localConfig] = useState(config || { kwargs: {} })

  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('URL')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          placeholder={t('URL of your worksheet')}
          defaultValue={localConfig?.kwargs?.document_url ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.document_url = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Sheet name')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          placeholder={t('Name of your spreadsheet')}
          rows={1}
          defaultValue={localConfig?.kwargs?.sheet_name ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.sheet_name = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Sheet range')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          placeholder={t('Sheet range to extract data from')}
          rows={1}
          defaultValue={localConfig?.kwargs?.sheet_range ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.sheet_range = e.target.value
          }}
        />
      </Col>
      <Col className="flex justify-end" xs={12}>
        <ButtonMMM
          onClick={() => {
            if (
              localConfig?.kwargs?.document_url &&
              localConfig?.kwargs?.sheet_name &&
              localConfig?.kwargs?.sheet_range
            ) {
              if (localConfig.associatedTag) {
                localConfig.previousAssociatedTag = localConfig.associatedTag
                delete localConfig.associatedTag
              }
              setConfig(localConfig)
            } else
              toast.error(
                t('Please fill all the fields in the Google sheets form'),
              )
          }}
        >
          {t('Save')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}
