import { useState, useEffect } from 'react'
import { FaChevronDown, FaFilePowerpoint } from 'react-icons/fa'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ButtonMMM from './ButtonMMM'
import { useAuth } from '../providers/AuthProvider'
import { generateMMMPresentation, getMMMPresentation } from '../services/model'
import { toast } from 'react-toastify'

export default function ExportReport({}) {
  const { token } = useAuth()
  const [doExport, setExport] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (doExport) {
      generateMMMPresentation({ modelId: model.id, token })
        .then(async (r) => {
          if (r.ok) {
            toast.success('Report will download shortly.')
            const tryDownload = async () => {
              const result = await getMMMPresentation({
                modelId: model.id,
                token,
              })
              if (result.ok) {
                const blob = await result.blob()
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                  'download',
                  `${model.name.replaceAll('.csv', '')}.pptx`,
                )
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
              } else {
                await new Promise((resolve) => setTimeout(resolve, 4000))
                await tryDownload()
              }
            }
            await tryDownload()
          } else {
            toast.error('Failed to generate report.')
          }
        })
        .finally(() => {
          setExport(false)
        })
    }
  }, [doExport])

  return (
    <Dropdown disabled={doExport}>
      <Dropdown.Toggle
        as={ButtonMMM}
        className={`py-1 no-drop-aid ${doExport ? 'opacity-50 pointer-events-none' : ''}`}
        disabled={doExport}
      >
        <span className="inline-flex items-center">
          {t(doExport ? t('Generating report') : t('Export report'))}{' '}
          <FaChevronDown size={20} className="ms-2" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item
          onClick={() => setExport(true)}
          className="model-dropdown"
          disabled={doExport}
        >
          <span className="inline-flex items-center">
            <FaFilePowerpoint className="me-2" /> {t('Download report')}
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
