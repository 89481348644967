import { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'

export default function ConnectorItem({
  name,
  icon,
  alter = false,
  children,
  className,
  autoOpen,
  maxH = 'max-h-[400vh]',
  ...props
}) {
  const [isOpen, setIsOpen] = useState(autoOpen ?? false)
  return (
    <Row
      onClick={() => {
        if (!isOpen) {
          ;[...document.querySelectorAll('.open-conector-item')].forEach((e) =>
            e?.click(),
          )
        }
        setIsOpen((o) => !o)
      }}
      {...props}
      className={`connector-item py-2 px-2 rounded-3xl cursor-pointer ${alter ? 'bg-transparent border-1 border-connectorSecondary text-2xl' : 'bg-connectorItem'} ${isOpen ? 'open-conector-item' : 'hover:bg-connectorItemHover'} ${className ?? ''}`}
    >
      <Col xs={12}>
        <Row className="connector-item-head inline-flex flex-nowrap">
          <Col xs={12} className="flex items-center justify-end ">
            {icon}
          </Col>
          <Col className="font-bold text-white flex items-center ps-0" xs={12}>
            {name}
          </Col>
          <Col className="flex items-center px-0" xs={12}>
            <FaChevronRight
              className={`${isOpen ? 'rotate-90' : ''} duration-300`}
            />
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        className={`${isOpen ? `${maxH} overflow-auto` : 'max-h-0 overflow-hidden'} duration-500`}
      >
        {children}
      </Col>
    </Row>
  )
}
