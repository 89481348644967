import React from 'react'
import { Col, Row, Dropdown } from 'react-bootstrap'
import SidebarItem from './SidebarItem'
import lookerStudioImg from '../assets/logos/looker-studio.png'
import googleSheets from '../assets/logos/google-sheets.png'
import powerbi from '../assets/logos/powerbi.png'
import apiQueryManager from '../assets/logos/api-query-manager.png'
import awsRedShift from '../assets/logos/aws-redshift.png'
import awsS3 from '../assets/logos/aws-s3.png'
import bigQuery from '../assets/logos/bigquery.png'
import mmmLogo from '../assets/logos/MMM_icon_w.svg'
import blendedLogo from '../assets/logos/blended-icon.svg'
import hubIcon from '../assets/logos/hub-icon.svg'
import { useAuth } from '../providers/AuthProvider'
import { LiaCogSolid } from 'react-icons/lia'
import { FaPlus } from 'react-icons/fa'
import { config } from '../Constants'

const DATASLAYER_URL = config.DATASLAYER_URL
const TOTAL_SERVICES = 7
import { t } from 'i18next'

const SideBar = () => {
  const { user } = useAuth()
  return (
    <Col id="sidebar-col" className="px-0 flex flex-col">
      <Row
        className={`sidebar-content m-0 flex-nowrap  ${user?.admin ? 'admin-sidebar' : ''}`}
      >
        {user?.services?.includes('Google Sheets') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/google_sheets/license`}
            imgSrc={googleSheets}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#23A566"
            tooltipTitle="Google Sheets"
          />
        )}
        {user?.services?.includes('Looker Studio') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/datastudio/license`}
            imgSrc={lookerStudioImg}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#4285F4"
            tooltipTitle="Looker Studio"
          />
        )}
        {user?.services?.includes('API') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/query-manager`}
            imgSrc={apiQueryManager}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#FF3FD2"
            tooltipTitle="Query Manager"
          />
        )}
        {user?.services?.includes('Big Query') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/bigquery`}
            imgSrc={bigQuery}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#4386fa"
            tooltipTitle="Big Query"
          />
        )}
        {user?.services?.includes('Power BI') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/power-bi`}
            imgSrc={powerbi}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#B25400"
            tooltipTitle="Power BI"
          />
        )}
        {user?.services?.includes('Amazon Redshift') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/aws_redshift`}
            imgSrc={awsRedShift}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#FFF"
            tooltipTitle="Amazon Redshift"
          />
        )}
        {user?.services?.includes('Amazon S3') && (
          <SidebarItem
            href={`${DATASLAYER_URL}/aws_s3`}
            imgSrc={awsS3}
            imgAlt="Logo"
            primaryColor="#FFF"
            secondaryColor="#FFF"
            tooltipTitle="Amazon S3"
          />
        )}
        {user?.services?.length < TOTAL_SERVICES && (
          <SidebarItem
            href={`${DATASLAYER_URL}`}
            imgAlt="Logo"
            tooltipTitle="Add"
            className="add-service-item"
          >
            <FaPlus size={16} />
          </SidebarItem>
        )}
      </Row>
      <Row className={`sidebar-footer m-0 flex-nowrap`}>
        {user?.admin && (
          <Dropdown id="sidebar-dropdown" className="p-0">
            <Dropdown.Toggle as="div">
              <SidebarItem
                href="#"
                imgAlt="Logo"
                primaryColor="#FFF"
                secondaryColor="#FFF"
                tooltipTitle="Configurations"
                className="text-white hover-invert-svg"
              >
                <LiaCogSolid size={32} />
              </SidebarItem>
            </Dropdown.Toggle>
            <Dropdown.Menu
              variant="dark"
              style={{ transform: 'translateX(-120px)' }}
            >
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/manage-companies`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Admin companies')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/manage-accounts`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Admin Users')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/manage-issues`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Admin Issues')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/manage-coupons`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Admin Coupons')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/subscription-logs`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Admin Suscriptions Logs')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/manage-templates`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Templates')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/query-status`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Query status')}
                </span>
              </Dropdown.Item>
              <Dropdown.Divider className="border-white" />
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/server-stats`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Server Stats')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/admin-stats`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Monthly Stats')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/daily-stats`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Daily Stats')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/invoice-list`)
                }
              >
                <span className="inline-flex items-center">
                  {t('Invoices')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="model-dropdown"
                onClick={() =>
                  (window.location.href = `${DATASLAYER_URL}/payout-list`)
                }
              >
                <span className="inline-flex items-center">{t('Payouts')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <SidebarItem
          className="mmm-sidebar-icon icon-item"
          href="#"
          imgSrc={mmmLogo}
          imgAlt="Logo"
          primaryColor="#7724FF"
          secondaryColor="#7724FF"
          tooltipTitle="Morpheus"
        />
        <SidebarItem
          className="blended-sidebar-icon"
          href={`${DATASLAYER_URL}/blend/guide`}
          imgSrc={blendedLogo}
          imgAlt="Blended Logo"
          tooltipTitle="Blended Connectors"
        />
        <SidebarItem
          className="hub-sidebar-icon hover-invert-svg"
          href={`${DATASLAYER_URL}/hub`}
          imgSrc={hubIcon}
          imgAlt="Logo"
        />
      </Row>
    </Col>
  )
}

export default SideBar
