import React from 'react'
const SidebarItem = ({
  href,
  imgSrc,
  imgAlt,
  primaryColor,
  secondaryColor,
  tooltipTitle,
  className,
  children,
}) => {
  const itemStyle = {
    '--item-primary-color': primaryColor,
    '--item-secondary-color': secondaryColor,
  }

  return (
    <a
      href={href}
      className={'item' + (className ? ' ' + className : '')}
      style={itemStyle}
      data-toggle="tooltip"
      data-placement="bottom"
      title={tooltipTitle}
    >
      {imgSrc && <img className="icon-item" src={imgSrc} alt={imgAlt} />}
      {children}
    </a>
  )
}

export default SidebarItem
