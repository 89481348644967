import GoogleTrends from './custom-connection/GoogleTrends'
import GoogleSheets from './custom-connection/GoogleSheets'
import Database from './custom-connection/Database'
import JSONCSVXML from './custom-connection/JSONCSVXML'
import FacebookPublicData from './custom-connection/FacebookPublicData'
import InstagramPublicData from './custom-connection/InstagramPublicData'
import BigQuery from './custom-connection/BigQuery'
import { LuSun } from 'react-icons/lu'
import { Image } from 'react-bootstrap'
import { MdOutlineCurrencyExchange } from 'react-icons/md'

export const datasources_with_valid_time_dimension = [
  'facebook',
  'bing',
  'dbm',
  'dcm',
  'searchconsole',
  'criteo',
  'youtube',
  'linkedin',
  'googlemybusiness',
  'instagram',
  'facebookinsights',
  'twitter',
  'shopify',
  'stripe',
  'mailchimp',
  'tiktok',
  'linkedinpages',
  'snapchat',
  'instagram_public_data',
  'woocommerce',
  'applesearchads',
  'google_trends',
  'ads',
  'analytics_v4',
  'pinterest',
  'twitter_insights',
  'klaviyo',
  'amazon_ads',
  'google_ad_manager',
]

export const datasources_with_valid_metrics_for_media_channel = [
  'facebook',
  'bing',
  'dbm',
  'dcm',
  'criteo',
  'linkedin',
  'twitter',
  'mailchimp',
  'tiktok',
  'snapchat',
  'applesearchads',
  'ads',
  'analytics_v4',
  'pinterest',
  'amazon_ads',
  'google_ad_manager',
]

export const coming_soon = new Set([
  //'google_sheets', // front ready
  //'google_trends', // front ready
  //'database', // front ready
  //'bigquery',
  //'json_csv_xml', // front ready
  //'facebook_public_data', // front ready
  //'hubspot_basic',
])

export const datasource_name_to_id = {
  'Google Analytics': 'analytics',
  'Google Adwords (Deprecated)': 'adwords',
  Facebook: 'facebook',
  'Microsoft Advertising': 'bing',
  DV360: 'dbm',
  DCM: 'dcm',
  'Google Search Console': 'searchconsole',
  Criteo: 'criteo',
  YouTube: 'youtube',
  Database: 'database',
  BigQuery: 'bigquery',
  'JSON/CSV/XML': 'json_csv_xml',
  'Search Ads 360': 'sa360',
  LinkedIn: 'linkedin',
  'Google My Business': 'googlemybusiness',
  Instagram: 'instagram',
  'Facebook Insights': 'facebookinsights',
  'X Ads (Twitter)': 'twitter',
  Shopify: 'shopify',
  Stripe: 'stripe',
  Mailchimp: 'mailchimp',
  'Tik Tok': 'tiktok',
  'Linkedin Pages': 'linkedinpages',
  Snapchat: 'snapchat',
  'Instagram Public Data': 'instagram_public_data',
  WooCommerce: 'woocommerce',
  MongoDB: 'mongodb',
  'Apple Search Ads': 'applesearchads',
  'Google Trends': 'google_trends',
  'Google Ads': 'ads',
  'Facebook Public Data': 'facebook_public_data',
  'Google Analytics V4': 'analytics_v4',
  Pinterest: 'pinterest',
  'TikTok Insights': 'tiktok_insights',
  'X Insights Premium (Twitter)': 'twitter_insights',
  'X Insights (Twitter)': 'twitter_organic',
  Klaviyo: 'klaviyo',
  'Amazon ads': 'amazon_ads',
  'Google Ad Manager': 'google_ad_manager',
  'HubSpot Marketing Pro': 'hubspot',
  'Google Sheets': 'google_sheets',
  'HubSpot Basic': 'hubspot_basic',
  'Search Ads 360 NEW': 'search_ads_360',
}

export const datasources_without_cost = [
  'searchconsole',
  'youtube',
  'googlemybusiness',
  'instagram',
  'facebookinsights',
  'shopify',
  'stripe',
  'linkedinpages',
  'instagram_public_data',
  'woocommerce',
  'mongodb',
  'google_trends',
  'facebook_public_data',
  'tiktok_insights',
  'twitter_insights',
  'twitter_organic',
  'klaviyo',
  'hubspot',
  'google_sheets',
  'hubspot_basic',
  'search_ads_360',
]

export const datasources_cost_metric = {
  facebook: ['Total Cost', 'spend'],
  bing: ['Cost', 'Spend'],
  dbm: ['Total Media Cost', 'METRIC_TOTAL_MEDIA_COST_PARTNER'],
  dcm: ['Media Cost', 'dfa:mediaCost'],
  criteo: ['Cost', 'AdvertiserCost'],
  linkedin: ['Cost', 'costInLocalCurrency'],
  twitter: ['Cost', 'billed_charge_local_micro'],
  mailchimp: ['Campaign e-commerce total spent (USD)', 'campaign_total_spent'],
  tiktok: ['Cost', 'spend'],
  snapchat: ['Spend', 'spend'],
  applesearchads: ['Cost', 'cost'],
  ads: ['Cost', 'Cost'],
  analytics_v4: ['Advertiser ad cost', 'advertiserAdCost'],
  pinterest: ['Spend', 'spend_in_dollar'],
  amazon_ads: ['Cost', 'cost'],
  google_ad_manager: ['Cost', 'DP_COST'],
}

export const special_connection_datasources_without_local = ['google_trends']

export const special_connection_datasources = {
  google_trends: GoogleTrends,
  json_csv_xml: JSONCSVXML,
}

export const special_connection_account_datasources = {
  database: Database,
  google_sheets: GoogleSheets,
  facebook_public_data: FacebookPublicData,
  instagram_public_data: InstagramPublicData,
}

export const special_connection_account_subaccount_datasources = {
  bigquery: BigQuery,
}

export const single_hierarchy_connectors = ['google_ad_manager']

//Swap id with value of the datasource for each account since ids are repeated
export const special_id_accounts = {
  dcm: 'advertiser_id',
  dbm: 'advertiser_id',
}

export const campaign_dimensions_datasources = [
  'ads',
  'facebook',
  'bing',
  'dbm',
  'dcm',
  'criteo',
  'linkedin',
  'twitter',
  'mailchimp',
  'tiktok',
  'snapchat',
  'applesearchads',
  'pinterest',
  'klaviyo',
  'amazon_ads',
  //'hubspot_basic',
  'search_ads_360',
]

export const adgroup_dimensions_datasources = [
  'ads',
  'facebook',
  'bing',
  'criteo',
  'linkedin',
  'twitter',
  // 'mailchimp',
  'tiktok',
  'snapchat',
  'applesearchads',
  'pinterest',
  'amazon_ads',
  'search_ads_360',
]

export const ad_dimensions_datasources = [
  'ads',
  'facebook',
  'bing',
  'dbm',
  'dcm',
  'linkedin',
  'twitter',
  // 'mailchimp',
  'tiktok',
  'snapchat',
  'applesearchads',
  'pinterest',
  'amazon_ads',
  'google_ad_manager',
]

export const translate_account_name = {
  google_trends: 'Split by search term',
}

export const search_term_dimensions_datasources = ['google_trends']

export const datasource_uses_special_key_for_subaccounts = {
  bigquery: (account) => `${account?.id}$$$$$${account?.name}`,
}

export const custom_static_config_datasource = [
  'facebook_public_data',
  'instagram_public_data',
]

export const disabled_connectors = [
  'mongodb',
  'tiktok_insights',
  'twitter_organic',
  'hubspot',
  'search_ads_360',
  'facebook_public_data',
  'instagram_public_data',
]

export const AVAILABLE_ENRICHMENTS = [
  {
    name: 'Weather data',
    icon: <LuSun size={25} color="#FADE3F" />,
    requirements: new Set(['location']),
    metrics: () => {
      return [
        {
          id: 'tavg',
          name: 'Average temperature',
        },
        {
          id: 'tmin',
          name: 'Minimum temperature',
        },
        {
          id: 'tmax',
          name: 'Maximum temperature',
        },
        {
          id: 'prcp',
          name: 'Precipitation',
        },
        {
          id: 'snow',
          name: 'Snow',
        },
        {
          id: 'wdir',
          name: 'Wind direction',
        },
        {
          id: 'wspd',
          name: 'Wind speed',
        },
        {
          id: 'wpgt',
          name: 'Peak gust',
        },
        {
          id: 'pres',
          name: 'Pressure',
        },
        {
          id: 'tsun',
          name: 'Sunshine duration',
        },
      ]
    },
  },
  {
    name: 'Holidays data',
    icon: <Image width={25} src="/dataslayer_datasources/holyday.svg" />,
    requirements: new Set(['country']),
    metrics: () => {
      return [
        {
          id: 'holidays',
          name: 'holidays',
        },
      ]
    },
  },
  {
    name: 'Currency exchange',
    icon: <MdOutlineCurrencyExchange size={25} color="#79a471" />,
    requirements: new Set(['dual_currency']),
    metrics: () => {
      return [
        {
          id: 'Exchange rate',
          name: 'exchange_rate',
        },
      ]
    },
  },
]

export const ESSENTIALS_KPIS = [
  { id: 'totalRevenue', name: 'Total revenue' },
  { id: 'purchaseRevenue', name: 'Purchase revenue' },
  { id: 'itemRevenue', name: 'Item revenue' },
  { id: 'ecommercePurchases', name: 'Ecommerce purchases' },
  { id: 'conversions:purchase', name: 'Conversions count for purchase' },
  { id: 'keyEvents', name: 'Key events' },
  { id: 'eventCount', name: 'Event count' },
  { id: 'transactions', name: 'Transactions' },
  { id: 'newUsers', name: 'New users' },
]
