const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function formatDateddmmyy(date, separator = '/') {
  const d = new Date(date)
  return `${d.getDate()}${separator}${
    monthNames[d.getMonth()]
  }${separator}${d.getFullYear()}`
}

const lookupDefault = [
  { value: 1e9, symbol: 'B' },
  { value: 1e6, symbol: 'M' },
  { value: 1, symbol: '' },
]

export function round(num, decimals = 2) {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

export function defaultFormat({ num, digits = 2, wrap, ...props }) {
  let result
  // eslint-disable-next-line
  if (num == 0) result = 0
  else {
    if (Number.isNaN(Number.parseFloat(num))) result = num
    else {
      num = parseFloat(num)
      let n = ''
      if (num < 0) {
        n = '-'
        num = Math.abs(num)
      }

      const item = lookupDefault.find(function (item) {
        return num >= item.value
      })

      result =
        n +
        (item
          ? round(num / item.value, digits) + item.symbol
          : num < Math.pow(10, -digits)
            ? num?.toExponential(digits)
            : round(num, digits))
    }
  }
  return result
}

export function abbrNum(num) {
  // code from:
  // https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn#answer-32638472
  try {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return '0'
    } // terminate early
    var isNegative = num < 0
    num = Math.abs(num)
    let k = 0

    let c, c1, c2
    if (num < 999.5) {
      c =
        (c1 = num.toString()).length < (c2 = num.toPrecision(4)).length
          ? c1
          : c2
    } else if (num < 9999.5) {
      c = num.toFixed(0)
    } else {
      var power = num.toPrecision(2).split('e')[1].slice(1) // get power
      k = Math.min(Math.floor(power / 3), 14)
      c = (num / Math.pow(10, k * 3)).toPrecision(3)
    }
    var d = c < 0 ? c : Math.abs(c) // enforce -0 is 0

    return (isNegative ? '-' : '') + d + ['', 'K', 'M', 'B', 'T'][k]
  } catch (e) {
    return num
  }
}

export function isValidDate(item) {
  return (
    Object.prototype.toString.call(item) === '[object Date]' && !isNaN(item)
  )
}

export const readableNumber = (num, decimalComma = true) => {
  if (!num && num !== 0) return ''
  if (Math.abs(num) < 1) return Number(num.toFixed(num > 0.0001 ? 4 : 10))
  const [integer, decimal] = String(num).split('.')
  const integerFormatted = integer.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    decimalComma ? ',' : '.',
  )
  const decimalFormatted = decimal || ''
  if (decimalFormatted.length === 0) {
    return integerFormatted
  }
  if (decimalComma) {
    return `${integerFormatted}.${decimalFormatted}`
  }
  return `${integerFormatted},${decimalFormatted}`
}

export const readableNumberMMM = (
  num,
  roundTo = 2,
  roundToUnderzero = null,
) => {
  if (!num && num !== 0) return ''
  if (roundToUnderzero && num < 1) roundTo = roundToUnderzero

  const res = readableNumber(round(num, roundTo))
  if (num && `${res}` === '0') return num.toExponential(2)
  return res
}

export function formatDateIndex(date) {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = d.getMonth() + 1 // Los meses van de 0 a 11
  const day = d.getDate()
  const hours = d.getHours()
  const minutes = d.getMinutes()
  const seconds = d.getSeconds()

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
