export const placeholderPrediction = {
  media_data: [
    '2020-12-23 00:00:00',
    '2021-01-01 00:00:00',
    '2021-01-08 00:00:00',
    '2021-01-15 00:00:00',
    '2021-01-22 00:00:00',
    '2021-01-29 00:00:00',
    '2021-02-05 00:00:00',
    '2021-02-12 00:00:00',
    '2021-02-19 00:00:00',
  ],
  y: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  y_pred: [
    -114.7669906616211, 130.0032958984375, 141.3546142578125,
    106.74394989013672, 56.561973571777344, 10.753861427307129,
    -15.869422912597656, -19.659154891967773, 2.0758302211761475,
  ],
  std: [
    348.5731201171875, 307.93988037109375, 293.5345764160156, 282.6221618652344,
    277.2158508300781, 276.3514709472656, 277.2687683105469, 277.35992431640625,
    275.0123291015625,
  ],
  upper_bound: [
    415.783935546875, 632.2473754882812, 633.4839477539062, 567.6331787109375,
    493.1689147949219, 449.7001647949219, 441.0314636230469, 441.7566833496094,
    451.96636962890625,
  ],
  lower_bound: [
    -682.274169921875, -375.292236328125, -332.7704772949219,
    -357.8237609863281, -411.7041931152344, -451.32916259765625,
    -487.11700439453125, -486.52215576171875, -453.74163818359375,
  ],
  accuracy: -6.9,
  contribution: {
    contribution: [
      1.216360330581665, 0.050315506756305695, 0.06872604042291641,
      0.5580332279205322, 0.2985900640487671, 0.06767039000988007,
      0.0004693031369242817, 0.00036456729867495596, 0.00063339649932459,
    ],
    max_contribution: [
      25.73331069946289, 0.746979296207428, 3.758622646331787,
      2.7805466651916504, 1.4701979160308838, 0.14483997225761414,
      0.09808436036109924, 0.07619456946849823, 0.13237987458705902,
    ],
    min_contribution: [0, 0, 0, 0, 0, 0.0065086702816188335, 0, 0, 0],
  },
}

export const placeholderFunnel = {
  columns: [
    'index',
    'baseline',
    'channel_2',
    'channel_3',
    'channel_4',
    'channel_5',
    'channel_6',
    'channel_7',
    'channel_8',
    'channel_9',
    'channel_10',
  ],
  data: [
    [
      'baseline',
      0,
      0.1,
      0.1,
      0.1,
      0.1,
      8.8445539412,
      0.2878935075,
      8.8360591793,
      8.8328567089,
      8.8309504313,
    ],
    [
      'Advertiser ad cost (Cross-network)',
      0,
      0,
      0.1,
      0.9203686707,
      0.4660748752,
      1.0413026122,
      0.3411749116,
      -32.1098778082,
      -74.8137546866,
      -18.732625848,
    ],
    [
      'channel_2',
      0,
      -1.143212964,
      0,
      -0.4200385156,
      -0.7100894807,
      0.2818671896,
      8.836002939,
      0.1063799319,
      0.3628156537,
      4.6938242428,
    ],
    [
      'channel_3',
      0,
      -0.2097587626,
      8.9438567841,
      0,
      8.8538625,
      8.7147604218,
      8.9395485483,
      0.6869871711,
      0.1,
      1.3227036861,
    ],
    [
      'channel_4',
      0,
      -0.7009963687,
      8.7610609636,
      0.857753327,
      0,
      8.0996610581,
      -5.7338376684,
      0.1,
      4.3974014191,
      0.4875690252,
    ],
    [
      'channel_5',
      0,
      -1.1191503793,
      1.0982296002,
      -0.4048915236,
      0.0928515923,
      0,
      8.7734831273,
      0.2123320628,
      0.6752877478,
      5.09046875,
    ],
    [
      'channel_6',
      0,
      -0.5871850499,
      8.8989648512,
      0.1,
      2.3809134478,
      8.8842863921,
      0,
      0.1895690928,
      4.2566075773,
      0.1,
    ],
    [
      'channel_7',
      0,
      0.0999952501,
      -0.0634725171,
      -0.247614033,
      -0.0058310152,
      -0.0669704364,
      -0.0010759465,
      0,
      -5.1320205271,
      8.9079795195,
    ],
    [
      'channel_8',
      0,
      0.0999999865,
      -0.0028586425,
      -0.0038970846,
      -0.0921514371,
      -0.0200918129,
      -0.0030181605,
      8.8218892178,
      0,
      8.6047802678,
    ],
    [
      'channel_9',
      0,
      0.099999958,
      -0.0021478777,
      -0.3030173908,
      -0.0083983183,
      -0.010096375,
      -0.2431502761,
      -4.6020772953,
      8.5785480714,
      0,
    ],
  ],
}

export const placeholderKPISummaryBreakdown = {
  columns: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ],
  index: [
    'channel_0',
    'channel_1',
    'channel_2',
    'channel_3',
    'channel_4',
    'channel_5',
    'channel_6',
    'channel_7',
    'channel_8',
    'channel_9',
    'channel_10',
    'channel_11',
  ],
  data: [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
}

export const placeholderAdstock = {
  spend: {
    x: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    y: [
      71, 67, 71, 74, 77, 80, 89, 81, 82, 83, 123, 92, 93, 93, 101, 91, 96, 117,
      99, 104,
    ],
  },
  adstock: {
    x: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    y: [
      33.427669525146484, 57.766395568847656, 67.67975616455078,
      72.11583709716797, 75.32273864746094, 78.336669921875, 84.55767822265625,
      83.9185562133789, 82.17435455322266, 82.49243927001953,
      104.38050842285156, 102.92752838134766, 94.98143005371094,
      93.0740737915039, 97.29197692871094, 94.94677734375, 94.49838256835938,
      106.89572143554688, 105.51344299316406, 103.11463928222656,
    ],
  },
}

export const placeholderSaturation = {
  channel_0: {
    x: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    y: [
      0, 516.9329058274812, 1021.0445579770819, 1512.8061131621614,
      1992.6659219712537, 2461.050892269393, 2918.3677559464336,
      3365.004246912832, 3801.3301975138343, 4227.698559878389,
      4644.446358131005, 5051.895576865641, 5450.353990804212,
      5840.115940132512, 6221.463055618391, 6594.664937266299,
      6959.979789944967, 7317.65501913753, 7667.92778970265, 8011.025550298539,
      8347.166525906703, 8676.560180696562, 8999.407653293949,
      9315.902166354086, 9626.229412191555, 9930.567916084407,
      10229.089378746005, 10521.958999345032, 10809.335780350544,
      11091.372815384051, 11368.217561173564, 11640.012094624657,
      11906.893355950193, 12168.993378732872, 12426.439507732677,
      12679.35460519416, 12927.857246355747, 13172.061904814773,
      13412.079128356974, 13648.015705817954, 13879.974825505664,
      14108.05622567777, 14332.356337534802, 14552.96842115995,
      14769.982694807917, 14983.486457919573, 15193.564208214631,
      15400.29775319244, 15603.766316349826, 15804.046638405902,
    ],
    y_pred: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    r2: 0.85,
    v: 115145.8204244568,
    v_max: 41166.920458412154,
    km: 10876.52531079963,
    coefficients: [
      0, 45.18192782357393, 90.36385564714786, 135.54578347072177,
      180.72771129429572, 225.90963911786963, 271.09156694144355,
      316.2734947650175, 361.45542258859143, 406.63735041216535,
      451.81927823573926, 497.00120605931323, 542.1831338828871,
      587.3650617064611, 632.546989530035, 677.728917353609, 722.9108451771829,
      768.0927730007568, 813.2747008243307, 858.4566286479046,
      903.6385564714785, 948.8204842950524, 994.0024121186265,
      1039.1843399422003, 1084.3662677657742, 1129.548195589348,
      1174.7301234129222, 1219.9120512364962, 1265.09397906007,
      1310.275906883644, 1355.457834707218, 1400.6397625307918,
      1445.8216903543657, 1491.0036181779396, 1536.1855460015136,
      1581.3674738250875, 1626.5494016486614, 1671.7313294722353,
      1716.9132572958092, 1762.0951851193831, 1807.277112942957,
      1852.459040766531, 1897.6409685901049, 1942.8228964136788,
      1988.004824237253, 2033.1867520608268, 2078.3686798844005,
      2123.5506077079745, 2168.7325355315484, 2213.9144633551223,
    ],
    linspace_x: [
      0, 138.31329780715532, 276.62659561431065, 414.93989342146597,
      553.2531912286213, 691.5664890357766, 829.8797868429319,
      968.1930846500873, 1106.5063824572426, 1244.819680264398,
      1383.1329780715532, 1521.4462758787085, 1659.7595736858639,
      1798.0728714930192, 1936.3861693001745, 2074.69946710733,
      2213.012764914485, 2351.3260627216405, 2489.639360528796,
      2627.952658335951, 2766.2659561431065, 2904.579253950262,
      3042.892551757417, 3181.2058495645724, 3319.5191473717277,
      3457.832445178883, 3596.1457429860384, 3734.4590407931937,
      3872.772338600349, 4011.0856364075044, 4149.39893421466,
      4287.712232021815, 4426.02552982897, 4564.338827636126, 4702.652125443281,
      4840.965423250436, 4979.278721057592, 5117.592018864747,
      5255.905316671902, 5394.218614479058, 5532.531912286213,
      5670.845210093368, 5809.158507900524, 5947.471805707679,
      6085.785103514834, 6224.0984013219895, 6362.411699129145, 6500.7249969363,
      6639.0382947434555, 6777.351592550611,
    ],
    maximum: 65000,
    coef: 0.3266636580856403,
    sat: 37613.95491312529,
    linspace_y: [
      0, 516.9329058274812, 1021.0445579770819, 1512.8061131621614,
      1992.6659219712537, 2461.050892269393, 2918.3677559464336,
      3365.004246912832, 3801.3301975138343, 4227.698559878389,
      4644.446358131005, 5051.895576865641, 5450.353990804212,
      5840.115940132512, 6221.463055618391, 6594.664937266299,
      6959.979789944967, 7317.65501913753, 7667.92778970265, 8011.025550298539,
      8347.166525906703, 8676.560180696562, 8999.407653293949,
      9315.902166354086, 9626.229412191555, 9930.567916084407,
      10229.089378746005, 10521.958999345032, 10809.335780350544,
      11091.372815384051, 11368.217561173564, 11640.012094624657,
      11906.893355950193, 12168.993378732872, 12426.439507732677,
      12679.35460519416, 12927.857246355747, 13172.061904814773,
      13412.079128356974, 13648.015705817954, 13879.974825505664,
      14108.05622567777, 14332.356337534802, 14552.96842115995,
      14769.982694807917, 14983.486457919573, 15193.564208214631,
      15400.29775319244, 15603.766316349826, 15804.046638405902,
    ],
    linspace_r2: 0.85,
    notes: [
      'This notes will be removed at any time',
      'coef key is not multiplied by vthe linspace detail is 100 points not 1000',
      'maximum key is the diminishin_point[i]',
    ],
  },
}

export const placehoderOptimization = {
  columns: [
    'channel_id_0',
    'channel_id_1',
    'channel_id_2',
    'channel_id_3',
    'channel_id_4',
    'channel_id_5',
    'channel_id_6',
    'channel_id_7',
  ],
  data: [
    [
      'channel_id_0',
      48712,
      16.7800006866,
      22638.80078125,
      16.7831821442,
      2.1517040797,
      23835184.827862557,
      66957.8404570756,
    ],
    [
      'channel_id_1',
      10723,
      2.3599998951,
      3176.8000488281,
      2.3550784588,
      3.3754091649,
      2756858.068264595,
      3574.3333343912,
    ],
    [
      'channel_id_2',
      646.4,
      0.9499999881,
      1280.0999755859,
      0.9490001798,
      0.5049605596,
      6502200.463177293,
      1939.1999999495,
    ],
    [
      'channel_id_3',
      5822.5,
      1.5,
      2025.8000488281,
      1.5018373728,
      2.8741730969,
      226441.9122768423,
      17467.4999998702,
    ],
    [
      'channel_id_4',
      11457.5,
      2.2699999809,
      3066.6999511719,
      2.2734692097,
      3.736100754,
      384312.0379575203,
      3819.1666885341,
    ],
    [
      'channel_id_5',
      24562,
      1.3600000143,
      1837,
      1.3618727922,
      13.3707131192,
      102771.3806228628,
      8188.4793695738,
    ],
    [
      'channel_id_6',
      19.6,
      0.0399999991,
      58,
      0.0429913998,
      0.3379310345,
      326891.0060653934,
      6.5334444906,
    ],
    [
      'channel_id_7',
      14.8,
      0.0299999993,
      45,
      0.0333968736,
      0.3288888889,
      247691.8101676257,
      22.0121011133,
    ],
    [
      'channel_id_8',
      25.8,
      0.0599999987,
      78.3000030518,
      0.0580234788,
      0.3295019029,
      433941.4317609896,
      8.6000155483,
    ],
  ],
}

export const placeholderTable = {
  columns: [
    'index',
    'Media spend',
    'Media contribution',
    'Media outcome',
    'Contribution %',
    'CPA',
    'Channel Max spend',
  ],
  data: [
    ['Advertiser ad cost (Cross-network)', 0, 0, 0, 0, 0, 0],
    ['Advertiser ad cost (Paid Search)', 0, 0, 0, 0, 0, 0],
    ['Advertiser ad cost (Display)', 0, 0, 0, 0, 0, 0],
    ['Mailchimp Automations Cost', 0, 0, 0, 0, 0, 0],
    ['Mailchimp Regular Cost', 0, 0, 0, 0, 0, 0],
    ['Registrations', 0, 0, 0, 0, 0, 0],
    ['Average temperature', 0, 0, 0, 0, 0, 0],
    ['Minimum temperature', 0, 0, 0, 0, 0, 0],
    ['Maximum temperature', 0, 0, 0, 0, 0, 0],
  ],
}
