import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import { ResponsiveLine } from '@nivo/line'
import { useQuery } from 'react-query'
import { BasicTooltip } from '@nivo/tooltip'

import { useAuth } from '../../providers/AuthProvider'
import CustomSelect from '../CustomSelect'
import {
  getTextWidth,
  interpolateXYLine,
  zip,
  nivoProps,
  generateMMMColorMap,
} from '../../utility/model'
import { getMMMSaturationCurves } from '../../services/model'
import Loader from '../Loader'
import { placeholderSaturation } from './placeholders'
import UpgradeToPro from './UpgradeToPro'

function SpendArea({ xScale, yScale, innerWidth, innerHeight, data, areas }) {
  const optimizePoint = useMemo(
    () => interpolateXYLine(xScale, yScale, areas.best, areas.line, true),
    // eslint-disable-next-line
    [data, areas],
  )

  const saturationPoint = useMemo(
    () => interpolateXYLine(xScale, yScale, areas.good, areas.line, true),
    // eslint-disable-next-line
    [data, areas],
  )
  const color = 'var(--mmm-tables-negative-graph-bar-color)'

  const overlap =
    optimizePoint &&
    saturationPoint &&
    xScale(saturationPoint.x) - xScale(optimizePoint.x) < 110
  const yOffset = overlap ? -25 : 0

  return (
    <>
      {optimizePoint && (
        <>
          <rect
            x={0}
            y={yScale(optimizePoint.y)}
            width={xScale(optimizePoint.x)}
            height={innerHeight - yScale(optimizePoint.y)}
            strokeWidth={1}
            stroke={'#00ff0099'}
            fill="#00ff0033"
          />
        </>
      )}
      {optimizePoint && saturationPoint && (
        <>
          <rect
            x={xScale(optimizePoint.x)}
            y={0}
            width={xScale(saturationPoint.x) - xScale(optimizePoint.x)}
            height={yScale(optimizePoint.y)}
            strokeWidth={2}
            stroke="#f3d55c33"
            fill="#ff956b22"
          />
          <path
            d={`M ${xScale(optimizePoint.x)},${0} L${xScale(
              optimizePoint.x,
            )},${innerHeight}`}
            className="simple-ant-trail"
            stroke={'var(--mmm-tables-graph-bar-color)'}
            strokeWidth="1"
            fill="transparent"
            strokeDasharray="3 6"
          />
          <g transform={`translate(${xScale(optimizePoint.x) - 45},-30)`}>
            <rect
              x={0}
              y={0}
              width={90}
              height={20}
              fill="var(--mmm-sidebar-color)"
              stroke={'var(--mmm-tables-graph-bar-color)'}
              strokeWidth={2}
              rx={4}
              ry={4}
            />
            <polyline
              points={`${0} ${0} ${4} ${-16} ${-4} ${-16}`}
              transform={`translate(45,35)`}
              fill={'var(--mmm-tables-graph-bar-color)'}
              strokeWidth="1"
            />
            <text
              className="label-media-contribution"
              x={30}
              y={15}
              fill={'var(--mmm-tables-graph-bar-color)'}
            >
              Best
            </text>
          </g>
          <rect
            x={xScale(saturationPoint.x)}
            y={0}
            width={innerWidth - xScale(saturationPoint.x)}
            height={Math.round(yScale(optimizePoint.y) / 2)}
            strokeWidth={2}
            stroke="#e4363644"
            fill="#e4363622"
          />
          <path
            d={`M ${xScale(saturationPoint.x)},${yOffset} L${xScale(
              saturationPoint.x,
            )},${innerHeight}`}
            className="simple-ant-trail"
            stroke={'var(--mmm-tables-negative-graph-bar-color)'}
            strokeWidth="1"
            fill="transparent"
            strokeDasharray="3 6"
          />
          <g
            transform={`translate(${xScale(saturationPoint.x) - 45},${
              -30 + yOffset
            })`}
          >
            <rect
              x={0}
              y={0}
              width={90}
              height={20}
              fill="var(--mmm-sidebar-color)"
              stroke={color}
              strokeWidth={2}
              rx={4}
              ry={4}
            />
            <polyline
              points={`${0} ${0} ${4} ${-16} ${-4} ${-16}`}
              transform={`translate(45,35)`}
              fill={color}
              strokeWidth="1"
            />
            <text
              className="label-media-contribution"
              x={5}
              y={15}
              fill={color}
            >
              Saturation
            </text>
          </g>
        </>
      )}
    </>
  )
}

function LegendBlock({ width, fill, text, ...props }) {
  return (
    <g {...props}>
      <rect
        x={0}
        y={0}
        rx="1"
        ry="1"
        width={13}
        height={13}
        fill={fill}
        stroke="var(--mmm-secondary-border-color)"
        strokeWidth={1}
      />
      <text className="label-best-spend" x={20} y={10} fill="white">
        {text}
      </text>
    </g>
  )
}

function Legend({ innerWidth, margin, channels, onChange, value }) {
  const [height, setHeight] = useState(200)
  const { t } = useTranslation()
  const legendWidth = useMemo(() => {
    return [
      getTextWidth(t('High profitability'), '14px Open Sans'),
      getTextWidth(t('Moderate profitability'), '14px Open Sans'),
      getTextWidth(t('No profitability'), '14px Open Sans'),
    ]
  }, [t])
  return (
    <>
      <g
        x={0}
        y={0}
        transform={`translate(${Math.max(
          innerWidth / 2 - legendWidth.reduce((a, b) => a + b, 30) / 2,
          0,
        )},-80)`}
      >
        <LegendBlock
          width={margin.right - 5}
          text={t('High profitability')}
          fill={'var(--mmm-tables-graph-bar-color-50)'}
          transform={`translate(0,0)`}
        />
        <LegendBlock
          width={margin.right - 5}
          text={t('Moderate profitability')}
          transform={`translate(${legendWidth[0] + 60},0)`}
          fill={'var(--mmm-tables-graph-bar-soft-orange-color)'}
        />
        <LegendBlock
          width={margin.right - 5}
          text={t('No profitability')}
          transform={`translate(${legendWidth[1] + legendWidth[0] + 120},0)`}
          fill={'var(--mmm-tables-negative-graph-bar-color-50)'}
        />
      </g>
      <foreignObject
        onMouseEnter={() => {
          setHeight(1000)
        }}
        onMouseLeave={() => {
          setHeight(100)
        }}
        x={-margin.left}
        y={-margin.top - 8}
        width={300}
        height={height}
      >
        <Row>
          <Col xs={12}>
            <CustomSelect
              value={value}
              type={'dark'}
              className="basic-single mt-2"
              classNamePrefix="select"
              isClearable={false}
              onChange={onChange}
              options={channels.map((c) => ({ value: c, label: c }))}
            />
          </Col>
        </Row>
      </foreignObject>
    </>
  )
}

function PointMap({ xScale, yScale, data, channel, areas, ...props }) {
  try {
    const saturationPoint = useMemo(
      () => interpolateXYLine(xScale, yScale, areas.good, areas.line, true),
      // eslint-disable-next-line
      [data, areas],
    )
    const limit = saturationPoint ? yScale(saturationPoint.y) + 5 : 0
    //map points to svg circles
    const points = zip([channel.x, channel.y_pred])

    return points
      .filter(([, y]) => yScale(y) > limit)
      .map(([x, y], i) => (
        <circle
          key={i}
          cx={'0'}
          cy={'0'}
          transform={`translate(${xScale(x)},${yScale(y)})`}
          r={2}
          fill={'#dedede'}
          stroke={'#00000000'}
          strokeWidth={1}
        />
      ))
  } catch (e) {
    return <></>
  }
}

function SuggestedSpend({
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  margin,
  data,
  line,
  optimization,
}) {
  const suggestion = useMemo(
    () => interpolateXYLine(xScale, yScale, optimization, line) ?? {},
    // eslint-disable-next-line
    [data],
  )
  const { t } = useTranslation()

  if (!suggestion.x) suggestion.x = optimization

  try {
    const x = xScale(suggestion.x)
    const y = suggestion.y ? yScale(suggestion.y) : 0
    return x > innerWidth / 2 ? (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={-106}
          y={-8}
          width={90}
          height={20}
          fill="var(--mmm-sidebar-color)"
          stroke={'yellow'}
          strokeWidth={2}
          rx={4}
          ry={4}
        />
        <polyline
          points={`${0} ${0} ${-16} ${4} ${-16} ${-4}`}
          fill={'yellow'}
          strokeWidth="1"
        />
        <text
          className="label-media-contribution"
          x={-96}
          y={6}
          fill={'yellow'}
        >
          {t('Suggested')}
        </text>
      </g>
    ) : (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={16}
          y={-8}
          width={90}
          height={20}
          fill="var(--mmm-main-color)"
          stroke={'yellow'}
          strokeWidth={2}
          rx={4}
          ry={4}
        />
        <polyline
          points={`${0} ${0} ${16} ${4} ${16} ${-4}`}
          fill={'yellow'}
          strokeWidth="1"
        />
        <text className="label-media-contribution" x={26} y={6} fill={'yellow'}>
          {t('Suggested')}
        </text>
      </g>
    )
  } catch (e) {}

  return <></>
}

export default function BestSpend({
  model = null,
  channel = null,
  targetSpend = null,
  isInView = true,
  target = null,
}) {
  const { t } = useTranslation()
  const { token, isEssential } = useAuth()
  const requirePro = isEssential
  const [selectedChannel, setSelectedChannel] = useState(
    channel ? { label: channel, value: channel } : null,
  )

  const { data, isLoading, isSuccess } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      if (requirePro) return placeholderSaturation
      const response = await getMMMSaturationCurves({
        modelId: model.id,
        token,
      })
      if (response.ok) return await response.json()
    },
    { staleTime: Infinity },
  )
  if (!window.item) window.item = data

  useEffect(() => {
    if (!selectedChannel && data && !isLoading) {
      const k = Object.keys(data)[0]
      if (k) setSelectedChannel({ label: k, value: k })
    }
  }, [data, selectedChannel, isLoading])

  const [lineData, maxY] = useMemo(() => {
    const lineData =
      selectedChannel && data?.[selectedChannel?.value]
        ? zip([
            data[selectedChannel.value].linspace_x,
            data[selectedChannel.value].linspace_y,
          ]).map(([x, y]) => ({ x, y }))
        : null
    return [lineData, lineData ? Math.max(...lineData.map((l) => l.y)) : 'auto']
  }, [data, selectedChannel])

  const maxTarget = useMemo(() => {
    if (!targetSpend || !lineData) return 'auto'

    return Math.max(...lineData.map((l) => l.x), targetSpend * 1.1)
    // eslint-disable-next-line
  }, [lineData])

  let best = null
  let saturation = null
  if (selectedChannel && data && data[selectedChannel.value]) {
    best = data[selectedChannel.value].v
    saturation = data[selectedChannel.value].maximum
    if (best > saturation) {
      best = saturation / 6
    }
  }
  const areas =
    selectedChannel && data && data[selectedChannel.value]
      ? {
          best: best,
          good: saturation,
          line: lineData,
        }
      : null

  const csvData = useMemo(() => {
    return lineData
      ? [['spend per week', 'outcome'], ...lineData.map((s) => [s.x, s.y])]
      : []
  }, [lineData])

  const colorMap = useMemo(() => {
    return generateMMMColorMap(model)
  }, [model])
  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess && lineData) {
      setShownData(lineData.map((v) => ({ ...v, y: 0 })))
      if (isInView) setTimeout(() => setShownData(lineData), 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, selectedChannel, data])

  const axisLeftFormat = useMemo(() => {
    if (model?.column_statistics?.[model?.training_config?.target]?.max < 1000)
      return '-.2f'
    else return '>(4.2s'
  }, [data, selectedChannel])

  return isLoading || !selectedChannel || !shownData || !areas ? (
    <Loader />
  ) : (
    <Row
      className={`relative inline-flex flex-nowrap flex-row  ${requirePro ? 'blur-under' : ''}`}
    >
      {requirePro && <UpgradeToPro />}
      <Col
        xs={12}
        style={{ maxWidth: '50px', minWidth: '50px' }}
        className="px-0"
      >
        <Row
          style={{
            minWidth: '400px',
            maxWidth: '400px',
            maxHeight: '50px',
            transform: 'rotate(-90deg)',
            transformOrigin: '0 0',
          }}
          className="absolute  left-0 bottom-0 text-xs text-center"
          title={`${t('pre-effects-on')} ${selectedChannel?.label} ${t('mid-effects-on')} ${target}`}
        >
          <Col className="px-0 inline-block text-truncate" xs={12}>
            {t('pre-effects-on') + selectedChannel?.label}
          </Col>
          <Col className="px-0 inline-block text-truncate" xs={12}>
            {t('mid-effects-on') + target}
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        style={{
          maxWidth: 'calc(100% - 100px)',
          minWidth: 'calc(100% - 100px)',
          minHeight: '450px',
        }}
        className="px-0"
      >
        <ResponsiveLine
          {...nivoProps}
          data={[
            {
              id: selectedChannel.label,
              color: '#aeaeaebb',
              data: shownData,
            },
          ]}
          margin={{
            top: channel ? 75 : 135,
            right: 60,
            bottom: 80,
            left: 60,
          }}
          xScale={{ type: 'linear', min: 'auto', max: maxTarget }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: maxY,
            stacked: false,
            reverse: false,
          }}
          sliceTooltip={({ slice }) => (
            <BasicTooltip
              id={`${slice.points[0].serieId}`}
              value={`${slice.points[0].data.yFormatted}`}
              color={colorMap?.[slice.points[0].serieId] ?? '#3ec73e'}
              enableChip
            />
          )}
          colors={(d) => d.color}
          curve={'linear'}
          lineWidth={3}
          yFormat=" >-.2f"
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            legend: (channel ?? '') + ' ' + t('Spend per week'),
            legendOffset: 66,
            legendPosition: 'middle',
            format: '>(4.2s',
            tickValues: 10,
            tickRotation: -30,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: axisLeftFormat,
            legend: '',
            legendOffset: -70,
            legendPosition: 'middle',
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices="x"
          legends={[]}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            ({ ...props }) => {
              return <SpendArea {...props} areas={areas} />
            },
            'lines',
            'points',
            channel && selectedChannel?.value
              ? () => <></>
              : ({ ...props }) => {
                  return (
                    <PointMap
                      {...props}
                      areas={areas}
                      channel={data?.[selectedChannel?.value]}
                    />
                  )
                },
            channel && targetSpend !== null
              ? ({ ...props }) => (
                  <SuggestedSpend
                    optimization={targetSpend}
                    line={areas.line}
                    {...props}
                  />
                )
              : () => <></>,
            'slices',
            channel
              ? () => <></>
              : ({ ...props }) => {
                  return (
                    <Legend
                      {...props}
                      channels={Object.keys(data)}
                      onChange={(v) => setSelectedChannel(v)}
                      value={selectedChannel}
                    />
                  )
                },
            () => (
              <div
                className="data-holder display-none"
                data-csv={encodeURIComponent(JSON.stringify(csvData))}
                data-filename={`shape_effect__${model.id}`}
              ></div>
            ),
          ]}
        />
      </Col>
    </Row>
  )
}
