import React, { useState } from 'react'
import CustomSelect from '../../CustomSelect'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonMMM from '../../ButtonMMM'
import { toast } from 'react-toastify'

const TYPE_OPTIONS = [
  { label: 'JSON', value: 'json' },
  { label: 'CSV', value: 'csv' },
  { label: 'XML', value: 'xml' },
]

export default function JSONCSVXML({ config, setConfig }) {
  const { t } = useTranslation()
  const [localConfig, setLocalConfig] = useState(() =>
    JSON.parse(
      JSON.stringify(config || { kwargs: { options: {}, type: 'json' } }),
    ),
  )

  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('URL')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          defaultValue={localConfig?.kwargs?.url ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.url = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Data type')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.type = e.value
            setLocalConfig({ ...localConfig })
          }}
          options={TYPE_OPTIONS}
          value={TYPE_OPTIONS.find(
            (o) => o.value === localConfig?.kwargs?.type,
          )}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Request')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          defaultValue={localConfig?.kwargs?.request ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.request = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('HTTP headers')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          defaultValue={localConfig?.kwargs?.headers ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.headers = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('JSON path')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          defaultValue={localConfig?.kwargs?.json_path ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.json_path = e.target.value
          }}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Options')}</span>
      </Col>
      <Col xs={12} className="mb-1">
        <div className="ms-4">
          <Form.Check
            type="switch"
            label={t('Exclude header row')}
            defaultChecked={!!localConfig?.kwargs?.options?.exclude_header_row}
            onChange={async (e) => {
              localConfig.kwargs = localConfig.kwargs || {}
              localConfig.kwargs.options = localConfig.kwargs.options || {}
              localConfig.kwargs.options.exclude_header_row = e.target.checked
              setLocalConfig({ ...localConfig })
            }}
          />
          <Form.Check
            type="switch"
            label={t('Replace null for blank')}
            defaultChecked={
              !!localConfig?.kwargs?.options?.replace_null_for_blank
            }
            onChange={async (e) => {
              localConfig.kwargs = localConfig.kwargs || {}
              localConfig.kwargs.options = localConfig.kwargs.options || {}
              localConfig.kwargs.options.replace_null_for_blank =
                e.target.checked
              setLocalConfig({ ...localConfig })
            }}
          />
          <Form.Check
            type="switch"
            label={t('Permit irregular rows in CSV')}
            defaultChecked={
              !!localConfig?.kwargs?.options?.permit_irregular_rows_csv
            }
            onChange={async (e) => {
              localConfig.kwargs = localConfig.kwargs || {}
              localConfig.kwargs.options = localConfig.kwargs.options || {}
              localConfig.kwargs.options.permit_irregular_rows_csv =
                e.target.checked
              setLocalConfig({ ...localConfig })
            }}
          />
        </div>
      </Col>

      <Col className="flex justify-end" xs={12}>
        <ButtonMMM
          onClick={() => {
            if (localConfig?.kwargs?.url) {
              if (localConfig.associatedTag) {
                localConfig.previousAssociatedTag = localConfig.associatedTag
                delete localConfig.associatedTag
              }
              localConfig.kwargs.json_path = localConfig.kwargs.json_path || ''
              localConfig.kwargs.headers = localConfig.kwargs.headers || ''
              setConfig(localConfig)
            } else
              toast.error(
                t('Please fill all the fields in the JSON/CSV/XML form'),
              )
          }}
        >
          {t('Save')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}
