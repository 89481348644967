import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../../CustomSelect'
import ButtonMMM from '../../ButtonMMM'
import { useQuery } from 'react-query'
import { getMetrics } from '../../../services/dataslayer'
import { useAuth } from '../../../providers/AuthProvider'
import Loader from '../../Loader'
import { toast } from 'react-toastify'

// const dateOptions = new Array(new Date().getFullYear() - 2003)
//   .fill(null)
//   .map((_, i) => ({ label: 2004 + i, value: 2004 + i }))

export default function GoogleTrends({ config = {}, setConfig }) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const options = useMemo(() => {
    const options = [
      { value: 'interest_over_time' },
      // { value: 'interest_by_region' },
      // { value: 'related_queries' },
      // { value: 'related_topics' },
      // { value: 'related_suggestions' },
      // { value: 'daily_search_trends' },
      // { value: 'top_charts' },
    ]

    return options.map((o) => ({ label: t(o.value), value: o.value }))
  }, [t])

  const searchRef = useRef()

  const [localConfig, setLocalConfig] = useState(() => {
    const c = { ...config }
    if (!c?.queryType) c.queryType = options[0]
    return c
  })

  const queryType = localConfig?.queryType?.value

  const { data: metrics, isLoading } = useQuery(
    ['trends-metrics', queryType],
    async () => {
      if (!queryType) return null
      const response = await getMetrics({
        datasource: 'google_trends',
        callType: queryType,
        token,
      })
      if (response.ok) {
        const metrics = await response.json()
        const items = metrics?.report_options ?? []
        return items.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      }
      throw new Error('Error fetching metrics')
    },
    { staleTime: Infinity },
  )

  const showSearch = metrics?.['search_term']
  const showCountry = metrics?.['country']
  const showType = metrics?.['gprop']
  const showCategory = metrics?.['category']
  const showYear = metrics?.['year']

  const countryOptions = useMemo(() => {
    if (Array.isArray(metrics?.country?.values)) {
      const result = metrics?.country?.values.map((v) => ({
        label: (
          <span
            className={`${v.type === 'region' ? 'ms-4' : v.type === 'country' ? 'ms-3' : ''}`}
          >
            {v.name}
          </span>
        ),
        value: v.name,
        original_id: v.id,
        original_name: v.name,
      }))
      if (!localConfig?.country && result?.length)
        setLocalConfig((l) => ({
          ...l,
          country: {
            label: result[0].original_name,
            value: result[0].original_id,
          },
        }))

      return result
    }
    return []
  }, [metrics])

  const categoryOptions = useMemo(() => {
    if (!Array.isArray(metrics?.category?.values)) return []
    const hierarchy = (item, level = 0) => {
      const base = [
        {
          label: (
            <span style={{ marginLeft: `${level * 15}px` }}>{item.name}</span>
          ),
          value: item.name,
          original_id: item.id,
          original_name: item.name,
        },
      ]
      if (Array.isArray(item.children))
        base.push(...item.children.map((c) => hierarchy(c, level + 1)).flat())
      return base
    }
    const result = metrics?.category?.values.map((c) => hierarchy(c)).flat()
    if (!localConfig?.category && result?.length)
      setLocalConfig((l) => ({
        ...l,
        category: {
          label: result[0].original_name,
          value: result[0].original_id,
        },
      }))
    return result
  }, [metrics])

  useEffect(() => {
    if (metrics?.gprop && !localConfig?.gprop) {
      setLocalConfig((l) => ({
        ...l,
        gprop: {
          label: metrics.gprop.values[0].name,
          value: metrics.gprop.values[0].id,
        },
      }))
    }
  }, [metrics, localConfig])

  if (isLoading)
    return (
      <Row>
        <Col xs={12} className="mb-1 ps-2">
          <Loader />
        </Col>
      </Row>
    )

  return (
    <Row>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Query type')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => setLocalConfig({ ...localConfig, queryType: e })}
          options={options}
          value={localConfig?.queryType}
        />
      </Col>
      {showSearch && (
        <>
          <Col xs={12} className="mb-1 ps-2">
            <span className="ps-2">{t('Search term')}</span>
          </Col>
          <Col xs={12} className="mb-3">
            <Form.Control
              className="text-input-import dark:text-white text-white"
              type="textarea"
              as="textarea"
              rows={3}
              placeholder={metrics['search_term']?.placeholder}
              defaultValue={localConfig?.search_term}
              onChange={(e) => (localConfig.search_term = e.target.value)}
              ref={searchRef}
            />
          </Col>
        </>
      )}
      {showCountry && (
        <>
          <Col xs={12} className="mb-1 ps-2">
            <span className="ps-2">{t('Country or region')}</span>
          </Col>
          <Col xs={12} className="mb-3">
            <CustomSelect
              className="basic-single mt-2 w-full"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={false}
              onChange={(e) =>
                setLocalConfig({
                  ...localConfig,
                  country: { label: e.original_name, value: e.original_id },
                })
              }
              options={countryOptions}
              value={localConfig?.country}
            />
          </Col>
        </>
      )}
      {showType && (
        <>
          <Col xs={12} className="mb-1 ps-2">
            <span className="ps-2">{t('Type')}</span>
          </Col>
          <Col xs={12} className="mb-3">
            <CustomSelect
              className="basic-single mt-2 w-full"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={false}
              onChange={(e) => setLocalConfig({ ...localConfig, gprop: e })}
              options={metrics?.gprop?.values.map((v) => ({
                label: v.name,
                value: v.id,
              }))}
              value={localConfig?.gprop}
            />
          </Col>
        </>
      )}
      {showCategory && (
        <>
          <Col xs={12} className="mb-1">
            <span className="ps-2">{t('Category')}</span>
          </Col>
          <Col xs={12} className="mb-3">
            <CustomSelect
              className="basic-single mt-2 w-full"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={false}
              onChange={(e) =>
                setLocalConfig({
                  ...localConfig,
                  category: { label: e.original_name, value: e.original_id },
                })
              }
              options={categoryOptions}
              value={localConfig?.category}
            />
          </Col>
        </>
      )}
      {showYear && (
        <>
          <Col xs={12} className="mb-1">
            <span className="ps-2">{t('Year')}</span>
          </Col>
          <Col xs={12} className="mb-3">
            <CustomSelect
              className="basic-single mt-2 w-full"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={false}
              onChange={(e) => setLocalConfig({ ...localConfig, year: e })}
              options={metrics?.year?.values.map((v) => ({
                label: v.name,
                value: v.id,
              }))}
              value={localConfig?.year}
            />
          </Col>
        </>
      )}
      <Col className="flex justify-end" xs={12}>
        <ButtonMMM
          onClick={() => {
            if (!localConfig?.search_term)
              toast.error('Search term is required')
            else setConfig(localConfig)
          }}
        >
          {t('Save')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}
