import React from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import dlIcon from '../assets/logos/dataslayer-icon.svg'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Container id="footer" className="flex justify-between flex-row" fluid>
      <div>
        {window.CookieConsent && (
          <button
            className="bg-transparent border-none text-[14px] text-[#3b7cf5] hover:underline"
            type="button"
            data-cc="show-preferencesModal"
            aria-haspopup="dialog"
          >
            {t('Manage cookie preferences')}
          </button>
        )}
      </div>
      <div className="footer-logo">
        <div className="footer-logo-line"></div>
        <Image src={dlIcon} className="object-contain" />
        <div className="footer-logo-line"></div>
      </div>
      <div></div>
    </Container>
  )
}

export default Footer
