import React from 'react'
import { OverlayTrigger, Nav, Tooltip, Popover } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'

const ContentNavbar = () => {
  const { user } = useAuth()
  const { i18n, t } = useTranslation()
  const location = useLocation()
  let optionalDisabled = {}
  if (user?.plan === 'Free')
    optionalDisabled = {
      disabled: true,
      className: 'opacity-50 color-black',
    }

  const indexNav = (
    <Nav.Item>
      <Nav.Link
        as={Link}
        to="/"
        active={location.pathname === '/'}
        {...optionalDisabled}
      >
        {t('Index')}
      </Nav.Link>
    </Nav.Item>
  )

  return (
    <Nav
      id="content-navbar"
      variant="pills"
      defaultActiveKey="/"
      className="border-color inline-flex w-fit items-center border rounded-full"
    >
      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/user-guide"
          active={location.pathname === '/user-guide'}
        >
          {t('User Guide')}
        </Nav.Link>
      </Nav.Item>
      {user?.plan === 'Free' ? (
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
            <Tooltip {...props}>
              {t('Request access to start using Morpheus')}
            </Tooltip>
          )}
        >
          {indexNav}
        </OverlayTrigger>
      ) : (
        indexNav
      )}
      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/license"
          active={location.pathname === '/license'}
          eventKey="link-1"
        >
          {t('License')}
        </Nav.Link>
      </Nav.Item>
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="bottom"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => <Tooltip {...props}>{t('Coming soon')}</Tooltip>}
      >
        <Nav.Item>
          <Nav.Link
            disabled
            as={Link}
            to="/issues"
            active={location.pathname === '/issues'}
            eventKey="link-3"
            className="opacity-50 color-black"
          >
            {t('Issues')}
          </Nav.Link>
        </Nav.Item>
      </OverlayTrigger>
    </Nav>
  )
}

export default ContentNavbar
