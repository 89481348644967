import React, { useMemo, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../../CustomSelect'
import ButtonMMM from '../../ButtonMMM'

const PAGE_POST_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 250, label: '250' },
]

export default function InstagramPublicData({ config = {}, setConfig }) {
  const { t } = useTranslation()
  const [localConfig, setLocalConfig] = useState(config || { kwargs: {} })

  const options = useMemo(() => {
    const options = [
      //{ value: 'Profile info' },
      { label: 'Profile posts', value: 'profile_posts' },
      //{ value: 'Post search' },
    ]
    return options.map((o) => ({ label: t(o.value), value: o.value }))
  }, [t])

  const postSearchOptions = useMemo(() => {
    const options = [{ value: 'Rec' }, { value: 'Profile posts' }]
    return options.map((o) => ({ label: t(o.value), value: o.value }))
  }, [t])

  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Query type')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.call_type = e.value
            setLocalConfig({ ...localConfig })
          }}
          options={options}
          value={options.find(
            (o) => o.value === localConfig?.kwargs?.call_type,
          )}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Instagram profiles')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={3}
          defaultValue={localConfig?.kwargs?.usernames ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.usernames = e.target.value
          }}
          placeholder={t(
            'To search for multiple terms, you must split by commas ( , )',
          )}
        />
      </Col>
      <Col
        xs={12}
        className={`${localConfig.query_type === 'Post search' ? '' : 'hidden'} mb-1 `}
      >
        <span className="ps-2">{t('Post search type')}</span>
      </Col>
      <Col
        xs={12}
        className={`${localConfig.query_type === 'Post search' ? '' : 'hidden'} mb-3`}
      >
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {}}
          options={postSearchOptions}
          value={null}
        />
      </Col>
      <Col
        xs={12}
        className={`${localConfig.query_type === 'Post search' ? '' : 'hidden'} mb-1 `}
      >
        <span className="ps-2">{t('# of posts per hashtag')}</span>
      </Col>
      <Col
        xs={12}
        className={`${localConfig.query_type === 'Post search' ? '' : 'hidden'} mb-3`}
      >
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {}}
          options={PAGE_POST_OPTIONS}
          value={null}
        />
      </Col>
      <Col
        className="flex justify-end"
        xs={12}
        onClick={() => {
          if (
            localConfig?.kwargs?.call_type &&
            localConfig?.kwargs?.usernames
          ) {
            setConfig({
              kwargs: localConfig.kwargs,
              static: true,
            })
          } else
            toast.error(
              t(
                'Please fill all the fields in the Instagram public data configuration form',
              ),
            )
        }}
      >
        <ButtonMMM>{t('Save')}</ButtonMMM>
      </Col>
    </Row>
  )
}
