import { useRef } from 'react'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { FcTodoList, FcDeleteColumn } from 'react-icons/fc'
import { MdInvertColors } from 'react-icons/md'
import './GraphLayers.css'

export function useInteractiveLineLegend({
  marginBottom = null,
  controls = true,
  onGraphWidth = 0,
  paddingLeft = 0,
}) {
  const ref = useRef(
    ({ height, innerWidth, margin, data, filters, setFilters }) => {
      return (
        <foreignObject
          x={
            onGraphWidth
              ? innerWidth - onGraphWidth + paddingLeft
              : innerWidth + paddingLeft
          }
          y={0}
          width={(onGraphWidth || margin.right) - paddingLeft}
          height={height - (marginBottom ?? margin.bottom)}
        >
          <Row
            className="ms-2 container-line-legend-interactive"
            style={{
              minWidth: `${
                (onGraphWidth || margin.right) - paddingLeft - 10
              }px`,
              maxWidth: `${
                (onGraphWidth || margin.right) - paddingLeft - 10
              }px`,
              maxHeight: `${height - (marginBottom ?? margin.bottom)}px`,
              overflowY: 'scroll',
            }}
          >
            {controls && (
              <Col className="controls-legend py-2 no-print" xs={12}>
                <Row>
                  <Col
                    className="px-0 d-flex align-items-center justify-content-center"
                    xs={12}
                  >
                    <ButtonGroup aria-label="Legend controls">
                      <Button
                        className="py-1 original"
                        variant={'secondary'}
                        onClick={() => {
                          setFilters({})
                        }}
                      >
                        <strong>
                          <span className="small d-inline-flex align-items-center">
                            All <FcTodoList />
                          </span>
                        </strong>
                      </Button>
                      <Button
                        className="py-1 original"
                        variant={'secondary'}
                        onClick={() => {
                          setFilters(
                            data.reduce((a, d) => {
                              a[d.id] = true
                              return a
                            }, {}),
                          )
                        }}
                      >
                        <strong>
                          <span className="small d-inline-flex align-items-center">
                            Hide <FcDeleteColumn />
                          </span>
                        </strong>
                      </Button>
                      <Button
                        className="py-1 original"
                        variant={'secondary'}
                        onClick={() => {
                          setFilters(
                            data.reduce(
                              (a, d) => {
                                a[d.id] = !a[d.id]
                                return a
                              },
                              { ...filters },
                            ),
                          )
                        }}
                      >
                        <strong>
                          <span className="small d-inline-flex align-items-center">
                            Invert
                            <MdInvertColors />
                          </span>
                        </strong>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Col>
            )}
            {data.map((s, i) => (
              <Col className={i ? '' : 'mt-2'} key={s.id} xs={12}>
                <Row
                  className={`interactive-legend-block ${
                    filters[s.id] ? 'interactive-legend-block-disabled' : ''
                  }`}
                  onClick={() =>
                    setFilters((f) => ({ ...f, [s.id]: !f[s.id] }))
                  }
                >
                  <Col
                    className="px-0 d-flex align-items-center"
                    style={{
                      position: 'relative',
                      maxWidth: '100%',
                      minWidth: '100%',
                    }}
                    xs={10}
                  >
                    <span
                      title={s.id}
                      style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '11px',
                      }}
                      className="w-100 detail-legend-interactive d-inline-block text-truncate"
                    >
                      <div
                        style={{
                          //Embeed styles, html to canvas fails to parse the styles if they come from classes :(
                          backgroundColor: `${s.color}`,
                          minWidth: '10px',
                          minHeight: '10px',
                          maxWidth: '10px',
                          maxHeight: '10px',
                          borderRadius: '50%',
                          display: 'inline-block',
                          marginRight: '2px',
                        }}
                        className="detail-legend-interative-shape"
                      ></div>
                      {s.id}
                    </span>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </foreignObject>
      )
    },
  )
  return ref.current
}
