import { useState, useEffect } from 'react'

export default function useStoreState({ key, ...initialValue }) {
  const retrieveFromLocalStore = () => {
    if (typeof window === 'undefined') {
      console.log("Can't access the local storage")
    } else
      try {
        const value = window.localStorage.getItem(key)
        return value ? JSON.parse(value) : initialValue
      } catch (error) {
        console.log(`Failed to retrieve value for ${key}`, error)
      }
    return initialValue
  }

  const [storedVal, setStoredVal] = useState(retrieveFromLocalStore)

  useEffect(() => {
    try {
      if (typeof window === 'undefined') {
        console.log("Can't access the local storage")
      } else {
        window.localStorage.setItem(key, JSON.stringify(storedVal))
      }
    } catch (error) {
      console.log(`Failed to store the key ${key}`, error)
    }
    // eslint-disable-next-line
  }, [storedVal])

  function forceRewite(value) {
    window.localStorage.setItem(key, JSON.stringify(value))
    setStoredVal(value)
  }

  useEffect(
    () => {
      setStoredVal(retrieveFromLocalStore())
    },
    // eslint-disable-next-line
    [key],
  )

  return [storedVal, setStoredVal, forceRewite]
}
