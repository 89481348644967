import React, { useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonMMM from './ButtonMMM'
import CustomSelect from './CustomSelect'
const STATUS_OPTIONS = [
  { label: 'created', value: 'created' },
  { label: 'trained', value: 'trained' },
  { label: 'importing', value: 'importing' },
  { label: 'training', value: 'training' },
]

const ModelFilter = ({ onApply, onClean }) => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')

  const handleApply = () => {
    onApply({ name, status })
  }

  const handleClean = () => {
    setName('')
    setStatus(null)
    onClean()
  }

  const translatedStatusOptions = STATUS_OPTIONS.map((option) => ({
    ...option,
    label: t(option.label),
  }))

  return (
    <div className="filters-container p-0">
      <Row className="form-filter">
        <Col>
          <Row className="mbottom-14">
            <Col className="font-thin mb-2" xs={12}>
              {t('Name')}
            </Col>
            <Col xs={12}>
              <Form.Control
                className="text-input-mmm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mbottom-14">
            <Col className="font-thin mb-2" xs={12}>
              {t('Status')}
            </Col>
            <Col xs={12}>
              <CustomSelect
                className="basic-single "
                classNamePrefix="select"
                isSearchable={true}
                placeholder={t('Select...')}
                isClearable={true}
                options={translatedStatusOptions}
                value={status}
                onChange={(selectedOption) => setStatus(selectedOption)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="items-end form-filter">
        <Col xs="auto">
          <ButtonMMM className="button-mmm-info" onClick={handleClean}>
            <span className="uppercase inline-flex items-center text-sm align-middle font-light">
              {t('Clean')}
            </span>
          </ButtonMMM>
        </Col>
        <Col>
          <button
            type="button"
            onClick={handleApply}
            className="uppercase w-full button-mmm rounded-3xl py-2 text-sm bg-main font-light disabled:bg-slate-950 disabled:pointer-events-none mt-2"
          >
            {t('Apply')}
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default ModelFilter
