import { components, default as ReactSelect } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'

const trainStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    minHeight: '30px',
    fontSize: '14px',
  }),
  container: (provided, a) => {
    const styles = {
      ...provided,
      border: `1px solid var(--mmm-button-outline)`,
      borderRadius: 25,
    }
    if (a?.isDisabled)
      styles['backgroundColor'] = 'var(--mmm-custm-select-disabled-background)'
    return styles
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      // backgroundColor: 'var(--mmm-background2)',
      color: 'white',
      padding: '4px 5px',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--mmm-background2)'
        : 'var(--mmm-background)',
      color: 'white',
      //border: '1px solid var(--mmm-secondary-border-color)',
      cursor: 'pointer',
      padding: '6px 5px',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-background2)',
    border: '1px solid var(--mmm-secondary-border-color)',
    color: 'var(--mmm-white-color)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--mmm-white-color)' },
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    padding: '0px 5px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-white-color)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: '30px',
  }),
  multiValue: (provided, s) => {
    const values = s.getValue()
    let color = 'var(--mmm-background-select-multivalue)'
    if (
      s?.selectProps?.className?.includes('--mmm-background-select-multivalue')
    ) {
      color = 'var(--mmm-dataslayer-blue)'
    }
    if (values.length > s.selectProps.formatOptionLabel.maxValues) {
      if (s.data.label !== values[0].label)
        return {
          display: 'none',
        }
      else
        return {
          pointerEvents: 'none',
          marginLeft: '-5000px',
        }
    }
    return {
      ...provided,
      backgroundColor: color,
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
}

const highImpact = {
  ...trainStyles,
  container: (provided, a) => {
    const styles = {
      ...provided,
      border: `1px solid #626262`,
      borderRadius: 10,
    }
    if (a?.isDisabled)
      styles['backgroundColor'] = 'var(--mmm-custm-select-disabled-background)'
    return styles
  },
}

const highImpactRounded = {
  ...trainStyles,
  container: (provided, a) => {
    const styles = {
      ...provided,
      border: `1px solid #626262`,
      borderRadius: 25,
    }
    if (a?.isDisabled)
      styles['backgroundColor'] = 'var(--mmm-custm-select-disabled-background)'
    return styles
  },
  valueContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: '40px',
  }),
}

const thinStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    // minHeight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: '30px',
    fontSize: '14px',
  }),
  container: (provided, a) => {
    return {
      ...provided,
      border: `1px solid var(--mmm-main-color)`,
      borderRadius: 15,
    }
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      backgroundColor: 'var(--mmm-background2)',
      padding: 0,
      color: 'var(--mmm-dataslayer-magenta)',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--mmm-background:)'
        : 'var(--mmm-background2)',
      color: categoryToColor[state.data.type] ?? 'var(--mmm-white-color)',
      //border: '1px solid var(--mmm-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-background2)',
    border: '1px solid var(--mmm-secondary-border-color)',
    color: 'var(--mmm-white-color)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--mmm-white-color)' },
    backgroundColor: 'var(--mmm-background)',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-white-color)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 4px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided, s) => {
    const values = s.getValue()
    let color = 'var(--mmm-dataslayer-magenta)'
    if (s?.selectProps?.className?.includes('--mmm-dataslayer-magenta')) {
      color = 'var(--mmm-dataslayer-blue)'
    }
    if (values.length > s.selectProps.formatOptionLabel.maxValues) {
      if (s.data.label !== values[0].label)
        return {
          display: 'none',
        }
      else
        return {
          pointerEvents: 'none',
          marginLeft: '-5000px',
        }
    }
    return {
      ...provided,
      backgroundColor: color,
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
    margin: '0',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
}

const emptyStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    minHeight: '30px',
    fontSize: '14px',
  }),
  container: (provided, a) => {
    return {
      ...provided,
      border: `1px solid #00000000`,
      borderRadius: 15,
    }
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      backgroundColor: '#00000000',
      color: 'var(--mmm-white-color)',
      fontWeight: 'bold',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--mmm-background:)'
        : 'var(--mmm-background)',
      color: categoryToColor[state.data.type] ?? 'var(--mmm-white-color)',
      //border: '1px solid var(--mmm-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    color: 'var(--mmm-white-color)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--mmm-white-color)' },
    backgroundColor: '#00000000',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
}

const suggestionStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    minHeight: '30px',
    fontSize: '14px',
  }),
  container: (provided, a) => {
    return {
      ...provided,
      border: `1px solid var(--mmm-secondary-border-color)`,
      borderBottomLeftRadius: '0.25em',
      borderBottomRightRadius: '0.25em',
    }
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      backgroundColor: '#00000000',
      color: 'var(--mmm-white-color)',
      fontWeight: 'bold',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--mmm-background:)'
        : 'var(--mmm-background)',
      color: categoryToColor[state.data.type] ?? 'var(--mmm-white-color)',
      //border: '1px solid var(--mmm-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    color: 'var(--mmm-white-color)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--mmm-white-color)' },
    backgroundColor: '#00000000',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
}

const dateTypesStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
    minHeight: '30px',
    fontSize: '14px',
    padding: '6px 5px',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid var(--mmm-secondary-border-color)',
  }),
  singleValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-dataslayer-magenta)',
    color: 'var(--mmm-white-color)',
    padding: '6px 5px',
    minHeight: '30px',
    fontSize: '14px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? 'var(--mmm-dataslayer-magenta)'
      : 'var(--mmm-dataslayer-magenta)',
    color: 'var(--mmm-white-color)',
    border: '1px solid var(--mmm-secondary-border-color)',
    cursor: 'pointer',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-dataslayer-magenta)',
    color: 'var(--mmm-white-color)',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--mmm-white-color)' },
    backgroundColor: 'var(--mmm-dataslayer-magenta)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--mmm-white-color)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '6px 5px',
    cursor: 'pointer',
    minHeight: '30px',
    fontSize: '14px',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--mmm-white-color)',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 5px',
  }),
}

export default function CustomSelect({
  type = 'train',
  creatable = false,
  async = false,
  maxSelected = 5,
  ...props
}) {
  if (creatable && async) {
    console.warn('Using both creatable and async react select is not supported')
  }
  const Component = async
    ? AsyncSelect
    : creatable
      ? CreatableSelect
      : ReactSelect
  switch (type) {
    case 'train':
    case 'dark':
    case 'highImpact':
    case 'highImpactRounded': {
      const formatLabel = (a, b) => {
        if (
          Array.isArray(b?.selectValue) &&
          b.selectValue.length > maxSelected
        ) {
          return (
            <div
              className="status-select-indicator"
              data-selected-num={
                b?.inputValue
                  ? `🔍 ${b?.inputValue}`
                  : `${b.selectValue.length}${
                      props?.options?.length
                        ? ` / ${props?.options?.length}`
                        : ''
                    } selected`
              }
            ></div>
          )
        }
        return a.label
      }
      formatLabel.maxValues = maxSelected
      return (
        <Component
          styles={
            type === 'highImpact'
              ? highImpact
              : type === 'highImpactRounded'
                ? highImpactRounded
                : trainStyles
          }
          {...props}
          className={`${props?.className ?? ''} react-select-mmm`}
          formatOptionLabel={formatLabel}
        />
      )
    }
    case 'thin': {
      const formatLabel = (a, b) => {
        if (
          Array.isArray(b?.selectValue) &&
          b.selectValue.length > maxSelected
        ) {
          return (
            <div
              className="status-select-indicator"
              data-selected-num={
                b?.inputValue
                  ? `🔍 ${b?.inputValue}`
                  : `${b.selectValue.length}${
                      props?.options?.length
                        ? ` / ${props?.options?.length}`
                        : ''
                    } selected`
              }
            ></div>
          )
        }
        return a.label
      }
      formatLabel.maxValues = maxSelected
      return (
        <Component
          styles={thinStyles}
          {...props}
          className={`${
            props?.className ?? ''
          } base-input react-select-nb-${mode}`}
          formatOptionLabel={formatLabel}
        />
      )
    }
    case 'empty':
      return <Component styles={emptyStyles} {...props} />
    // return <Component styles={darkStyles} {...props} />
    case 'dateTypes':
      return <Component styles={dateTypesStyles} {...props} />
    case 'suggestions':
      return <Component styles={suggestionStyles} {...props} />
    default:
      throw new Error(`Unknown select type ${type}`)
  }
}

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label
          style={{
            color: 'inherit',
          }}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  )
}
