import React, { useState, useMemo, useEffect, useRef, Fragment } from 'react'
import { Row, Col, Image, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { FcOk } from 'react-icons/fc'
import {
  FaCalendar,
  FaCheck,
  FaChevronRight,
  FaGlobe,
  FaMagnifyingGlass,
  FaPlus,
  FaRegCircle,
  FaSpinner,
} from 'react-icons/fa6'
import { CiLocationOn, CiWarning } from 'react-icons/ci'
import { ImSpinner9 } from 'react-icons/im'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import mediaChannels from '../../assets/images/media-channel-purple.svg'
import contextVariablesp from '../../assets/images/context-variables-purple.svg'
import kpi from '../../assets/images/kpi-purple.svg'
import mediaChannelsDark from '../../assets/images/media-channel-dark.svg'
import contextVariablesDark from '../../assets/images/context-variables-dark.svg'
import kpiDark from '../../assets/images/kpi-dark.svg'
import arrowWhite from '../../assets/images/arrow-white.gif'
import CustomSelect from '../CustomSelect'
import { useDebouncedCallback } from 'use-debounce'
import {
  getCustomMetricsAnalytics_v4,
  getCustomMetricsFacebook,
  getMetrics,
} from '../../services/dataslayer'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery } from 'react-query'
import { baseFormat } from '../../utility/model'
import {
  AVAILABLE_ENRICHMENTS,
  datasources_without_cost,
  special_connection_datasources_without_local,
  translate_account_name,
} from './config'
import { FaFileAlt } from 'react-icons/fa'
import {
  campaign_dimensions_datasources,
  adgroup_dimensions_datasources,
  ad_dimensions_datasources,
  custom_static_config_datasource,
  datasources_cost_metric,
} from './config'
import { checkLocation } from '../../services/model'
import CountrySelector from './CountrySelector'
import { MdCurrencyExchange, MdSubdirectoryArrowRight } from 'react-icons/md'

function FileDatasourceConfig({
  connectors,
  file,
  onActivate,
  active,
  selected,
}) {
  const { t } = useTranslation()

  const disabled = file.status !== 'processed'

  const datasource = file?.config?.table_config?.datasource

  return (
    <Row
      className={`py-2  select-none  duration-100 ${active ? 'bg-importbgcontrast' : 'hover:bg-importbgcontrast50'} ${disabled ? 'opacity-50 pointer-events-none' : ''}`}
      onClick={onActivate}
    >
      <Col xs={12}>
        <Row className="cursor-pointer py-2">
          <Col
            className="pe-0 flex items-center"
            xs={12}
            style={{ maxWidth: '40px' }}
          >
            {disabled && <ImSpinner9 size={19} className="animate-spin" />}
            {!disabled && (
              <>
                {selected ? (
                  <FcOk className="inline-block me-1" size={21} />
                ) : (
                  <FaRegCircle className="inline-block me-1 mb-1" size={19} />
                )}
              </>
            )}
          </Col>
          <Col
            className="flex items-center pe-0"
            xs={12}
            style={{ maxWidth: 'calc(100% - 70px)' }}
          >
            <Row className="flex-nowrap w-full">
              {datasource ? (
                <Col className="pe-0" style={{ maxWidth: '45px' }} xs={12}>
                  <Image
                    src={`/dataslayer_datasources/${datasource}.png`}
                    width={25}
                  />
                </Col>
              ) : (
                <Col className="pe-0" style={{ maxWidth: '45px' }} xs={12}>
                  <FaFileAlt size={25} />
                </Col>
              )}
              <Col
                className="text-lg font-bold ps-0 inline-block text-truncate overflow-hidden whitespace-nowrap"
                xs={12}
                style={{ maxWidth: 'calc(100% - 45px)' }}
              >
                {datasource
                  ? file?.config?.table_config?.name ?? datasource
                  : file.name}
              </Col>
            </Row>
          </Col>
          <Col
            className="flex items-center px-0"
            xs={12}
            style={{ maxWidth: '20px' }}
          >
            <FaChevronRight size={20} className={`duration-300`} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function DatasourceConfig({
  datasource,
  name,
  displayName,
  selected,
  requireCost,
  active,
  onActivate,
  onCheck,
}) {
  const { t } = useTranslation()

  const item = (
    <Row
      className={`py-2  select-none  duration-100 ${active ? 'bg-importbgcontrast' : 'hover:bg-importbgcontrast50'}`}
      onClick={onActivate}
    >
      <Col xs={12}>
        <Row className="cursor-pointer py-2">
          <Col
            className="pe-0 flex items-center"
            xs={12}
            style={{ maxWidth: '40px' }}
          >
            {selected ? (
              <FcOk
                className="inline-block me-1"
                size={21}
                onClick={(e) => onCheck(false, e)}
              />
            ) : (
              <FaRegCircle
                className="inline-block me-1 mb-1"
                size={19}
                onClick={(e) => onCheck(true, e)}
              />
            )}
          </Col>
          <Col
            className="flex items-center pe-0"
            xs={12}
            style={{ maxWidth: 'calc(100% - 70px)' }}
          >
            <Row className="flex-nowrap w-full">
              <Col className="pe-0" style={{ maxWidth: '45px' }} xs={12}>
                {typeof datasource === 'string' ? (
                  <Image
                    src={`/dataslayer_datasources/${datasource}.png`}
                    width={25}
                  />
                ) : (
                  datasource
                )}
              </Col>
              <Col
                className="text-lg font-bold ps-0 inline-block text-truncate overflow-hidden"
                xs={12}
                style={{ maxWidth: 'calc(100% - 45px)' }}
              >
                {displayName || name}
              </Col>
            </Row>
          </Col>
          <Col
            className="flex items-center px-0"
            xs={12}
            style={{ maxWidth: '20px' }}
          >
            <FaChevronRight size={20} className={`duration-300`} />
          </Col>
        </Row>
      </Col>
    </Row>
  )

  const disabled = requireCost && datasources_without_cost.includes(datasource)

  return disabled ? (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="top"
      delay={{ show: 100, hide: 100 }}
      overlay={({ ...props }) => (
        <Popover {...props} style={{ ...props.style, minWidth: '200px' }}>
          <div
            style={{
              width: '200px',
              backgroundColor: 'var(--mmm-background)',
              borderRadius: 5,
            }}
          >
            <div className="text-center p-2">
              {t(
                'The datasource does not include a metric for investment cost.',
              )}
            </div>
          </div>
        </Popover>
      )}
    >
      <div>
        <div className="opacity-70 cursor-default pointer-events-none">
          {item}
        </div>
      </div>
    </OverlayTrigger>
  ) : (
    item
  )
}

function DatasourcesAccountsConfig({
  connectors,
  step,
  datasourcesWithUsers,
  users,
  nameRegister,
  mediaChannelDatasources,
  setMediaChannelDatasources,
  contextVariables,
  kpis,
  activeMediaChannel,
  setActiveMediaChannel,
  disabledContextVariables,
  setDisabledContextVariables,
  customConfigurations,
  localFiles,
  fileStatus,
  setFileStatus,
  activeCustomConfiguration,
  setActiveCustomConfiguration,
  enrichmentDatasources,
  activeEnrichment,
  setActiveEnrichment,
}) {
  const { t } = useTranslation()
  const stepMap = {
    0: 'Media Channel',
    1: 'Context Variables',
    2: 'KPI',
  }
  let selected = {}
  const activeFile = fileStatus?.active && !activeCustomConfiguration
  switch (step) {
    case 0:
      mediaChannelDatasources.forEach((d) => (selected[d] = true))
      break
    case 1:
      Object.entries(contextVariables).forEach(([k, v]) => {
        if (Object.values(v).some((vv) => vv))
          selected[k] = !disabledContextVariables?.[k]
      })
      break
    case 2:
      Object.entries(kpis).forEach(([k, v]) => {
        if (Object.values(v).some((vv) => vv)) selected[k] = true
      })
      break
  }
  return (
    <Row>
      <Col className="font-bold text-2xl mb-3" xs={12}>
        {t('Select')} {t(stepMap[step])}
      </Col>
      <Col xs={12}>
        <Row>
          {!!localFiles?.length &&
            localFiles.map((f, i) => (
              <Col key={f.name} xs={12}>
                <FileDatasourceConfig
                  file={f}
                  onActivate={() => {
                    setActiveEnrichment(null)
                    setActiveMediaChannel(null)
                    setActiveCustomConfiguration(null)
                    setFileStatus({
                      ...fileStatus,
                      active:
                        fileStatus?.active === f.filename ? null : f.filename,
                    })
                  }}
                  active={
                    !activeEnrichment &&
                    !activeCustomConfiguration &&
                    f.filename === fileStatus?.active
                  }
                  connectors={connectors}
                  selected={Object.values(
                    fileStatus?.['selected']?.[step]?.[f.filename] ?? {},
                  ).some((v) => v)}
                />
              </Col>
            ))}
          {datasourcesWithUsers.map((d) => (
            <Col xs={12} key={d}>
              <DatasourceConfig
                datasource={d}
                name={connectors?.find((c) => c.api_entry === d)?.name}
                requireCost={step === 0}
                selected={selected[d]}
                active={
                  !activeEnrichment &&
                  !activeCustomConfiguration &&
                  !activeFile &&
                  (step === 0
                    ? d === activeMediaChannel
                    : mediaChannelDatasources.includes(d))
                }
                onActivate={() => {
                  setActiveEnrichment(null)
                  setFileStatus({ ...fileStatus, active: null })
                  setActiveCustomConfiguration(null)
                  if (step === 0) {
                    setActiveMediaChannel(
                      !activeCustomConfiguration &&
                        fileStatus?.active &&
                        d === activeMediaChannel
                        ? null
                        : d,
                    )
                    return
                  }
                  if (mediaChannelDatasources.includes(d)) {
                    const update = mediaChannelDatasources.filter(
                      (v) => v !== d,
                    )
                    if (
                      mediaChannelDatasources[
                        mediaChannelDatasources.length - 1
                      ] === d
                    ) {
                      if (fileStatus?.active && !activeCustomConfiguration)
                        setMediaChannelDatasources(update)
                    } else setMediaChannelDatasources([...update, d])
                  } else
                    setMediaChannelDatasources(
                      step !== 0 ? [d] : [...mediaChannelDatasources, d],
                    )
                }}
                onCheck={(status, event) => {}}
              />
            </Col>
          ))}
          {Object.keys(customConfigurations)
            .filter((datasource) =>
              special_connection_datasources_without_local.includes(datasource),
            )
            .map((d) => (
              <Col xs={12} key={d}>
                <DatasourceConfig
                  datasource={d}
                  name={connectors?.find((c) => c.api_entry === d)?.name}
                  requireCost={step === 0}
                  selected={selected[d]}
                  active={
                    !activeEnrichment &&
                    !activeCustomConfiguration &&
                    !activeFile &&
                    (step === 0
                      ? d === activeMediaChannel
                      : mediaChannelDatasources.includes(d))
                  }
                  onActivate={() => {
                    setActiveEnrichment(null)
                    setFileStatus({ ...fileStatus, active: null })
                    setActiveCustomConfiguration(null)
                    if (step === 0) {
                      setActiveMediaChannel(
                        !activeCustomConfiguration &&
                          fileStatus?.active &&
                          d === activeMediaChannel
                          ? null
                          : d,
                      )
                      return
                    }
                    if (mediaChannelDatasources.includes(d)) {
                      const update = mediaChannelDatasources.filter(
                        (v) => v !== d,
                      )
                      if (
                        mediaChannelDatasources[
                          mediaChannelDatasources.length - 1
                        ] === d
                      ) {
                        if (fileStatus?.active && !activeCustomConfiguration)
                          setMediaChannelDatasources(update)
                      } else setMediaChannelDatasources([...update, d])
                    } else
                      setMediaChannelDatasources(
                        step !== 0 ? [d] : [...mediaChannelDatasources, d],
                      )
                  }}
                  onCheck={(status, event) => {}}
                />
              </Col>
            ))}
          {custom_static_config_datasource.map((d) => (
            <Fragment key={d}>
              {customConfigurations[d] &&
                Object.entries(customConfigurations[d]).map(([k, v]) => (
                  <Col key={k} xs={12}>
                    <DatasourceConfig
                      datasource={d}
                      displayName={k}
                      name={connectors?.find((c) => c.api_entry === d)?.name}
                      requireCost={step === 0}
                      selected={
                        (step === 1 &&
                          Object.values(v?.contextVariables ?? {}).some(
                            (v) => v,
                          )) ||
                        (step === 2 &&
                          Object.values(v?.kpis ?? {}).some((v) => v))
                      }
                      active={
                        !activeEnrichment &&
                        activeCustomConfiguration?.[0] === d &&
                        activeCustomConfiguration?.[1] === k
                      }
                      onActivate={() => {
                        setActiveEnrichment(null)
                        setActiveMediaChannel(null)
                        setFileStatus({ ...fileStatus, active: null })
                        setActiveCustomConfiguration([d, k])
                      }}
                      onCheck={() => {}}
                    />
                  </Col>
                ))}
            </Fragment>
          ))}
          {step === 1 &&
            Object.keys(enrichmentDatasources).map((k) => (
              <Col key={k} xs={12}>
                <DatasourceConfig
                  datasource={
                    AVAILABLE_ENRICHMENTS.find((e) => e.name === k)?.icon
                  }
                  displayName={t(k)}
                  name={k}
                  selected={Object.values(
                    enrichmentDatasources?.[k]?.contextVariables ?? {},
                  ).some((v) => v)}
                  active={k === activeEnrichment}
                  onActivate={() => setActiveEnrichment(k)}
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  )
}

const TIME_DIMENSIONS = [
  { value: 'YearWeek', label: 'Year-Week (Starting on Monday)' },
]

function ItemPill({ active, setActive, icon, disabled, children, ...props }) {
  const { t } = useTranslation()
  const result = (
    <div
      className={`py-2 px-3 ${active ? 'bg-mmmbutton' : 'bg-importlightblue'} rounded-3xl text-importbg w-fit font-bold inline-flex flex-nowrap select-none  ${props?.className ?? ''} items-center ${disabled ? ' opacity-60' : 'cursor-pointer'}`}
      onClick={() => !disabled && setActive(!active)}
    >
      <span className="inline-flex items-center min-w-5 max-w-5 me-2">
        {active ? (
          <FaCheck className="animate-popin" size={20} />
        ) : (
          <FaPlus size={20} />
        )}
      </span>
      {children}
      {icon && <div className="inline-flex items-center px-1">{icon}</div>}
    </div>
  )
  if (disabled) {
    return (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="auto"
        delay={{ show: 100, hide: 100 }}
        overlay={({ ...props }) => (
          <Popover {...props}>
            <div className="text-black">
              <div className="text-center p-2">{t(disabled)}</div>
            </div>
          </Popover>
        )}
      >
        {result}
      </OverlayTrigger>
    )
  }

  return result
}

const step_labels = {
  0: [
    'Select the way you want your data to be visualized. You can choose the account to break down the information by account.',
    'The cost will always be included in every Media Channel.',
  ],
  1: [
    'Select the way you want your data to be visualized. You can choose the account to break down the information by account.',
  ],
  2: [
    'Select the way you want your data to be visualized. You can choose the account to break down the information by account.',
  ],
}

function ConfigureHead({
  step,
  mediaChannelDatasources,
  timeDimension,
  setTimeDimension,
  mediaUserAccount,
  setMediaUserAccount,
  campaignAdAggregation,
  setCampaignAdAggregation,
  customConfigurations,
  setCustomConfigurations,
  customItemPath,
  customItem,
}) {
  const { t } = useTranslation()

  const active = customItem
    ? customItem?.aggregateAccount
    : mediaUserAccount[
        mediaChannelDatasources?.[mediaChannelDatasources.length - 1]
      ]
  const labels = step_labels[step]
  const datasource = customItemPath
    ? customItemPath[0]
    : mediaChannelDatasources[mediaChannelDatasources.length - 1]

  return (
    <>
      <Row>
        <Col className="font-bold text-2xl" xs={12}>
          {t('Default fields')}
        </Col>
        {labels.map((l, i, a) => (
          <Col
            xs={12}
            key={i}
            className={`text-xs ${i === a.length - 1 ? 'mb-2' : ''}`}
          >
            {t(l)}
          </Col>
        ))}
        <Col xs={12}>
          <CustomSelect
            className="basic-single mt-2 w-100"
            classNamePrefix="select"
            isSearchable={true}
            placeholder={'Select...'}
            isClearable={true}
            onChange={(e) => setTimeDimension(e?.value)}
            options={TIME_DIMENSIONS}
            value={TIME_DIMENSIONS.find((v) => v.value === timeDimension)}
          />
        </Col>
        <Col className="mt-2" xs={12}>
          <Row>
            <Col className="mb-1" xs="auto">
              <ItemPill
                active={active}
                setActive={() => {
                  if (customItem) {
                    customItem.aggregateAccount = !active
                    setCustomConfigurations({ ...customConfigurations })
                    return
                  }
                  if (!datasource) return
                  setMediaUserAccount({
                    ...mediaUserAccount,
                    [datasource]: !mediaUserAccount[datasource],
                  })
                }}
              >
                {t(translate_account_name?.[datasource] ?? 'Account name')}
              </ItemPill>
            </Col>
            {campaign_dimensions_datasources.includes(datasource) && (
              <Col className="mb-1" xs="auto">
                <ItemPill
                  active={
                    customItem
                      ? customItem?.aggregateCampaign
                      : !!campaignAdAggregation.campaign[datasource]
                  }
                  setActive={() => {
                    if (customItem) {
                      customItem.aggregateCampaign =
                        !customItem.aggregateCampaign
                      setCustomConfigurations({ ...customConfigurations })
                      return
                    }
                    campaignAdAggregation.campaign[datasource] =
                      !campaignAdAggregation.campaign[datasource]
                    setCampaignAdAggregation({ ...campaignAdAggregation })
                  }}
                >
                  {t('Campaign')}
                </ItemPill>
              </Col>
            )}
            {adgroup_dimensions_datasources.includes(datasource) && (
              <Col className="mb-1" xs="auto">
                <ItemPill
                  active={
                    customItem
                      ? customItem?.aggregateAdgroup
                      : !!campaignAdAggregation.adgroup[datasource]
                  }
                  setActive={() => {
                    if (customItem) {
                      customItem.aggregateAdgroup = !customItem.aggregateAdgroup
                      setCustomConfigurations({ ...customConfigurations })
                      return
                    }
                    campaignAdAggregation.adgroup[datasource] =
                      !campaignAdAggregation.adgroup[datasource]
                    setCampaignAdAggregation({ ...campaignAdAggregation })
                  }}
                >
                  {t('Ad group')}
                </ItemPill>
              </Col>
            )}
            {ad_dimensions_datasources.includes(datasource) && (
              <Col className="mb-1" xs="auto">
                <ItemPill
                  active={
                    customItem
                      ? customItem?.aggregateAd
                      : !!campaignAdAggregation.ad[datasource]
                  }
                  setActive={() => {
                    if (customItem) {
                      customItem.aggregateAd = !customItem.aggregateAd
                      setCustomConfigurations({ ...customConfigurations })
                      return
                    }
                    campaignAdAggregation.ad[datasource] =
                      !campaignAdAggregation.ad[datasource]
                    setCampaignAdAggregation({ ...campaignAdAggregation })
                  }}
                >
                  {t('Ad')}
                </ItemPill>
              </Col>
            )}
            {datasource === 'analytics_v4' && (
              <Col className="mb-1 pointer-events-none opacity-80" xs="auto">
                <ItemPill active={true} setActive={() => {}}>
                  {t('Default channel grouping')}
                </ItemPill>
              </Col>
            )}
            <hr className="mt-2"></hr>
          </Row>
        </Col>
      </Row>
    </>
  )
}

function MetricsSelector({
  metrics,
  connectors,
  disabledEntries,
  value,
  onChange,
  single,
  showCount = true,
  maxH = '56',
  placeholder = 'Search a metric...',
  selectAll = false,
}) {
  const { t } = useTranslation()
  const searchRef = useRef()
  const [filter, setFilter] = useState('')
  const updateFilter = useDebouncedCallback((v) => setFilter(v), 500)
  const selected = Object.values(value).filter((v) => v).length
  const [onlySelected, setOnlySelected] = useState(false)

  useEffect(() => {
    if (!selected && onlySelected) {
      setOnlySelected(false)
    }
  }, [selected, onlySelected])

  const flatMetrics = useMemo(() => {
    const validKeys = Object.keys(metrics).filter((k) => metrics[k])
    const items = validKeys.map((k) => {
      const items = Object.values(metrics[k]?.result?.metrics ?? {})
        .flat()
        .map((i) => ({ ...i, datasource: k }))
      return items
    })
    let result = items.flat()
    if (filter) {
      const inverse = filter.startsWith('!') || filter.startsWith('^')
      let f = filter.toLowerCase()
      if (inverse) f = f.slice(1)
      result = result.filter((r) => {
        const match = r.name.toLowerCase().includes(f)
        return inverse ? !match : match
      })
    }
    result.sort((a, b) => a.name.localeCompare(b.name))

    return result
  }, [metrics, filter])

  const disabledItems = useMemo(() => {
    const result = {}
    const messages = [
      t(
        'This metric is already selected as a media channel. You must deselect it before adding it to this section.',
      ),
      t(
        'This metric is already selected as a context variable. You must deselect it before adding it to this section.',
      ),
      t(
        'This metric is already selected as a KPI. You must deselect it before adding it to this section.',
      ),
    ]
    try {
      if (Array.isArray(disabledEntries))
        disabledEntries.forEach(
          (i, index) =>
            i &&
            Object.entries(i).forEach(
              ([k, v]) => v && (result[k] = messages[index]),
            ),
        )
    } catch (e) {
      console.error(e)
    }
    return result
  }, [disabledEntries])

  return (
    <Row>
      <Col className="relative" xs={12}>
        <FaMagnifyingGlass
          className="absolute"
          size={16}
          style={{
            top: `calc(50% - 8px)`,
            left: '25px',
          }}
        />
        <Form.Control
          className="text-input-mmm-nocolor dark:text-white text-white"
          placeholder={t(placeholder)}
          defaultValue={filter}
          onChange={(e) => updateFilter(e.target.value)}
          ref={searchRef}
        />
      </Col>
      {showCount && (
        <Col
          className="text-sm font-bold mt-2 inline-flex justify-between"
          xs={12}
        >
          <div
            className={`rounded-xl px-2 py-0 border-1 ${onlySelected ? 'border-green-700 bg-green-300' : 'border-green-300 bg-green-100'}  text-importbg cursor-pointer duration-300 active:scale-90 w-fit`}
            onClick={() => setOnlySelected(!onlySelected)}
          >
            {selected || t('None')} {t('Selected')}
          </div>
          {selectAll && !single && (
            <div
              className={`rounded-xl ms-1 px-2 py-0 border-1 border-green-100 bg-transparent  text-green-100 cursor-pointer duration-300 active:scale-90 w-fit`}
              onClick={() => {
                const allSelected = flatMetrics
                  .map((m) => value?.[m?.id])
                  .every((v) => v)
                const newValue = {}
                if (allSelected) {
                  flatMetrics.forEach((m) => {
                    newValue[m.id] = false
                  })
                } else
                  flatMetrics.forEach((m) => {
                    if (disabledItems?.[m.id]) return
                    newValue[m.id] = true
                  })
                onChange(
                  { ...(value || {}), ...newValue },
                  null,
                  null,
                  flatMetrics[0]?.datasource,
                  [],
                )
              }}
            >
              {'Select all'}
            </div>
          )}
        </Col>
      )}
      <Col className={`mt-1 max-h-${maxH} overflow-auto`} xs={12}>
        <Row>
          {flatMetrics.map((m, i) =>
            onlySelected && !value?.[m?.id] ? (
              <Fragment key={i}></Fragment>
            ) : (
              <Col className="my-1" key={i} xs={'auto'}>
                <ItemPill
                  className="text-sm"
                  disabled={disabledItems?.[m?.id]}
                  active={value?.[m?.id]}
                  setActive={(v) => {
                    onChange(
                      single ? { [m?.id]: v } : { ...value, [m?.id]: v },
                      m.id,
                      m.name,
                      m?.datasource,
                    )
                    searchRef.current.value = ''
                    searchRef.current.focus()
                    setFilter('')
                  }}
                >
                  {m.name}
                </ItemPill>
              </Col>
            ),
          )}
        </Row>
      </Col>
    </Row>
  )
}

function ConfigureMediaChannels({
  step,
  timeDimension,
  setTimeDimension,
  mediaUserAccount,
  setMediaUserAccount,
  mediaChannelDatasources,
  setMediaChannelDatasources,
  mediaChannelMetrics,
  activeMediaChannel,
  campaignAdAggregation,
  setCampaignAdAggregation,
  customItemPath,
  customItem,
  adjustMetrics,
}) {
  const { t } = useTranslation()

  const isCustom = customItem || customItemPath
  if (isCustom) return <></>

  const activeCost = isCustom
    ? false
    : mediaChannelDatasources.includes(activeMediaChannel)
  return (
    <Row>
      <Col xs={12}>
        <ConfigureHead
          mediaChannelDatasources={
            step === 0
              ? activeMediaChannel
                ? [activeMediaChannel]
                : []
              : mediaChannelDatasources
          }
          timeDimension={timeDimension}
          setTimeDimension={setTimeDimension}
          mediaUserAccount={mediaUserAccount}
          setMediaUserAccount={setMediaUserAccount}
          step={step}
          campaignAdAggregation={campaignAdAggregation}
          setCampaignAdAggregation={setCampaignAdAggregation}
        />
      </Col>
      <Col className="my-2" xs={12}>
        {activeMediaChannel && (
          <ItemPill
            active={activeCost}
            setActive={() => {
              setMediaChannelDatasources(
                activeCost
                  ? mediaChannelDatasources.filter(
                      (d) => d !== activeMediaChannel,
                    )
                  : [...mediaChannelDatasources, activeMediaChannel],
              )
              adjustMetrics(
                'cost',
                datasources_cost_metric?.[activeMediaChannel]?.[1],
                activeMediaChannel,
              )
            }}
          >
            {t('Total cost')}
          </ItemPill>
        )}
      </Col>
    </Row>
  )
}

function ConfigureContextVariables({
  step,
  connectors,
  timeDimension,
  setTimeDimension,
  mediaUserAccount,
  setMediaUserAccount,
  mediaChannelDatasources,
  setMediaChannelDatasources,
  contextVariables,
  setContextVariables,
  metricsNameRegister,
  setDisabledContextVariables,
  campaignAdAggregation,
  setCampaignAdAggregation,
  customConfigurations,
  setCustomConfigurations,
  customItemPath,
  customItem,
  adjustMetrics,
  selectedAccounts,
  nameRegister,
  kpis,
}) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [datasources, setDatasources] = useState([])
  const isCustom = customItem || customItemPath

  const setItems = useDebouncedCallback(async (value) => {
    setDatasources(value)
  }, 300)

  useEffect(() => {
    if (isCustom) {
      setItems([customItemPath[0]])
    } else if (Array.isArray(mediaChannelDatasources)) {
      if (!mediaChannelDatasources.length) {
        setDatasources([])
        return
      }
      const items = [...mediaChannelDatasources]
      items.sort((a, b) => a.localeCompare(b))
      setItems(items)
    }
  }, [mediaChannelDatasources, isCustom])

  const { data: defaultMetrics, isLoading: isLoadingMetrics } = useQuery(
    ['metrics', datasources, customItem?.kwargs?.call_type],
    async () => {
      if (!datasources?.length) return {}
      const items = await Promise.all(
        datasources.map((d) => {
          let callType = null
          if (d === 'google_trends') {
            callType = customConfigurations?.google_trends?.queryType?.value
          }
          return getMetrics({
            datasource: d,
            callType: customItem?.kwargs?.call_type ?? callType,
            token,
          }).then(async (r) => {
            if (r.ok) {
              let metrics = await r.json()
              if (d === 'hubspot_basic')
                if (metrics?.result?.metrics?.Analytics) {
                  metrics.result.metrics = {
                    Analytics: metrics.result.metrics.Analytics,
                  }
                }
              return metrics
            } else return null
          })
        }),
      )
      return datasources.reduce((acc, d, i) => {
        acc[d] = items[i]
        return acc
      }, {})
    },
  )
  const customDS = datasources?.[0]
  const { data: customMetrics, isLoading: isLoadingCustomMetrics } = useQuery(
    ['customMetrics', customDS, selectedAccounts?.[customDS]],
    async () => {
      switch (customDS) {
        case 'facebook':
        case 'analytics_v4':
          const ep = {
            facebook: getCustomMetricsFacebook,
            analytics_v4: getCustomMetricsAnalytics_v4,
          }
          const fetchFunction = ep[customDS]
          const accounts = selectedAccounts?.[customDS] ?? {}
          if (!Object.keys(accounts).length) return null
          const items = Object.keys(accounts)
            .map((connectionId) => {
              const items = Object.values(accounts[connectionId] || {})
                .map((d) =>
                  Object.entries(d)
                    .filter(([id, enabled]) => enabled)
                    .map(([id]) => nameRegister?.[id])
                    .filter((v) => v)
                    .map((v) => ({
                      ...v,
                      connection_id: connectionId,
                    })),
                )
                .flat()
              return items
            })
            .flat()
          if (!items?.length) return null
          const response = await fetchFunction({
            data: {
              accounts: items,
            },
            token,
          })
          if (response.ok) return await response.json()
          throw new Error('Error fetching custom metrics')
        default:
          return null
      }
    },
    { staleTime: Infinity },
  )

  const metrics = useMemo(() => {
    if (
      Array.isArray(customMetrics) &&
      customMetrics.length &&
      defaultMetrics?.[customDS]?.result?.metrics
    ) {
      const reMetrics = JSON.parse(JSON.stringify(defaultMetrics))
      reMetrics[customDS].result.metrics[''] =
        reMetrics[customDS].result.metrics[''] || []
      reMetrics[customDS].result.metrics[''].push(...customMetrics)
      return reMetrics
    }
    return defaultMetrics
  }, [defaultMetrics, customMetrics])

  const activeDatasource = customItemPath
    ? customItemPath[0]
    : mediaChannelDatasources?.[0]

  const disabledEntries = [
    null,
    null,
    customItem ? customItem?.kpis : kpis?.[activeDatasource] ?? {},
  ]

  return (
    <Row>
      <Col xs={12}>
        <ConfigureHead
          step={step}
          timeDimension={timeDimension}
          setTimeDimension={setTimeDimension}
          mediaUserAccount={mediaUserAccount}
          setMediaUserAccount={setMediaUserAccount}
          mediaChannelDatasources={mediaChannelDatasources}
          campaignAdAggregation={campaignAdAggregation}
          setCampaignAdAggregation={setCampaignAdAggregation}
          customConfigurations={customConfigurations}
          setCustomConfigurations={setCustomConfigurations}
          customItemPath={customItemPath}
          customItem={customItem}
        />
      </Col>
      <Col xs={12}>
        <Row>
          <Col className="font-bold text-2xl" xs={12}>
            {t('Select more fields to include')}
          </Col>
          <Col className="text-xs mb-2" xs={12}>
            {t(
              'Select those metrics that best represent the factors that could be influencing your KPI.',
            )}
          </Col>
        </Row>
      </Col>
      {!customItem && mediaChannelDatasources?.length === 0 && (
        <Col
          className="text-sm my-1 mb-3 text-center text-red-300 border-2 border-red-300 p-2 rounded-3xl"
          xs={12}
        >
          {t(
            'Please select a datasource to to import the context variables if there is any you would like to add.',
          )}
        </Col>
      )}
      {isLoadingMetrics && (
        <Col xs={12}>
          <Row className="my-4">
            <ImSpinner9 size={40} className="animate-spin" />
          </Row>
        </Col>
      )}
      {metrics && typeof metrics === 'object' && activeDatasource && (
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <MetricsSelector
                selectAll={true}
                metrics={metrics}
                disabledEntries={disabledEntries}
                value={
                  (customItem
                    ? customItem?.contextVariables
                    : contextVariables?.[activeDatasource]) ?? {}
                }
                onChange={(v, id, name, datasource, all) => {
                  if (customItem) {
                    customItem.contextVariables = v
                    if (customItem.kpis?.[id])
                      customItem.kpis = { ...customItem.kpis, [id]: false }
                    if (all) {
                      Object.keys(all).forEach((k) => {
                        if (customItem?.kpis?.[k])
                          customItem.kpis = { ...customItem.kpis, [k]: false }
                      })
                    }
                    setCustomConfigurations({ ...customConfigurations })
                  } else {
                    adjustMetrics('context', id, activeDatasource, all)
                    setDisabledContextVariables((v) => ({
                      ...v,
                      [activeDatasource]: false,
                    }))
                    setContextVariables({
                      ...contextVariables,
                      [activeDatasource]: v,
                    })
                  }
                  metricsNameRegister[activeDatasource] =
                    metricsNameRegister[activeDatasource] ?? {}
                  metricsNameRegister[activeDatasource][id] = name
                }}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

function ConfigureKPI({
  step,
  connectors,
  timeDimension,
  setTimeDimension,
  mediaUserAccount,
  setMediaUserAccount,
  mediaChannelDatasources,
  kpis,
  setKpis,
  metricsNameRegister,
  fileStatus,
  setFileStatus,
  campaignAdAggregation,
  setCampaignAdAggregation,
  customConfigurations,
  setCustomConfigurations,
  customItemPath,
  customItem,
  adjustMetrics,
  selectedAccounts,
  nameRegister,
  contextVariables,
}) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [datasources, setDatasources] = useState([])
  const isCustom = customItem || customItemPath

  const setItems = useDebouncedCallback(async (value) => {
    setDatasources(value)
  }, 300)

  useEffect(() => {
    if (isCustom) {
      setItems([customItemPath[0]])
    } else if (Array.isArray(mediaChannelDatasources)) {
      if (!mediaChannelDatasources.length) {
        setDatasources([])
        return
      }
      const items = [...mediaChannelDatasources]
      items.sort((a, b) => a.localeCompare(b))
      setItems(items)
    }
  }, [mediaChannelDatasources])

  const { data: defaultMetrics, isLoading: isLoadingMetrics } = useQuery(
    ['metrics', datasources, customItem?.kwargs?.call_type],
    async () => {
      if (!datasources?.length) return {}
      const items = await Promise.all(
        datasources.map((d) => {
          let callType = null
          if (d === 'google_trends') {
            callType = customConfigurations?.google_trends?.queryType?.value
          }
          return getMetrics({
            datasource: d,
            callType: customItem?.kwargs?.call_type ?? callType,
            token,
          }).then(async (r) => {
            if (r.ok) {
              let metrics = await r.json()
              if (d === 'hubspot_basic')
                if (metrics?.result?.metrics?.Analytics) {
                  metrics.result.metrics = {
                    Analytics: metrics.result.metrics.Analytics,
                  }
                }
              return metrics
            } else return null
          })
        }),
      )
      return datasources.reduce((acc, d, i) => {
        acc[d] = items[i]
        return acc
      }, {})
    },
  )

  const customDS = datasources?.[0]
  const { data: customMetrics, isLoading: isLoadingCustomMetrics } = useQuery(
    ['customMetrics', customDS, selectedAccounts?.[customDS]],
    async () => {
      switch (customDS) {
        case 'facebook':
        case 'analytics_v4':
          const ep = {
            facebook: getCustomMetricsFacebook,
            analytics_v4: getCustomMetricsAnalytics_v4,
          }
          const fetchFunction = ep[customDS]
          const accounts = selectedAccounts?.[customDS] ?? {}
          if (!Object.keys(accounts).length) return null
          const items = Object.keys(accounts)
            .map((connectionId) => {
              const items = Object.values(accounts[connectionId] || {})
                .map((d) =>
                  Object.entries(d)
                    .filter(([id, enabled]) => enabled)
                    .map(([id]) => nameRegister?.[id])
                    .filter((v) => v)
                    .map((v) => ({
                      ...v,
                      connection_id: connectionId,
                    })),
                )
                .flat()
              return items
            })
            .flat()
          if (!items?.length) return null
          const response = await fetchFunction({
            data: {
              accounts: items,
            },
            token,
          })
          if (response.ok) {
            const customMetrics = await response.json()
            return customMetrics?.metrics ?? customMetrics
          }
          throw new Error('Error fetching custom metrics')
        default:
          return null
      }
    },
    { staleTime: Infinity },
  )

  const metrics = useMemo(() => {
    if (
      Array.isArray(customMetrics) &&
      customMetrics.length &&
      defaultMetrics?.[customDS]?.result?.metrics
    ) {
      const reMetrics = JSON.parse(JSON.stringify(defaultMetrics))
      reMetrics[customDS].result.metrics[''] =
        reMetrics[customDS].result.metrics[''] || []
      reMetrics[customDS].result.metrics[''].push(...customMetrics)
      return reMetrics
    }
    return defaultMetrics
  }, [defaultMetrics, customMetrics])

  const activeDatasource = customItemPath
    ? customItemPath[0]
    : mediaChannelDatasources?.[0]

  const disabledEntries = [
    null,
    customItem
      ? customItem?.contextVariables
      : contextVariables?.[activeDatasource] ?? {},
    null,
  ]

  return (
    <Row>
      <Col xs={12}>
        <ConfigureHead
          step={step}
          timeDimension={timeDimension}
          setTimeDimension={setTimeDimension}
          mediaUserAccount={mediaUserAccount}
          setMediaUserAccount={setMediaUserAccount}
          mediaChannelDatasources={mediaChannelDatasources}
          campaignAdAggregation={campaignAdAggregation}
          setCampaignAdAggregation={setCampaignAdAggregation}
          customConfigurations={customConfigurations}
          setCustomConfigurations={setCustomConfigurations}
          customItemPath={customItemPath}
          customItem={customItem}
        />
      </Col>
      <Col className="mt-2" xs={12}>
        <Row>
          <Col className="font-bold text-2xl" xs={12}>
            {t('Select a field to include')}
          </Col>
          <Col className="text-xs mb-2" xs={12}>
            {t(
              'Select the key metric you want to study in the Marketing Mix Modeling analysis. You can only select 1 KPI.',
            )}
          </Col>
        </Row>
      </Col>
      {isLoadingMetrics && (
        <Col xs={12}>
          <Row className="my-4">
            <ImSpinner9 size={40} className="animate-spin" />
          </Row>
        </Col>
      )}
      {metrics && typeof metrics === 'object' && activeDatasource && (
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <MetricsSelector
                metrics={metrics}
                disabledEntries={disabledEntries}
                value={
                  (customItem ? customItem?.kpis : kpis?.[activeDatasource]) ??
                  {}
                }
                onChange={(v, id, name) => {
                  Object.keys(customConfigurations).forEach((datasource) => {
                    if (custom_static_config_datasource.includes(datasource)) {
                      Object.values(customConfigurations[datasource]).forEach(
                        (v) => {
                          if (v.kpis) delete v['kpis']
                        },
                      )
                    }
                  })
                  if (customItem) {
                    customItem.kpis = v
                    if (customItem.contextVariables?.[id])
                      customItem.contextVariables = {
                        ...customItem.contextVariables,
                        [id]: false,
                      }
                    setCustomConfigurations({ ...customConfigurations })
                    setKpis({})
                  } else {
                    adjustMetrics('kpi', id, activeDatasource)
                    setKpis({ [activeDatasource]: v })
                    setFileStatus({
                      ...fileStatus,
                      selected: { ...(fileStatus?.selected ?? {}), [step]: {} },
                    })
                  }
                  metricsNameRegister[activeDatasource] =
                    metricsNameRegister[activeDatasource] ?? {}
                  metricsNameRegister[activeDatasource][id] = name
                }}
                single={true}
                showCount={false}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

function ConfigureFiles({ step, fileStatus, setFileStatus, file, setKpis }) {
  const { t } = useTranslation()

  const metrics = useMemo(() => {
    const data = {
      file_metrics: {
        result: {
          metrics: {
            '': file.columns
              .filter((c) => c.type === 'numeric')
              .map((c) => ({ name: c.name, id: c.name })),
          },
        },
      },
    }
    return data
  }, [file])

  const dateOptions = useMemo(() => {
    return file.columns
      .filter((c) => c.type === 'datetime')
      .map((c) => ({ label: c.name, value: c.name }))
  }, [file])
  const selectedDatetime = fileStatus?.selectedDateCol?.[file.filename]

  const dateFormatOptions = useMemo(() => {
    return (
      file.columns.find((c) => c.name === selectedDatetime?.value)?.options
        ?.datetime_options ?? []
    )
  }, [selectedDatetime])
  const selectedDatetimeOption =
    fileStatus?.selectedOption?.[file.filename]?.[selectedDatetime?.value]

  useEffect(() => {
    if (
      !fileStatus?.selectedDateCol?.[file.filename] &&
      dateOptions.length === 1
    ) {
      fileStatus['selectedDateCol'] = fileStatus['selectedDateCol'] ?? {}
      fileStatus['selectedDateCol'][file.filename] = dateOptions[0]
      setFileStatus({ ...fileStatus })
    }
  }, [dateOptions])

  return (
    <Row>
      <Col className="mt-2" xs={12}>
        <Row>
          <Col className="font-bold text-2xl" xs={12}>
            {t('Default fields')}
          </Col>
          <Col className="text-xs mb-2" xs={12}>
            <span>
              {t('Select the way you want your data to be visualized')}
            </span>{' '}
            <span className="font-bold">
              {t('Learn how the date format should be')}
            </span>{' '}
            <a
              href="https://support.dataslayer.ai/understanding-the-uploaded-local-file-format-in-morpheus"
              target="_blank"
              rel="noreferrer"
            >
              {t('here')}
            </a>
            .
          </Col>
        </Row>
      </Col>
      <Col className="text-xs" xs={12}>
        <span className="ms-2">{t('DETECTED DATE COLUMN')}</span>
      </Col>
      <Col className="inline-flex items-center mb-2" xs={12}>
        <CustomSelect
          value={
            dateOptions.length === 0
              ? {
                  label: 'No date columns detected',
                  value: 'No date columns detected',
                }
              : selectedDatetime
          }
          className="basic-single mt-1 w-full"
          classNamePrefix="select"
          isSearchable={true}
          placeholder={'Select a datetime column...'}
          isDisabled={dateOptions.length < 2}
          isClearable={false}
          onChange={(e) => {
            fileStatus.selectedDateCol = fileStatus?.selectedDateCol ?? {}
            fileStatus['selectedDateCol'][file.filename] =
              fileStatus['selectedDateCol'][file.filename] || {}
            fileStatus['selectedDateCol'][file.filename] = e
            setFileStatus({ ...fileStatus })
          }}
          options={dateOptions}
        />
        {dateOptions?.length === 0 && (
          <CiWarning className="text-[#F45151]" size={35} />
        )}
      </Col>
      {dateFormatOptions?.length > 1 && (
        <>
          <Col className="text-xs" xs={12}>
            <span className="ms-2">{t('DATE FORMAT')}</span>
          </Col>
          <Col className="inline-flex items-center mb-2" xs={12}>
            <CustomSelect
              value={selectedDatetimeOption}
              className="basic-single mt-1 w-full"
              classNamePrefix="select"
              isSearchable={true}
              placeholder={'Select datetime format...'}
              isClearable={false}
              onChange={(e) => {
                fileStatus?.selectedOption?.[file.filename]?.[
                  selectedDatetime?.value
                ]
                fileStatus.selectedOption = fileStatus?.selectedOption || {}
                fileStatus.selectedOption[file.filename] =
                  fileStatus.selectedOption[file.filename] || {}
                fileStatus.selectedOption[file.filename][
                  selectedDatetime?.value
                ] = e
                setFileStatus({ ...fileStatus })
              }}
              options={dateFormatOptions.map((o) => ({ label: o, value: o }))}
            />
          </Col>
        </>
      )}
      <Col className="mt-3" xs={12}>
        <Row>
          <Col xs={12}>
            <MetricsSelector
              metrics={metrics}
              selectAll={true}
              disabledEntries={[
                step === 0
                  ? null
                  : fileStatus?.selected?.[0]?.[file.filename] ?? {},
                step === 1 ? null : fileStatus?.selected?.[1]?.[file.filename],
                step === 2
                  ? null
                  : fileStatus?.selected?.[2]?.[file.filename] ?? {},
              ]}
              value={fileStatus?.selected?.[step]?.[file.filename] ?? {}}
              onChange={(v, id, group, datasource, all) => {
                if (step === 2) {
                  setKpis({})
                }
                fileStatus['selected'] = fileStatus['selected'] ?? {}
                fileStatus['selected'][step] =
                  fileStatus['selected'][step] ?? {}
                if (step === 2) fileStatus['selected'][step] = {}
                fileStatus['selected'][step][file.filename] = v
                const st = [0, 1, 2].filter((s) => s !== step)
                st.forEach((s) => {
                  if (id) {
                    if (fileStatus?.['selected']?.[s]?.[file.filename]?.[id])
                      fileStatus['selected'][s][file.filename][id] = false
                  } else if (all) {
                    Object.keys(all).forEach((id) => {
                      if (fileStatus?.['selected']?.[s]?.[file.filename]?.[id])
                        fileStatus['selected'][s][file.filename][id] = false
                    })
                  }
                })
                setFileStatus({ ...fileStatus })
              }}
              maxH={dateFormatOptions?.length > 1 ? '56' : '64'}
              placeholder="Search a column..."
              single={step === 2}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function ConfigureEnrichment({
  activeEnrichment,
  enrichmentDatasources,
  setEnrichmentDatasources,
}) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const enrichment = AVAILABLE_ENRICHMENTS.find(
    (e) => e.name === activeEnrichment,
  )
  const enrichmentConfiguration = enrichmentDatasources[activeEnrichment]
  const requirements = enrichment?.requirements ?? new Set()

  const [locationResult, setLocationResult] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)

  return (
    <Row>
      <Col className="mt-2" xs={12}>
        <Row>
          <Col className="font-bold text-2xl" xs={12}>
            {t('Required fields')}
          </Col>
        </Row>
      </Col>
      {requirements.has('location') && (
        <>
          <Col className="text-sm my-1" xs={12}>
            <span className="ms-2">{t('Location')}</span>
          </Col>
          <Col className="inline-flex items-center relative" xs={12}>
            {loadingLocation ? (
              <FaSpinner
                className="absolute left-[18px] top-[3px] animate-spin"
                size={25}
              />
            ) : (
              <CiLocationOn
                className="absolute left-[18px] top-[3px]"
                size={25}
              />
            )}
            <Form.Control
              className="text-input-mmm pe-4"
              defaultValue={enrichmentConfiguration?.kwargs?.location ?? ''}
              onBlur={(e) => {
                const value = e.target.value.trim()
                enrichmentDatasources[activeEnrichment].kwargs =
                  enrichmentDatasources[activeEnrichment].kwargs || {}
                enrichmentDatasources[activeEnrichment].kwargs.location = value
                if (value) {
                  setLoadingLocation(true)
                  setLocationResult(null)
                  checkLocation({ location: value, token })
                    .then(async (r) => {
                      if (r.ok) {
                        const res = await r.json()
                        if (res?.ok) {
                          setLocationResult({
                            ok: true,
                            location: res.location,
                          })
                          return
                        }
                      }
                      setLocationResult({
                        ok: false,
                        location: `${value} not found`,
                      })
                    })
                    .finally(() => setLoadingLocation(false))
                }
              }}
            />
          </Col>
          <Col
            xs={12}
            className={`${locationResult ? '' : 'opacity-0 user-select-none'}`}
          >
            {locationResult && (
              <span
                className={`inline-flex align-items-center text-xs ms-3 font-bold ${locationResult?.ok ? 'text-green-500' : 'text-red-500'}`}
              >
                <MdSubdirectoryArrowRight />
                {locationResult.location}
              </span>
            )}
          </Col>
        </>
      )}
      {requirements.has('country') && (
        <>
          <Col className="text-sm my-1" xs={12}>
            <span className="ms-2">{t('Country')}</span>
          </Col>
          <Col className="inline-flex items-center mb-2 relative" xs={12}>
            <FaGlobe className="absolute left-[18px] top-[7px]" size={20} />
            <CountrySelector
              className="basic-single  min-w-full ps-4"
              value={enrichmentConfiguration?.kwargs?.country ?? ''}
              onChange={(value) => {
                enrichmentDatasources[activeEnrichment].kwargs =
                  enrichmentDatasources[activeEnrichment].kwargs || {}
                enrichmentDatasources[activeEnrichment].kwargs.country = value
                setEnrichmentDatasources({ ...enrichmentDatasources })
              }}
            />
          </Col>
        </>
      )}
      {requirements.has('dual_currency') && (
        <>
          <Col className="text-sm my-1" xs={12}>
            <span className="ms-2">{t('Source currency')}</span>
          </Col>
          <Col className="inline-flex items-center mb-2 relative" xs={12}>
            <MdCurrencyExchange
              className="absolute left-[18px] top-[7px]"
              size={20}
            />
            <CountrySelector
              className="basic-single  min-w-full ps-4"
              currency={true}
              value={enrichmentConfiguration?.kwargs?.currency_source ?? ''}
              onChange={(value) => {
                enrichmentDatasources[activeEnrichment].kwargs =
                  enrichmentDatasources[activeEnrichment].kwargs || {}
                enrichmentDatasources[activeEnrichment].kwargs.currency_source =
                  value
                setEnrichmentDatasources({ ...enrichmentDatasources })
              }}
            />
          </Col>
          <Col className="text-sm my-1" xs={12}>
            <span className="ms-2">{t('Target currency')}</span>
          </Col>
          <Col className="inline-flex items-center mb-2 relative" xs={12}>
            <MdCurrencyExchange
              className="absolute left-[18px] top-[7px]"
              size={20}
            />
            <CountrySelector
              className="basic-single  min-w-full ps-4"
              currency={true}
              value={enrichmentConfiguration?.kwargs?.currency_target ?? ''}
              onChange={(value) => {
                enrichmentDatasources[activeEnrichment].kwargs =
                  enrichmentDatasources[activeEnrichment].kwargs || {}
                enrichmentDatasources[activeEnrichment].kwargs.currency_target =
                  value
                setEnrichmentDatasources({ ...enrichmentDatasources })
              }}
            />
          </Col>
        </>
      )}
      <Col className="mt-3" xs={12}>
        <Row>
          <Col xs={12}>
            <MetricsSelector
              metrics={{
                file_metrics: {
                  result: {
                    metrics: {
                      '': enrichment.metrics(),
                    },
                  },
                },
              }}
              selectAll={true}
              value={enrichmentConfiguration?.contextVariables ?? {}}
              onChange={(v, id, group, datasource, all) => {
                enrichmentDatasources[activeEnrichment].contextVariables = v
                setEnrichmentDatasources({ ...enrichmentDatasources })
              }}
              maxH={'64'}
              placeholder="Search a metric..."
              single={false}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const CONFIGURE_ITEMS = [
  ConfigureMediaChannels,
  ConfigureContextVariables,
  ConfigureKPI,
]
const BASE = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
)
const TIME_OPTIONS = [
  {
    value: 1000 * 60 * 60 * 24 * 365 * 2,
    label: 'Last 2 years',
  },
  { value: 1000 * 60 * 60 * 24 * 365, label: 'Last year' },
  { value: 1, label: 'Autoselect from files' },
]

function activeCustomConfigGet(configurations, path) {
  let item = configurations
  for (const p of path) {
    if (item) item = item?.[p]
  }
  return item
}

export default function ConfigureInputs({
  connectors,
  selectedAccounts,
  nameRegister,
  metricsNameRegister,
  step,
  setStep,
  timeFrame,
  setTimeFrame,
  timeDimension,
  setTimeDimension,
  mediaChannelDatasources,
  setMediaChannelDatasources,
  contextVariablesDatasources,
  setContextVariablesDatasources,
  kpiDatasources,
  setKpiDatasources,
  mediaUserAccount,
  setMediaUserAccount,
  mediaChannelMetrics,
  setMediaChannelmetrics,
  contextVariables,
  setContextVariables,
  kpis,
  setKpis,
  disabledContextVariables,
  setDisabledContextVariables,
  customConfigurations,
  setCustomConfigurations,
  localFiles,
  fileStatus,
  setFileStatus,
  campaignAdAggregation,
  setCampaignAdAggregation,
  enrichmentDatasources,
  setEnrichmentDatasources,
}) {
  const { t } = useTranslation()
  const calendarRef = useRef()
  const [activeMediaChannel, setActiveMediaChannel] = useState(null)
  const [_activeEnrichment, setActiveEnrichment] = useState(null)
  let activeEnrichment = _activeEnrichment
  if (step !== 1) activeEnrichment = null

  const [activeCustomConfiguration, setActiveCustomConfiguration] =
    useState(null)
  const activeCustomItem =
    activeCustomConfiguration &&
    activeCustomConfigGet(customConfigurations, activeCustomConfiguration)

  const datasourcesWithUsers = useMemo(() => {
    const checkDict = (d) => {
      if (typeof d === 'boolean' && d) return true
      for (const v of Object.values(d)) if (checkDict(v)) return true
    }
    return Object.keys(selectedAccounts).filter((key) =>
      checkDict(selectedAccounts[key]),
    )
  }, [selectedAccounts])

  const activeFile =
    !activeEnrichment &&
    !activeCustomConfiguration &&
    fileStatus?.active &&
    localFiles.find((f) => f.filename === fileStatus?.active)
  const CurrentConfig =
    activeEnrichment || activeFile || activeCustomConfiguration
      ? null
      : CONFIGURE_ITEMS[step]
  const CurrentConfigCustomConfig = CONFIGURE_ITEMS[step]
  let [sources, setSources] = [[], () => {}]
  switch (step) {
    case 0:
      sources = mediaChannelDatasources
      setSources = setMediaChannelDatasources
      break
    case 1:
      sources = contextVariablesDatasources
      setSources = setContextVariablesDatasources
      break
    case 2:
      sources = kpiDatasources
      setSources = setKpiDatasources
      break
    default:
      break
  }

  const countMediaChannelsFile = useMemo(() => {
    let count = 0
    Object.values(fileStatus?.['selected']?.[0] ?? {}).forEach((v) =>
      Object.values(v).forEach((v) => v && count++),
    )
    return count
  }, [fileStatus])
  const contextVariablesUsed = useMemo(() => {
    let count = 0

    Object.keys(customConfigurations).forEach((k) => {
      if (custom_static_config_datasource.includes(k))
        Object.values(customConfigurations[k]).forEach((v) => {
          if (v?.contextVariables)
            Object.values(v.contextVariables).forEach((vv) => vv && count++)
        })
    })
    Object.entries(contextVariables).forEach(
      ([k, v]) =>
        disabledContextVariables?.[k] ||
        Object.values(v).forEach((vv) => vv && count++),
    )
    Object.values(fileStatus?.['selected']?.[1] ?? {}).forEach((v) =>
      Object.values(v).forEach((v) => v && count++),
    )
    Object.values(enrichmentDatasources).forEach((v) => {
      if (v?.contextVariables)
        Object.values(v.contextVariables).forEach((vv) => vv && count++)
    })
    return count
  }, [
    contextVariables,
    disabledContextVariables,
    fileStatus,
    customConfigurations,
    enrichmentDatasources,
  ])

  const KPIcount = useMemo(() => {
    let count = 0
    Object.keys(customConfigurations).forEach((k) => {
      if (custom_static_config_datasource.includes(k))
        Object.values(customConfigurations[k]).forEach((v) => {
          if (v?.kpis) Object.values(v.kpis).forEach((vv) => vv && count++)
        })
    })
    Object.values(kpis).forEach((v) =>
      Object.values(v).forEach((vv) => vv && count++),
    )
    Object.values(fileStatus?.['selected']?.[2] ?? {}).forEach((v) =>
      Object.values(v).forEach((v) => v && count++),
    )
    return count
  }, [kpis, fileStatus, customConfigurations])

  const adjustMetrics = (type, metric, datasource, all) => {
    switch (type) {
      case 'cost':
        if (datasource && metric) {
          if (contextVariables?.[datasource]?.[metric]) {
            contextVariables[datasource][metric] = false
            setContextVariables({ ...contextVariables })
          }
          if (kpis?.[datasource]?.[metric]) {
            kpis[datasource][metric] = false
            setKpis({ ...kpis })
          }
        }
        break
      case 'context':
        if (datasource && metric) {
          if (
            metric === datasources_cost_metric[datasource]?.[1] &&
            mediaChannelDatasources.includes(datasource)
          )
            setMediaChannelDatasources(
              mediaChannelDatasources.filter((d) => d !== datasource),
            )
          if (kpis?.[datasource]?.[metric]) {
            kpis[datasource][metric] = false
            setKpis({ ...kpis })
          }
        } else if (datasource && all) {
          Object.keys(all).forEach((metric) => {
            if (
              metric === datasources_cost_metric[datasource]?.[1] &&
              mediaChannelDatasources.includes(datasource)
            )
              setMediaChannelDatasources(
                mediaChannelDatasources.filter((d) => d !== datasource),
              )
            if (kpis?.[datasource]?.[metric]) {
              kpis[datasource][metric] = false
              setKpis({ ...kpis })
            }
          })
        }
        break
      case 'kpi':
        if (datasource && metric) {
          if (
            metric === datasources_cost_metric[datasource]?.[1] &&
            mediaChannelDatasources.includes(datasource)
          )
            setMediaChannelDatasources(
              mediaChannelDatasources.filter((d) => d !== datasource),
            )
          if (contextVariables?.[datasource]?.[metric]) {
            contextVariables[datasource][metric] = false
            setContextVariables({ ...contextVariables })
          }
        }
        break
      default:
        break
    }
  }

  return (
    <Row>
      <Col className="text-4xl text-center font-bold" xs={12}>
        {t('Classify Your Connected Data')}
      </Col>
      <Col className="text-2xl text-center font-bold mt-2 mb-1" xs={12}>
        {t('Assign your connected data sources to the appropriate categories')}
      </Col>
      <Col xs={12} className="mb-2 inline-flex  items-center justify-end">
        <span
          className="me-2 inline-flex items-center mt-1 cursor-pointer relative"
          title={t('Select a custom starting date ')}
        >
          <span className="absolute right-4">
            <span className="opacity-0">
              <DatePicker
                ref={calendarRef}
                value={
                  timeFrame?.value
                    ? dayjs(new Date(Date.now() - timeFrame?.value))
                    : dayjs(new Date())
                }
                onChange={(date) => {
                  setTimeFrame({
                    label: `${baseFormat(new Date(date))} ${t('to today')}`,
                    value: Date.now() - new Date(date).getTime(),
                  })
                }}
              />
            </span>
          </span>
          <FaCalendar
            size={30}
            onClick={() => {
              calendarRef.current?.querySelector('button')?.click()
            }}
          />
        </span>
        <span>
          <div className="min-w-56 text-base">
            <CustomSelect
              className="basic-single mt-2 w-100"
              classNamePrefix="select"
              isSearchable={true}
              placeholder={'Select...'}
              isClearable={true}
              onChange={(e) => setTimeFrame(e?.value)}
              options={TIME_OPTIONS.filter(
                (v) => localFiles?.length || v.value !== 1,
              )}
              value={
                TIME_OPTIONS.find((o) => o.value === timeFrame) ??
                (timeFrame ? timeFrame : null)
              }
            />
          </div>
        </span>
      </Col>
      <Col xs={12}>
        <Row className="justify-center">
          <Col xxl={3} md={12} xs={12} className="">
            <Row className="border-r min-h-full border-r-gray-500 px-3 ms-4">
              <Col
                className={`${step === 0 ? 'text-importbg bg-importpurple active-card' : 'text-importpurple hover:bg-importpurple50'} mt-3 mb-1 text-center text-xl border-config-type border-2 rounded-xl py-1 px-2 font-extrabold  flex flex-col flex-nowrap items-center justify-center cursor-pointer select-none duration-300 active:scale-95 card-inputs-config`}
                xs={12}
                onClick={() => setStep(0)}
              >
                <Image
                  src={step !== 0 ? mediaChannels : mediaChannelsDark}
                  width={80}
                />
                <div>{t('Media Channels')}</div>
              </Col>
              <Col className="text-xs text-white text-center mb-1" xs={12}>
                {(mediaChannelDatasources?.length ?? 0) +
                  countMediaChannelsFile}{' '}
                {t('media channels selected')}
              </Col>
              <Col
                className={`${step === 1 ? 'text-importbg bg-importpurple active-card' : 'text-importpurple hover:bg-importpurple50'} mt-3 mb-1 text-center text-xl border-config-type border-2 rounded-xl py-1 px-2 font-extrabold  flex flex-col flex-nowrap items-center justify-center cursor-pointer select-none duration-300 active:scale-95 card-inputs-config`}
                xs={12}
                onClick={() => setStep(1)}
              >
                <Image
                  src={step !== 1 ? contextVariablesp : contextVariablesDark}
                  width={80}
                />
                <div>{t('Context variables')}</div>
              </Col>
              <Col className="text-xs text-white text-center mb-1" xs={12}>
                {contextVariablesUsed} {t('context variables selected')}
              </Col>
              <Col
                className={`${step === 2 ? 'text-importbg bg-importpurple active-card' : 'text-importpurple hover:bg-importpurple50'} mt-3 mb-1 text-center text-xl border-config-type border-2 rounded-xl py-1 px-2 font-extrabold  flex flex-col flex-nowrap items-center justify-center cursor-pointer select-none duration-300 active:scale-95 card-inputs-config`}
                xs={12}
                onClick={() => setStep(2)}
              >
                <Image src={step !== 2 ? kpi : kpiDark} width={80} />
                <div>{t('KPI')}</div>
              </Col>
              <Col className="text-xs text-white text-center mb-1" xs={12}>
                {KPIcount} {t('/1 KPI selected')}
              </Col>
            </Row>
          </Col>
          {Number.isInteger(step) ? (
            <>
              <Col xxl={4} md={6} xs={6}>
                <Row className="min-h-full max-h-full overflow-auto border-r border-r-gray-500">
                  <Col xs={12}>
                    <DatasourcesAccountsConfig
                      connectors={connectors}
                      step={step}
                      datasourcesWithUsers={datasourcesWithUsers}
                      users={selectedAccounts}
                      nameRegister={nameRegister}
                      mediaChannelDatasources={sources}
                      setMediaChannelDatasources={setSources}
                      contextVariables={contextVariables}
                      kpis={kpis}
                      activeMediaChannel={activeMediaChannel}
                      setActiveMediaChannel={setActiveMediaChannel}
                      disabledContextVariables={disabledContextVariables}
                      setDisabledContextVariables={setDisabledContextVariables}
                      customConfigurations={customConfigurations}
                      localFiles={localFiles}
                      fileStatus={fileStatus}
                      setFileStatus={setFileStatus}
                      activeCustomConfiguration={activeCustomConfiguration}
                      setActiveCustomConfiguration={
                        setActiveCustomConfiguration
                      }
                      enrichmentDatasources={enrichmentDatasources}
                      setEnrichmentDatasources={setEnrichmentDatasources}
                      activeEnrichment={activeEnrichment}
                      setActiveEnrichment={setActiveEnrichment}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xxl={4} md={6} xs={6}>
                <Row className="min-h-full px-3 flex-col flex-nowrap">
                  <Col xs={12}>
                    {CurrentConfig && (
                      <CurrentConfig
                        step={step}
                        connectors={connectors}
                        timeDimension={timeDimension}
                        setTimeDimension={setTimeDimension}
                        mediaUserAccount={mediaUserAccount}
                        setMediaUserAccount={setMediaUserAccount}
                        mediaChannelDatasources={sources}
                        setMediaChannelDatasources={setSources}
                        mediaChannelMetrics={mediaChannelMetrics}
                        setMediaChannelmetrics={setMediaChannelmetrics}
                        contextVariables={contextVariables}
                        setContextVariables={setContextVariables}
                        kpis={kpis}
                        setKpis={setKpis}
                        metricsNameRegister={metricsNameRegister}
                        activeMediaChannel={activeMediaChannel}
                        setDisabledContextVariables={
                          setDisabledContextVariables
                        }
                        customConfigurations={customConfigurations}
                        setCustomConfigurations={setCustomConfigurations}
                        fileStatus={fileStatus}
                        setFileStatus={setFileStatus}
                        campaignAdAggregation={campaignAdAggregation}
                        setCampaignAdAggregation={setCampaignAdAggregation}
                        adjustMetrics={adjustMetrics}
                        selectedAccounts={selectedAccounts}
                        nameRegister={nameRegister}
                      />
                    )}
                    {activeFile && (
                      <ConfigureFiles
                        step={step}
                        fileStatus={fileStatus}
                        setFileStatus={setFileStatus}
                        file={activeFile}
                        kpis={kpis}
                        setKpis={setKpis}
                      />
                    )}
                    {!activeEnrichment &&
                      activeCustomConfiguration &&
                      CurrentConfigCustomConfig && (
                        <CurrentConfigCustomConfig
                          customItemPath={activeCustomConfiguration}
                          customItem={activeCustomItem}
                          step={step}
                          connectors={connectors}
                          timeDimension={timeDimension}
                          setTimeDimension={setTimeDimension}
                          mediaUserAccount={mediaUserAccount}
                          setMediaUserAccount={setMediaUserAccount}
                          mediaChannelDatasources={sources}
                          setMediaChannelDatasources={setSources}
                          mediaChannelMetrics={mediaChannelMetrics}
                          setMediaChannelmetrics={setMediaChannelmetrics}
                          contextVariables={contextVariables}
                          setContextVariables={setContextVariables}
                          kpis={kpis}
                          setKpis={setKpis}
                          metricsNameRegister={metricsNameRegister}
                          activeMediaChannel={activeMediaChannel}
                          setDisabledContextVariables={
                            setDisabledContextVariables
                          }
                          customConfigurations={customConfigurations}
                          setCustomConfigurations={setCustomConfigurations}
                          fileStatus={fileStatus}
                          setFileStatus={setFileStatus}
                          campaignAdAggregation={campaignAdAggregation}
                          setCampaignAdAggregation={setCampaignAdAggregation}
                          adjustMetrics={adjustMetrics}
                        />
                      )}
                    {activeEnrichment && (
                      <ConfigureEnrichment
                        activeEnrichment={activeEnrichment}
                        enrichmentDatasources={enrichmentDatasources}
                        setEnrichmentDatasources={setEnrichmentDatasources}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col xs={8} className="position-relative">
              <Row className="inline-flex flex-nowrap">
                <Col xs={'auto'} className="pe-0">
                  <Image
                    style={{ filter: 'blur(0.5px)' }}
                    className="max-h-56 max-w-full"
                    src={arrowWhite}
                  />
                </Col>
                <Col className="text-center flex items-center ps-0" xs={6}>
                  <span className="font-bold text-xl">
                    {t(
                      'Start classifying your data sources into the category of your choice.',
                    )}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}
