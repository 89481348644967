import { useEffect, useRef, useState } from 'react'
import { checkTask } from '../services/dataslayer'
import { useAuth } from '../providers/AuthProvider'

export default function UseTask() {
  const { token } = useAuth()
  const results = useRef({})
  const [activeWaitTasks, setActiveWaitTasks] = useState({})

  const registerTask = async ({
    task_id,
    callback,
    error_callback,
    period = 3000,
    max_retries = Infinity,
    errorMessage,
  }) => {
    let interval = null
    const doQuery = async () => {
      const result = await checkTask({ task_id, token })
      if (result.ok) {
        const data = await result.json()
        if (data.finished) {
          if (!data?.ok) {
            if (error_callback) {
              results.current[task_id] = data.result
              error_callback(data.result)
            } else {
              results.current[task_id] = data.result
              callback({
                status: 'error',
                error: data?.error ?? errorMessage ?? 'Error',
              })
            }
          } else {
            results.current[task_id] = data.result
            callback(data.result)
          }
          clearInterval(interval)
        }
      } else {
        const data = await result.json()
        error_callback(data?.error ?? errorMessage ?? 'Error')
        clearInterval(interval)
      }
    }
    interval = setInterval(doQuery, period)
    doQuery()
    setActiveWaitTasks((t) => ({
      ...t,
      [task_id]: {
        interval,
        callback,
        error_callback,
        retries: 0,
        max_retries,
      },
    }))
  }

  useEffect(
    () => () => {
      setActiveWaitTasks((t) => {
        if (t)
          Object.values(t).forEach(({ interval }) => {
            clearInterval(interval)
            if (t.error_callback)
              t.error_callback({
                status: 'error',
                message: 'Component unmonted',
              })
            else
              t?.callback?.({
                status: 'error',
                message: 'Component unmonted',
              })
          })
        return null
      })
    },
    [],
  )

  return {
    registerTask,
    getResult: (task_id) => results.current[task_id],
  }
}
