import React, { useMemo, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CiWarning } from 'react-icons/ci'
import CustomSelect from '../CustomSelect'

export default function ConfirmImport({
  shouldTweakFileParams,
  fileStatus,
  setFileStatus,
  file,
  onConfirm,
  onCancel,
}) {
  const { t } = useTranslation()
  const dateOptions = useMemo(() => {
    return (file?.columns ?? [])
      .filter((c) => c.type === 'datetime')
      .map((c) => ({ label: c.name, value: c.name }))
  }, [file])
  const selectedDatetime = fileStatus?.selectedDateCol?.[file?.filename]

  const dateFormatOptions = useMemo(() => {
    return (
      (file?.columns ?? []).find((c) => c.name === selectedDatetime?.value)
        ?.options?.datetime_options ?? []
    )
  }, [selectedDatetime])

  const selectedDatetimeOption =
    fileStatus?.selectedOption?.[file?.filename]?.[selectedDatetime?.value]

  useEffect(() => {
    if (
      !fileStatus?.selectedDateCol?.[file?.filename] &&
      dateOptions.length === 1
    ) {
      fileStatus['selectedDateCol'] = fileStatus['selectedDateCol'] ?? {}
      fileStatus['selectedDateCol'][file.filename] = dateOptions[0]
      setFileStatus({ ...fileStatus })
    }
  }, [dateOptions])

  return (
    <Row
      className={`min-h-[65vh] ${shouldTweakFileParams ? '!mt-[5vh] !mb-[10vh]' : '!mt-[15vh]'} flex-col justify-start items-center !mx-14`}
    >
      <Col className="text-center text-3xl font-bold mt-5 mb-4" xs={12}>
        {t("Are you sure you're done with your setup?")}
      </Col>
      {shouldTweakFileParams && file && (
        <Col className="flex justify-center" xs={12}>
          <Row className="max-w-[600px] mb-4">
            <Col xs={12}>
              <Row>
                <Col
                  className="text-sm mb-2 flex justify-center items-center flex-col"
                  xs={12}
                >
                  <span className="text-center">
                    {t(
                      'Select a valid date column and the correct date format for',
                    )}
                  </span>{' '}
                  <strong className="mt-1 text-base mb-2">{file?.name}</strong>
                </Col>
              </Row>
            </Col>
            <Col className="text-xs" xs={12}>
              <span className="ms-2">{t('DETECTED DATE COLUMN')}</span>
            </Col>
            <Col className="inline-flex items-center mb-2" xs={12}>
              <CustomSelect
                value={
                  dateOptions.length === 0
                    ? {
                        label: 'No date columns detected',
                        value: 'No date columns detected',
                      }
                    : selectedDatetime
                }
                className="basic-single mt-1 w-full"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={'Select a datetime column...'}
                isDisabled={dateOptions.length < 2}
                isClearable={false}
                onChange={(e) => {
                  fileStatus.selectedDateCol = fileStatus?.selectedDateCol ?? {}
                  fileStatus['selectedDateCol'][file.filename] =
                    fileStatus['selectedDateCol'][file.filename] || {}
                  fileStatus['selectedDateCol'][file.filename] = e
                  setFileStatus({ ...fileStatus })
                }}
                options={dateOptions}
              />
              {dateOptions?.length === 0 && (
                <CiWarning className="text-[#F45151]" size={35} />
              )}
            </Col>
            {dateFormatOptions?.length > 1 && (
              <>
                <Col className="text-xs" xs={12}>
                  <span className="ms-2">{t('DATE FORMAT')}</span>
                </Col>
                <Col className="inline-flex items-center mb-2" xs={12}>
                  <CustomSelect
                    value={selectedDatetimeOption}
                    className="basic-single mt-1 w-full"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder={'Select datetime format...'}
                    isClearable={false}
                    onChange={(e) => {
                      fileStatus?.selectedOption?.[file.filename]?.[
                        selectedDatetime?.value
                      ]
                      fileStatus.selectedOption =
                        fileStatus?.selectedOption || {}
                      fileStatus.selectedOption[file.filename] =
                        fileStatus.selectedOption[file.filename] || {}
                      fileStatus.selectedOption[file.filename][
                        selectedDatetime?.value
                      ] = e
                      setFileStatus({ ...fileStatus })
                    }}
                    options={dateFormatOptions.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      )}
      <Col className="text-center" xs={8}>
        {t('Please note that once you confirm')},{' '}
        <strong>
          {t('the selected GA4 account cannot be changed in the future')}
        </strong>
        . {t('Make sure everything is correctly configured before proceeding.')}
      </Col>
      <Col className="justify-center flex !mt-12" xs={12}>
        <Button
          className="me-2 !bg-transparent !px-12 !rounded-[30px] !border-[#92B8F1] !text-[#92B8F1] !font-bold"
          onClick={onCancel}
        >
          {t('BACK')}
        </Button>
        <Button
          className="ms-2 !bg-[#92B8F1] !px-12 !rounded-[30px] !text-[#23384F] !border-[#92B8F1] !font-bold"
          onClick={onConfirm}
          disabled={
            shouldTweakFileParams &&
            (!fileStatus?.['selectedDateCol']?.[file?.filename] ||
              !selectedDatetimeOption)
          }
        >
          {t('FINISH')}
        </Button>
      </Col>
    </Row>
  )
}
