import { Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaRegCheckCircle } from 'react-icons/fa'
import HelpTooltip from './HelpTooltip'
import { FaPlusMinus } from 'react-icons/fa6'

export default function PlanningCard({
  info,
  message = '',
  value = '',
  error,
  helpText = '',
}) {
  const { t } = useTranslation()
  return (
    <Card
      body
      className={`results-card bg-transparent border-1 border-dashed text-white p-0 ${info ? 'blue-border' : 'green-border'}`}
    >
      <Row>
        <Col
          xs={12}
          className={`${helpText ? '' : 'pointer-events-none opacity-0'}`}
        >
          <HelpTooltip message={helpText} />
        </Col>
        <Col xs={12}>
          <h2 className="text-3xl font-bold mb-0">{value}</h2>
        </Col>
        <p className="text-base m-0 inline-flex items-center">
          <FaPlusMinus /> {error}
        </p>
        <Col xs={12}>
          <strong className="text-base m-0">{message}</strong>
        </Col>
        <Col xs={12}>
          <span className="text-xs m-0 gap-2 flex">
            {t('With 95% confidence')} <FaRegCheckCircle />
          </span>
        </Col>
      </Row>
    </Card>
  )
}
