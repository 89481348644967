import React, { useCallback, useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Modal, Form, Card, Button } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { FaUpload, FaArrowLeft } from 'react-icons/fa'
import { updateModel, uploadModel } from '../services/model'
import { formatDateddmmyy } from '../utility/format'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'

const ModalUpload = ({ show, onHide, refetch, onEditModel, currentModel }) => {
  const { t } = useTranslation()
  const [modelName, setModelName] = useState('')
  const { token } = useAuth()

  useEffect(() => {
    if (currentModel) {
      setModelName(currentModel.name)
    } else {
      const defaultModelName = `new model - ${formatDateddmmyy(new Date())}`
      setModelName(defaultModelName)
    }
  }, [currentModel])

  const mutation = useMutation(
    ({ formData, token }) =>
      onEditModel
        ? updateModel({ modelId: currentModel.id, modelName: modelName, token })
        : uploadModel({ model: formData, modelName: modelName, token }),
    {
      onSuccess: () => {
        onHide()
        refetch()
      },
    },
  )

  const handleSaveChanges = () => {
    if (onEditModel) {
      mutation.mutate({ formData: null, token })
    }
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData()
      acceptedFiles.forEach((file) => {
        formData.append('file', file)
      })

      mutation.mutate({ formData, token })
    },
    [modelName, mutation],
  )

  const acceptedFileTypes = {
    'application/zip': ['.zip'],
    'text/csv': ['.csv'],
    'text/plain': ['.txt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xlsb'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/vnd.oasis.opendocument.graphics': ['.odg'],
    'application/x-spss-sav': ['.sav'],
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  })
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => onHide(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {onEditModel ? t('Edit model') : t('Create model')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Model Name"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
        />

        {!onEditModel && (
          <div>
            <Card className="text-center p-4 my-4 upload-card">
              <div
                {...getRootProps({
                  className: 'dropzone flex flex-col items-center',
                })}
              >
                <input {...getInputProps()} />
                <p>{t('Drag and drop')}</p>
                <FaUpload size={60} className="mb-3" />
                <Button className="bg-transparent">{t('Upload file')}</Button>
              </div>
              <small className="text-xs">
                {t('Allowed formats')}: csv, zip, txt, sav, xls, xlsx, xlsm,
                xlsb, odf,ods, odt
              </small>
            </Card>
            <div className="text-center">
              <small className="text-sm">
                {t('Privacy concern')}{' '}
                <a className="no-underline" href="#">
                  {t('here')}
                </a>{' '}
                {t('first')}.
              </small>
            </div>
          </div>
        )}
        {onEditModel && (
          <Button
            className="save-button my-2 py-1 pe-2 float-right"
            onClick={handleSaveChanges}
          >
            Save changes
          </Button>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpload
