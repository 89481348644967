import { useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { getModel } from '../services/model'

import BreadcrubsModel from './BreadcrumbsModel'
import ViewData from './ViewData'
import Loader from './Loader'
import ModelInsights from './ModelInsights'
import Optimization from './Optimization'
import ModelPlanning from './ModelPlanning'
import { useAuth } from '../providers/AuthProvider'

const STEP_COMPONENTS = [ViewData, ModelInsights, Optimization, ModelPlanning]

export default function ModelContent({ pushHeader, popHeader, ...props }) {
  const { token } = useAuth()
  const queryClient = useQueryClient()
  const { modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeStep = Number.parseInt(searchParams.get('step')) || 0
  const setActiveStep = (step) => {
    searchParams.set('step', step)
    setSearchParams(searchParams)
  }
  const Component = STEP_COMPONENTS?.[activeStep] ?? STEP_COMPONENTS[0]

  const { data: model } = useQuery(
    ['model', modelId],
    async () => {
      const response = await getModel({ modelId, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const model = await response.json()
      return model
    },
    { staleTime: Infinity },
  )
  window.model = model
  useEffect(() => {
    pushHeader('MODEL CONFIGURATION')
    return () => popHeader()
  }, [])

  useEffect(() => {
    if (model?.status === 'training' || model?.status === 'optimizing') {
      const to = setTimeout(() => {
        queryClient.invalidateQueries(['model', modelId])
      }, 5000)
      return () => clearTimeout(to)
    }
  }, [model])

  const prevState = useRef('')
  useEffect(() => {
    const transition = `${prevState?.current ?? ''}_${model?.status ?? ''}`
    prevState.current = model?.status ?? ''
    switch (transition) {
      case 'training_trained':
        queryClient.invalidateQueries(['mmm-optimized-table-spend', model?.id])
        queryClient.invalidateQueries(['mmm-model-statistics', model?.id])
        queryClient.invalidateQueries(['mediaContribution-lines', model?.id])
        queryClient.invalidateQueries(['mmm-funnel-effect', model?.id])
        queryClient.invalidateQueries(['mmm-total-funnel-effect', model?.id])
        queryClient.invalidateQueries(['lag-carryover', model.id])
        queryClient.invalidateQueries(['MMMDynamicSpend', model.id])
        queryClient.invalidateQueries(['MMMInfluence', model.id])
        queryClient.invalidateQueries(['mediaContribution', model.id])
        queryClient.invalidateQueries(['saturation-curves', model.id])
        queryClient.invalidateQueries(['model-optimization-table', model.id])
        queryClient.invalidateQueries([
          'model-budget-optimization-original',
          model.id,
        ])
        break
      case 'optimizing_trained':
      case 'optimizing_created':
      case 'optimizing_error':
        queryClient.invalidateQueries(['budget-optimize', model?.id])
        queryClient.invalidateQueries(['outcome-optimize', model?.id])
        queryClient.invalidateQueries([
          'mmm-model-optimization-outcome',
          model?.id,
        ])
        queryClient.invalidateQueries(['model-optimization-table', model.id])
        queryClient.invalidateQueries()
        queryClient.invalidateQueries()
        break
      default:
        break
    }
  }, [model])

  return (
    <Row>
      <Col className="mb-4" xs={12}>
        <BreadcrubsModel
          activeStep={activeStep}
          onChange={setActiveStep}
          model={model}
        />
      </Col>
      <Col xs={12}>
        {model ? <Component model={model} {...props} /> : <Loader />}
      </Col>
    </Row>
  )
}
