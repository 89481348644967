import React, { useMemo, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../../CustomSelect'
import ButtonMMM from '../../ButtonMMM'
import { toast } from 'react-toastify'

const PAGE_POST_OPTIONS = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '250', label: '250' },
]

export default function FacebookPublicData({ config = {}, setConfig }) {
  const { t } = useTranslation()
  const [localConfig, setLocalConfig] = useState(config || { kwargs: {} })

  const options = useMemo(() => {
    const options = [
      // { label: 'Page data', value: 'page' },
      { label: 'Posts per page', value: 'page_posts' },
    ]
    return options
  }, [t])

  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Query type')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.call_type = e.value
            setLocalConfig({ ...localConfig })
          }}
          options={options}
          value={options.find(
            (o) => o.value === localConfig?.kwargs?.call_type,
          )}
        />
      </Col>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Page aliases/IDs')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={3}
          defaultValue={localConfig?.kwargs?.page_ids ?? ''}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.page_ids = e.target.value
          }}
          placeholder={t(
            'To search for multiple terms, you must split by commas ( , )',
          )}
        />
      </Col>
      <Col
        xs={12}
        className={`${localConfig?.kwargs?.call_type === 'page_posts' ? '' : 'hidden'} mb-1 `}
      >
        <span className="ps-2">{t('# of posts per page')}</span>
      </Col>
      <Col
        xs={12}
        className={`${localConfig?.kwargs?.call_type === 'page_posts' ? 'min-h-36' : 'hidden'} mb-3`}
      >
        <CustomSelect
          className="basic-single mt-2 w-full"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={false}
          onChange={(e) => {
            localConfig.kwargs = localConfig.kwargs || {}
            localConfig.kwargs.number_of_posts = e.value
            setLocalConfig({ ...localConfig })
          }}
          options={PAGE_POST_OPTIONS}
          value={PAGE_POST_OPTIONS.find(
            (o) => o.value === localConfig?.kwargs?.number_of_posts,
          )}
        />
      </Col>
      <Col
        className="flex justify-end"
        xs={12}
        onClick={() => {
          if (
            localConfig?.kwargs?.call_type === 'page' &&
            localConfig?.kwargs?.number_of_posts
          )
            delete localConfig?.kwargs?.number_of_posts

          if (
            localConfig?.kwargs?.call_type &&
            localConfig?.kwargs?.page_ids &&
            (localConfig?.kwargs?.call_type === 'page' ||
              localConfig?.kwargs?.number_of_posts)
          ) {
            setConfig({
              kwargs: localConfig.kwargs,
              static: true,
            })
          } else
            toast.error(
              t(
                'Please fill all the fields in the Facebook public data configuration form',
              ),
            )
        }}
      >
        <ButtonMMM>{t('Save')}</ButtonMMM>
      </Col>
    </Row>
  )
}
