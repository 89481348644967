import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import { getMMMOptimizedTable } from '../services/model'
import { useQuery } from 'react-query'
import { generateMMMColorMap, nivoProps } from '../utility/model'
import { readableNumber, round } from '../utility/format'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowDown } from 'react-icons/md'
import Loader from './Loader'
import DownloadGraphOverlay from './mmm-graphs/DownloadGraphOverlay'
import { useAuth } from '../providers/AuthProvider'
import { placehoderOptimization } from './mmm-graphs/placeholders'
import UpgradeToPro from './mmm-graphs/UpgradeToPro'

export default function ChannelSpendChange({
  model,
  isInView = true,
  ...props
}) {
  const { token, isEssential } = useAuth()
  const requirePro = isEssential
  const { t } = useTranslation()
  const [usePercent, setUsePercent] = useState(true)

  const {
    data: _data,
    isLoading,
    isSuccess,
  } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      const response = await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: false,
        original: true,
        token,
      })
      if (response?.ok) return await response.json()
    },
    { staleTime: Infinity },
  )
  const data = requirePro ? placehoderOptimization : _data
  const datasets = useMemo(() => {
    if (data) {
      const colorMap = generateMMMColorMap(model)
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const datasets = data.data.reduce((ac, d) => {
        ac[d[keymap['index']]] = {
          spend: d[keymap['Media spend']],
          recommended: d[keymap['Recommended spend']],
          color: colorMap[d[keymap['index']]],
        }
        return ac
      }, {})

      const { total, totalRecommended } = data.data.reduce(
        (acc, d) => {
          acc.total += d[keymap['Media spend']]
          acc.totalRecommended += d[keymap['Recommended spend']]
          return acc
        },
        { total: 0, totalRecommended: 0 },
      )
      Object.entries(datasets).forEach(([k, v]) => {
        v.spendPerc = (100 * v.spend) / total
        v.recommendedPerc = (100 * v.recommended) / totalRecommended
      })
      return datasets
    }

    return null
    // eslint-disable-next-line
  }, [data])

  const pieData = datasets
    ? Object.entries(datasets).map(([k, v]) => {
        return [
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.spendPerc : v.spend,
          },
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.recommendedPerc : v.recommended,
          },
        ]
      })
    : null
  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess && isInView) {
      setShownData(
        pieData?.map((d) => [
          { ...d[0], value: 0 },
          { ...d[1], value: 0 },
        ]),
      )
      setTimeout(() => setShownData(pieData), 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, datasets])

  const csvData = pieData
    ? [
        ['channel', 'original', 'potimized'],
        ...pieData.map((d) => [d[0].id, d[0].value, d[1].value]),
      ]
    : null

  if (isLoading || !shownData) {
    return (
      <Row style={{ minHeight: '100%' }}>
        <Col xs={12} className="insight-model-content">
          <Loader />
        </Col>
      </Row>
    )
  }

  return (
    <div className="position-relative">
      <DownloadGraphOverlay
        disabled={requirePro}
        buttonsStyle={{ top: '5px', right: '15px', zIndex: '2' }}
      >
        <Row
          className={`h-100 relative justify-content-between pt-0 d-xl-flex d-md-none`}
        >
          {requirePro && <UpgradeToPro showSampleReport={true} />}
          <Col
            xs={12}
            className="data-holder insight-model-content"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`scenario optimization__${model.id}`}
          >
            <Row className="mx-2">
              <Col xs={6}>
                <Row className="outline-main rounded-xl py-3 me-2">
                  <Col xs={12}>
                    <strong>{t('Current media mix')}</strong>
                  </Col>
                  <Col
                    xs={12}
                    style={{ minHeight: '300px', maxHeight: '300px' }}
                    className={`relative ${requirePro ? 'blur-under' : ''}`}
                  >
                    <ResponsivePie
                      {...nivoProps}
                      data={shownData.map((d) => d[0])}
                      margin={{ top: 30, right: 80, bottom: 30, left: 80 }}
                      innerRadius={0.3}
                      colors={(d) => d.data.color}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      valueFormat={(n) =>
                        usePercent
                          ? `${round(n, 1)}%`
                          : `${readableNumber(round(n, 0))}`
                      }
                      borderWidth={1}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                      }}
                      arcLinkLabelsThickness={1}
                      arcLinkLabelsDiagonalLength={5}
                      arcLinkLabelsColor={'white'}
                      arcLabelsSkipAngle={30}
                      arcLinkLabelsSkipAngle={30}
                      arcLabelsTextColor={'white'}
                      defs={[]}
                      fill={[]}
                      legends={[]}
                      layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
                      {...props}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row className="outline-main rounded-xl py-3 ms-2">
                  <Col xs={12}>
                    <strong>{t('Optimized Media Mix')}</strong>
                  </Col>
                  <Col
                    xs={12}
                    style={{ minHeight: '300px', maxHeight: '300px' }}
                    className={`relative ${requirePro ? 'blur-under' : ''}`}
                  >
                    <ResponsivePie
                      {...nivoProps}
                      data={shownData.map((d) => d[1])}
                      margin={{ top: 30, right: 80, bottom: 30, left: 80 }}
                      innerRadius={0.3}
                      valueFormat={(n) =>
                        usePercent
                          ? `${round(n, 1)}%`
                          : `${readableNumber(round(n, 0))}`
                      }
                      colors={(d) => d.data.color}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                      }}
                      arcLinkLabelsThickness={1}
                      arcLinkLabelsDiagonalLength={5}
                      arcLinkLabelsColor={'white'}
                      arcLabelsSkipAngle={30}
                      arcLinkLabelsSkipAngle={30}
                      arcLabelsTextColor={'white'}
                      defs={[]}
                      fill={[]}
                      legends={[]}
                      layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
                      {...props}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="h-100 position-relative justify-content-between pt-3 d-xl-none d-md-flex">
          <Col
            xs={12}
            className="data-holder insight-model-content"
            data-csv={encodeURIComponent(JSON.stringify(csvData))}
            data-filename={`scenario optimization__${model.id}`}
          >
            <Row>
              <Col xs={12} {...props} className={`  ${props.className ?? ''}`}>
                <Row className="px-2 pt-1">
                  <Col
                    className="h4 bold"
                    style={{ maxWidth: 'calc(100% - 50px)' }}
                    xs={'auto'}
                  >
                    <strong>{t('Current media mix')}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={`relative ${requirePro ? 'blur-under' : ''}`}>
              <Col style={{ minHeight: '400px', maxHeight: '400px' }} xs={12}>
                <ResponsivePie
                  {...nivoProps}
                  data={shownData.map((d) => d[0])}
                  margin={{ top: 30, right: 50, bottom: 30, left: 50 }}
                  innerRadius={0.3}
                  colors={(d) => d.data.color}
                  padAngle={0.7}
                  cornerRadius={0}
                  activeOuterRadiusOffset={8}
                  valueFormat={(n) =>
                    usePercent
                      ? `${round(n, 1)}%`
                      : `${readableNumber(round(n, 0))}`
                  }
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  arcLinkLabelsThickness={1}
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsColor={'white'}
                  arcLabelsSkipAngle={30}
                  arcLinkLabelsSkipAngle={30}
                  arcLabelsTextColor={'white'}
                  defs={[]}
                  fill={[]}
                  legends={[]}
                  layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
                  {...props}
                />
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            style={{ maxHeight: '70px', overflow: 'hidden' }}
            xs={12}
          >
            <MdKeyboardArrowDown size={110} />
          </Col>
          <Col
            className="insight-model-content"
            xs={12}
            style={{ minHeight: '300px' }}
          >
            <Row>
              <Col xs={12} {...props} className={`  ${props.className ?? ''}`}>
                <Row className="px-2 pt-1">
                  <Col
                    className="h4 bold"
                    style={{ maxWidth: 'calc(100% - 50px)' }}
                    xs={'auto'}
                  >
                    <strong>{t('Optimized media mix')}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={`relative ${requirePro ? 'blur-under' : ''}`}>
              <Col style={{ minHeight: '400px', maxHeight: '400px' }} xs={12}>
                <ResponsivePie
                  {...nivoProps}
                  data={shownData.map((d) => d[1])}
                  margin={{ top: 30, right: 180, bottom: 30, left: 180 }}
                  innerRadius={0.3}
                  valueFormat={(n) =>
                    usePercent
                      ? `${round(n, 1)}%`
                      : `${readableNumber(round(n, 0))}`
                  }
                  colors={(d) => d.data.color}
                  padAngle={0.7}
                  cornerRadius={0}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  arcLinkLabelsThickness={1}
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={30}
                  arcLinkLabelsSkipAngle={30}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  defs={[]}
                  fill={[]}
                  legends={[]}
                  layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
                  {...props}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DownloadGraphOverlay>
      <Form.Check
        type="switch"
        label={t('Percentages')}
        checked={usePercent}
        className="form-switch-share position-absolute original button-flip mt-2 d-none"
        onChange={async (e) => {
          setUsePercent(e.target.checked)
        }}
      />
    </div>
  )
}
