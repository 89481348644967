import React from 'react'
import { Card } from 'react-bootstrap'
import HelpTooltip from './HelpTooltip'
import { useAuth } from '../providers/AuthProvider'
import proCrown from '../assets/images/pro_crown.svg'

const InsightsGraphCard = ({
  icon,
  name,
  isActive,
  onClick,
  helpText,
  requirePro,
}) => {
  const { isEssential } = useAuth()

  return (
    <Card
      className={`graph-card text-white  ${isActive ? 'active-card' : ''} relative overflow-hidden`}
      onClick={onClick}
    >
      {isEssential && requirePro && (
        <>
          <div className="absolute w-full h-full left-0 top-0 bg-[#222] z-10 opacity-70 pointer-events-none"></div>
          <div className="absolute right-[10px] top-[10px]">
            <img src={proCrown} className="w-[20px] h-[20px]" />
          </div>
        </>
      )}
      <Card.Body>
        <Card.Title>
          <div style={{ fontSize: '2rem' }}>{icon}</div>
        </Card.Title>
        <Card.Subtitle className="graph-card-subtitle">{name}</Card.Subtitle>
        {/* <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <Dropdown>
            <Dropdown.Toggle as="div" className="card-insight-toggle">
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item href="#/action-1">Download as csv</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Download as PNG</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}

        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          <HelpTooltip message={helpText} className="ms-2 mt-1" color="white" />
        </div>
      </Card.Body>
    </Card>
  )
}

export default InsightsGraphCard
