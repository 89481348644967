import React from 'react'
import { FaCircleQuestion } from 'react-icons/fa6'
import { toast } from 'react-toastify'

const ConfirmToast = ({ onConfirm, onCancel, message }) => (
  <div id="confirm-toast">
    <div className="flex items-center gap-3">
      <span>
        <FaCircleQuestion size={20} />
      </span>
      <span>{message}</span>
    </div>
    <div className="mt-2 flex justify-end">
      <button
        onClick={() => {
          onCancel()
        }}
        className="toast-cancel-button rounded-3xl mr-2 px-3 py-1 cursor-pointer"
      >
        No
      </button>
      <button
        onClick={() => {
          onConfirm()
        }}
        className="toast-confirm-button rounded-3xl mr-2 px-3 py-1 cursor-pointer"
      >
        Yes
      </button>
    </div>
  </div>
)

export const showConfirmToast = (onConfirm, onCancel, message) => {
  toast(
    <ConfirmToast
      onConfirm={onConfirm}
      onCancel={onCancel}
      message={message}
    />,
    { closeButton: false, autoClose: false, className: 'toastify-container' },
  )
}
