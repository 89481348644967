import { config } from '../Constants'

export async function fetchAPI({ endpoint, token, ...props }) {
  const res = await fetch(`${config.API_URL}/${endpoint}`, {
    ...props,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(props?.headers ?? {}),
    },
  })
  if (res.status === 426) {
    window.location.reload()
  }
  return res
}
