import { useMemo, useEffect } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useQuery, useQueryClient } from 'react-query'
import { Row, Col } from 'react-bootstrap'
import { generateMMMColorMap } from '../../utility/model'
import { useTranslation } from 'react-i18next'

import { nivoProps } from '../../utility/model'
import { useAuth } from '../../providers/AuthProvider'
import Loader from '../Loader'
import { getMMMOptimizedTable } from '../../services/model'

const CustomAxisTick = ({ x, y, value }) => {
  // Custom rendering logic here
  // For example, you can add custom styling or other elements to the tick
  const elipsis = value?.length > 18 + 2

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={elipsis ? 0 : 27 - value.length}
        y={0}
        dy={0}
        textAnchor="middle"
        fill="white" // Customize the text color here
        fontSize={11} // Customize the font size here
        transform="translate(-62 40) rotate(-22)" // Optionally rotate the tick text
      >
        {elipsis ? value.slice(0, 20) + '...' : value}
      </text>
    </g>
  )
}

export default function MediaContributionBars({
  model,
  rawGraph = false,
  isInView = true,
  ...props
}) {
  const queryClient = useQueryClient()
  const { token } = useAuth()
  const { t } = useTranslation()
  const {
    data: tableData,
    isLoading,
    isSuccess,
  } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      let data = null
      try {
        const response = await getMMMOptimizedTable({
          modelId: model.id,
          is_outcome_optimization: false,
          weekly: false,
          original: true,
          token,
        })
        if (response.ok) data = await response.json()
      } catch (e) {
        console.info('Error retrieving mmm optimized table spend')
      }
      return data
    },
    { staleTime: Infinity },
  )
  const data = tableData?.data
  const colorMap = useMemo(() => generateMMMColorMap(model), [model])
  const adjustedData = useMemo(() => {
    if (!data) return []
    data?.sort((a, b) => b[2] - a[2])
    return data ?? []
  }, [data])

  useEffect(() => {
    if (!data && model) {
      const to = setInterval(() => {
        queryClient.invalidateQueries(['mmm-optimized-table-spend', model.id])
      }, 1000)
      return () => clearInterval(to)
    }
  }, [data])

  if (!data)
    return (
      <Row>
        <Col xs={12}>
          <Loader />
        </Col>
      </Row>
    )

  const finalData = isInView
    ? adjustedData.map((e) => ({
        media: e[0],
        contribution: e[2],
        color: colorMap?.[e[0]],
      }))
    : adjustedData.map((e) => ({
        media: e[0],
        contribution: 0,
        color: colorMap?.[e[0]],
      }))

  const barGraph = (
    <ResponsiveBar
      {...nivoProps}
      data={finalData}
      colors={(d) => d.data.color}
      indexBy="media"
      keys={['contribution']}
      enableGridY={false}
      enableGridX={false}
      margin={{ top: 40, right: 30, bottom: 70, left: 100 }}
      padding={0.3}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      label={(_) => <></>}
      axisBottom={{
        orient: 'bottom',
        tickSize: 3,
        tickPadding: 5,
        legendOffset: 90,
        legendPosition: 'middle',
        tickRotation: -22,
        legend: t(`Media channels`),
        renderTick: CustomAxisTick,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -60,
        legendPosition: 'middle',
        legend: `Contribution %`,
      }}
      valueFormat=">-.2f"
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="black"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        ({ bars, labelSkipWidth }) => {
          return (
            <g>
              {bars.map((props, index) => {
                const { width, x, y, data } = props
                return (
                  <text
                    key={`${data.value}_${index}`}
                    transform={`translate(${x + width / 2 - 20}, ${y - 20})`}
                    fontSize={12}
                    textAnchor="left"
                    dominantBaseline="central"
                    fontWeight="bold"
                    fill="var(--mmm-white-color)"
                  >
                    {`${data.formattedValue ?? ''}%`}
                  </text>
                )
              })}
            </g>
          )
        },
      ]}
    />
  )

  if (rawGraph) return barGraph

  return (
    <Row
      {...props}
      className={`media-contribution-bars ${props.className ?? ''} data-holder`}
      data-csv={encodeURIComponent(
        JSON.stringify([
          ['channel', 'contribution'],
          ...data.map((d) => [d[0], d[2]]),
        ]),
      )}
      data-filename={`media_effects_average__${model.id}`}
    >
      <Col xs={12}>{barGraph}</Col>
    </Row>
  )
}
