import React, { useMemo, useRef } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonMMM from '../../ButtonMMM'
import { toast } from 'react-toastify'

export default function Database({ config, setConfig }) {
  const { t } = useTranslation()
  const ref = useRef()
  return (
    <Row className="my-2" onClick={(e) => e.stopPropagation()}>
      <Col xs={12} className="mb-1 ps-2">
        <span className="ps-2">{t('Query SQL')}</span>
      </Col>
      <Col xs={12} className="mb-3">
        <Form.Control
          className="text-input-import dark:text-white text-white"
          type="textarea"
          as="textarea"
          rows={1}
          placeholder={t('Query your database...')}
          defaultValue={config?.kwargs?.query}
          ref={ref}
        />
      </Col>
      <Col
        className="flex justify-end"
        xs={12}
        onClick={() => {
          if (ref.current.value) {
            if (config.associatedTag) {
              config.previousAssociatedTag = config.associatedTag
              delete config.associatedTag
            }
            setConfig({
              ...config,
              kwargs: {
                ...config.kwargs,
                query: ref.current.value,
              },
            })
          } else {
            toast.error('Query cannot be empty')
          }
        }}
      >
        <ButtonMMM>{t('Save')}</ButtonMMM>
      </Col>
    </Row>
  )
}
