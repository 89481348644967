import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { GiConfirmed } from 'react-icons/gi'
import { FaPlusMinus } from 'react-icons/fa6'

import {
  getMMMOptimizedTable,
  getMMMOutcomeBudgetOptimization,
  getMMMStatistics,
  getModeltable,
} from '../../services/model'
import { generateMMMColorMap } from '../../utility/model'
import { readableNumber, readableNumberMMM, round } from '../../utility/format'
import Loader from '../Loader'
import { useAuth } from '../../providers/AuthProvider'
import HelpTooltip from '../HelpTooltip'
import { placeholderTable } from './placeholders'
import { toast } from 'react-toastify'

function calculateMediaContributionTotal(table) {
  try {
    const indexMediaContribution = table.columns.indexOf('Media contribution')
    const contributionPerc =
      table.data
        .map((r) => r[indexMediaContribution])
        .reduce((a, b) => a + b, 0) / 100
    if (Number.isNaN(contributionPerc)) return 1
    return contributionPerc
  } catch (e) {
    return 1
  }
}

export default function MMMPerformance({ model, ...props }) {
  const queryClient = useQueryClient()
  const { token, isEssential } = useAuth()
  const requirePro = isEssential
  const { t } = useTranslation()
  const [usePercent] = useState(true)

  const budgetOptimization = useQuery(
    ['model-budget-optimization-original', model.id],
    async () => {
      if (requirePro) return { outcome: 0 }
      const res = await getMMMOutcomeBudgetOptimization({
        modelId: model.id,
        original: true,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )
  const { data: statistics } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
      })
      if (!response?.ok) toast.error(t('Failed to retrieve original forecast'))
      return await response.json()
    },
    { staleTime: Infinity },
  )

  const { data, isLoading, isSuccess } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      const res = await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: false,
        original: true,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )

  const { data: _table } = useQuery(
    ['model-optimization-table', model.id],
    async () => {
      const res = await getModeltable({
        modelId: model.id,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )
  const table = requirePro ? placeholderTable : _table
  const testAccuracy = () => {
    return statistics?.accuracy ?? 0
  }

  const contributionPerc = calculateMediaContributionTotal(data)

  const outcome =
    budgetOptimization.isLoading || isLoading
      ? 0
      : budgetOptimization.data?.outcome * contributionPerc

  const acc = testAccuracy(model)
  let accuracy = 0.95
  if (!isNaN(acc)) accuracy = acc / 100

  const { increaseOutcome, newCpa } = useMemo(() => {
    if (table) {
      const header = table.columns
      const mediaSpendIndex = header.indexOf('Media spend')
      const mediaOutcomeIndex = header.indexOf('Media outcome')
      let totalMediaSpend = 0
      let totalMediaOutcome = 0
      table.data.forEach((row) =>
        row.forEach((cell, index) => {
          const value = isNaN(cell) ? cell : round(cell, 2)
          switch (index) {
            case mediaSpendIndex:
              totalMediaSpend += value
              break
            case mediaOutcomeIndex:
              totalMediaOutcome += value
              break
            default:
              break
          }
        }),
      )
      const increaseOutcome = round(100 * (outcome / totalMediaOutcome - 1), 2)
      const prevCpa = totalMediaSpend / totalMediaOutcome
      const newCpa = totalMediaSpend / outcome

      return {
        totalMediaOutcome,
        increaseOutcome,
        prevCpa,
        newCpa,
        improveCpa: 100 * (1 - newCpa / prevCpa),
      }
    }
    return {}
    // eslint-disable-next-line
  }, [outcome])

  const datasets = useMemo(() => {
    if (data) {
      const colorMap = generateMMMColorMap(model)
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const datasets = data.data.reduce((ac, d) => {
        ac[d[keymap['index']]] = {
          spend: d[keymap['Media spend']],
          recommended: d[keymap['Recommended spend']],
          color: colorMap[d[keymap['index']]],
        }
        return ac
      }, {})

      const { total, totalRecommended } = data.data.reduce(
        (acc, d) => {
          acc.total += d[keymap['Media spend']]
          acc.totalRecommended += d[keymap['Recommended spend']]
          return acc
        },
        { total: 0, totalRecommended: 0 },
      )
      Object.entries(datasets).forEach(([k, v]) => {
        v.spendPerc = (100 * v.spend) / total
        v.recommendedPerc = (100 * v.recommended) / totalRecommended
      })
      return datasets
    }

    return null
    // eslint-disable-next-line
  }, [data])

  const pieData = datasets
    ? Object.entries(datasets).map(([k, v]) => {
        return [
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.spendPerc : v.spend,
          },
          {
            id: k,
            color: v.color,
            label: k,
            value: usePercent ? v.recommendedPerc : v.recommended,
          },
        ]
      })
    : null
  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess) {
      setShownData(
        pieData?.map((d) => [
          { ...d[0], value: 0 },
          { ...d[1], value: 0 },
        ]),
      )
      setTimeout(() => setShownData(pieData), 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, datasets])

  useEffect(() => {
    if (
      isSuccess &&
      !budgetOptimization.isLoading &&
      !budgetOptimization?.data
    ) {
      const timeout = setTimeout(() => {
        queryClient.invalidateQueries([
          'model-budget-optimization-original',
          model.id,
        ])
      }, 3000)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [isSuccess, budgetOptimization])

  const [mediaOutcome, ROI] = useMemo(() => {
    if (table) {
      const mes = table.columns.indexOf('Media spend'),
        moi = table.columns.indexOf('Media outcome'),
        cpai = table.columns.indexOf('CPA')

      const valid = [mes, moi, cpai].reduce((ac, i) => ac && i !== -1, true)
      if (valid) {
        const totalSpend = table.data.reduce((ac, row) => ac + row[mes], 0)
        const totalOutcome = table.data.reduce((ac, row) => ac + row[moi], 0)
        const totalCPA = totalSpend / totalOutcome
        return [totalOutcome, 1 / totalCPA]
      }
    }
    return [0, 0]
  }, [table])

  if (isLoading || !shownData || !budgetOptimization?.data) return <Loader />

  let optimizeOutcome = outcome
  let optimizedROI = 1 / newCpa
  let optimizeIncreaseOutcome = increaseOutcome

  if (optimizeOutcome < mediaOutcome) {
    optimizeOutcome = mediaOutcome
    optimizedROI = ROI
    optimizeIncreaseOutcome = 0
  }

  let mediaSpendContributionTotal = 0
  try {
    const ci = table.columns.indexOf('Media contribution')
    const contribution = table.data.map((r) => r[ci]).reduce((a, b) => a + b, 0)
    mediaSpendContributionTotal = round(contribution, 0)
  } catch (e) {}

  return (
    <Row className="w-100 justify-content-md-start justify-content-sm-center relative">
      <Col
        className="min-h-full inline-flex flex-col justify-center border-dashed border-2 rounded-xl py-2 mb-xl-0 mb-4"
        xl={2}
        md={12}
        xs={12}
      >
        <HelpTooltip
          message={t('Percentage of contribution help')}
          className="ms-2 mt-1"
        />
        <h1 className={`px-xl-3 ${requirePro ? 'blur-under-mh' : ''}`}>
          <span>{mediaSpendContributionTotal}%</span>
        </h1>
        <span className="px-3">
          {t('Percentage of contribution from media')}
        </span>
      </Col>
      <Col xs={12} sm={12} md={9}>
        <Row
          className="h-100 ms-3"
          style={{ borderLeft: `1px solid var(--mmm-dataslayer-blue)` }}
        >
          <Col
            className="ps-0"
            xs={12}
            style={{ borderBottom: `1px solid var(--mmm-dataslayer-blue)` }}
          >
            <Row className="ms-3 mb-4">
              <Col className="mb-2" xs={12}>
                {t('Media KPI')}
              </Col>
              <Col
                xxl={3}
                md={6}
                sm={12}
                xs={12}
                className="mb-xl-0 mb-md-0 mb-sm-2 mb-2"
              >
                <div className="border-dashed border-2 rounded-xl px-3 py-1 flex flex-col justify-end mb-4 h-100">
                  <h2 className={`mb-0 ${requirePro ? 'blur-under-mh' : ''}`}>
                    <strong className="texto-ajustable">
                      {readableNumber(round(mediaOutcome, 3))}
                    </strong>
                  </h2>
                  <span>{t('Total')}</span>
                </div>
              </Col>
              <Col xxl={3} md={6} sm={12} xs={12}>
                <div
                  className={`mb-0 ${requirePro ? 'blur-under-mh' : ''} border-dashed border-2 border-green-500 rounded-xl px-3 py-1 flex flex-col justify-start`}
                >
                  <span className="outcome-increase-container mt-2 bg-green-500 bg-opacity-25 w-fit px-2 py-1 rounded-md text-green-600 pe-4 my-2">
                    {optimizeIncreaseOutcome}%
                  </span>
                  <h2 className="mb-0 texto-ajustable">
                    {readableNumber(round(optimizeOutcome, 3))}
                  </h2>
                  <span className="ms-1 pb-2 text-sm">
                    <i className="inline-flex items-center">
                      <FaPlusMinus />{' '}
                      {round((1 - accuracy) * optimizeOutcome, 3)}
                    </i>
                  </span>
                  <span className="display-blur">Optimized</span>
                  <span className="inline-flex items-center text-xs display-blur">
                    {t('With 95% confidence')}
                    <GiConfirmed className="ms-2" />
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="ps-0" xs={12}>
            <Row className="ms-3 mt-4">
              <Col className="mb-2" xs={12}>
                {t('Media ROI')}
              </Col>
              <Col
                xxl={3}
                md={6}
                sm={12}
                xs={12}
                className="mb-xl-0 mb-md-0 mb-sm-2 mb-2"
              >
                <div className="border-dashed border-2 rounded-xl px-3 py-1 flex flex-col justify-end mb-4 h-100">
                  <h2 className={`mb-0 ${requirePro ? 'blur-under-mh' : ''}`}>
                    <strong className="texto-ajustable">
                      {readableNumberMMM(ROI)}
                    </strong>
                  </h2>
                  <span>{t('Total')}</span>
                </div>
              </Col>
              <Col xxl={3} md={6} sm={12} xs={12}>
                <div
                  className={`${requirePro ? 'blur-under-mh' : ''} border-dashed border-2 border-green-500 rounded-xl px-3 py-1 flex flex-col justify-start`}
                >
                  <span className="outcome-increase-container mt-2 bg-green-500 bg-opacity-25 w-fit px-2 py-1 rounded-md text-green-600 pe-4 my-2">
                    {optimizeIncreaseOutcome}%
                  </span>
                  <h2 className="mb-0 texto-ajustable">
                    {readableNumberMMM(optimizedROI)}
                  </h2>
                  <span className="ms-1 pb-2 text-sm">
                    <i className="inline-flex items-center">
                      <FaPlusMinus /> {round(((1 - accuracy) * 1) / newCpa, 4)}
                    </i>
                  </span>
                  <span className="display-blur">{t('Optimized')}</span>
                  <span className="inline-flex items-center text-xs display-blur">
                    {t('With 95% confidence')}
                    <GiConfirmed className="ms-2" />
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
