import { isValidDate } from '../../utility/format'
import { getTextWidth } from '../../utility/model'

export default function YearlyLayer({
  offset = 50,
  start = 0,
  end = 0,
  innerWidth,
  xScale,
  innerHeight,
  margin,
  timeOffset = false,
  ignoreLessThanYear = false,
  extraWidth = 0,
  ...props
}) {
  if (typeof start === 'string') start = new Date(start)
  if (typeof end === 'string') end = new Date(end)
  innerWidth = innerWidth + extraWidth

  const from = isValidDate(start) ? start : new Date()
  const to = isValidDate(end)
    ? end
    : new Date(from.getTime() + 1000 * 60 * 60 * 24 * 7 * (2 + end - start))

  const diff = to.getTime() - from.getTime()
  const years = diff / (1000 * 60 * 60 * 24 * 365)
  if (years < 1 && ignoreLessThanYear) return <></>

  if (!Number.isNaN(years) && years > 0) {
    const steps = []
    let i = 0
    while (i < years && years < 20) steps.push(i++)

    let yearSpace = innerWidth / years
    if (years < 1) yearSpace = innerWidth + 10
    const yPos = innerHeight + offset
    const padding = 25

    let currentTimeOffset = 0
    if (timeOffset && typeof start === 'number') {
      const skip = start / 52.1429
      currentTimeOffset = Math.floor(skip)
    }

    return (
      <>
        <defs>
          <marker
            id="triangle_end_marker"
            markerWidth="20"
            markerHeight="20"
            refX="10"
            refY="5"
            orient="auto"
          >
            <polygon
              points="0 0, 10 5, 0 10"
              fill="var(--mmm-tables-graph-year-split)"
            />
          </marker>
          <marker
            id="triangle_begin_marker"
            markerWidth="20"
            markerHeight="20"
            refX="0"
            refY="5"
            orient="auto"
          >
            <polygon
              points={`10 0, 0 5, 10 10`}
              fill="var(--mmm-tables-graph-year-split)"
            />
          </marker>
        </defs>
        {steps.map((year, i) => {
          const label = `Year ${year + 1 + currentTimeOffset}`
          const textWidth = getTextWidth(label, '13px monospace') + padding * 2
          const endStartMax = innerWidth - yearSpace * year
          const endStart = Math.max(yearSpace / 2 - textWidth / 2, 0)
          return (
            <g transform={`translate(${yearSpace * year}, 0)`} key={year}>
              <path
                d={`M 0,${yPos} L${Math.max(
                  Math.min(endStartMax, endStart),
                  0,
                )},${yPos}`}
                stroke={'var(--mmm-tables-graph-year-split)'}
                strokeWidth="1"
                fill="transparent"
                markerStart="url(#triangle_begin_marker)"
              />
              <path
                d={`M 0,${yPos - 30 / 2} L 0,${yPos + 30 / 2}`}
                strokeWidth="2"
                fill="transparent"
                stroke={'var(--mmm-tables-graph-year-split)'}
              />

              {!(yearSpace * year + yearSpace / 2 + textWidth > innerWidth) && (
                <>
                  <text
                    className="label-media-contribution"
                    x={yearSpace / 2 - textWidth / 2 + padding}
                    y={yPos + 5}
                    fill={'var(--mmm-tables-graph-year-split)'}
                  >
                    {label}
                  </text>
                  <path
                    d={`M ${
                      yearSpace / 2 + textWidth / 2
                    },${yPos} L${yearSpace},${yPos}`}
                    stroke={'var(--mmm-tables-graph-year-split)'}
                    strokeWidth="1"
                    fill="transparent"
                    markerEnd="url(#triangle_end_marker)"
                  />
                </>
              )}
            </g>
          )
        })}
      </>
    )
  }

  return <></>
}
