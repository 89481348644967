import { OverlayTrigger, Popover, Row, Col, Tooltip } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

export default function HelpTooltip({
  message,
  iconProps = { size: 18, color: 'var(--mmm-main-color)' },
  warning = false,
  helpLink,
  tooltip = false,
  color,
  ...props
}) {
  const HelpComponent = tooltip ? Tooltip : Popover
  const finalIconProps = {
    ...iconProps,
    color: color || iconProps.color,
  }
  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="auto"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <HelpComponent
          {...props}
          className={`popover-help-tooltip pointer-events-none ${props.className ?? ''}`}
          style={{ ...(props.style ?? {}), zIndex: 99999 }}
        >
          <Row className="py-4 pe-4 ps-3 ms-1">
            <Col className="text-slate-900" xs={12}>
              {message}
            </Col>
          </Row>
        </HelpComponent>
      )}
    >
      <span {...props} className={`${props.className ?? ''} help-tooltip p-2}`}>
        <FaInfoCircle {...finalIconProps} />
      </span>
    </OverlayTrigger>
  )
}
