import { useState, useMemo, useEffect } from 'react'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import { ResponsivePie } from '@nivo/pie'

import { getMMMOptimizedTable } from '../../services/model'
import { generateMMMColorMap } from '../../utility/model'
import Loader from '../Loader'
import { nivoProps } from '../../utility/model'
import { round, abbrNum } from '../../utility/format'
import '../../styles/mmmGraphs.css'
import { useAuth } from '../../providers/AuthProvider'

function SelectModel({ showPercentages, setShowPercentages }) {
  return (
    <div className="select-original-spend-units no-print">
      <ButtonGroup>
        <Button
          onClick={() => setShowPercentages(true)}
          className={`py-0 original button-original-spend ${
            showPercentages ? 'active-op' : ''
          }`}
          variant="secondary"
        >
          <strong
            style={{ minWidth: '20px' }}
            className="d-flex justify-content-center smallp"
          >
            %
          </strong>
        </Button>
        <Button
          onClick={() => setShowPercentages(false)}
          className={`py-0 original button-original-spend ${
            showPercentages ? '' : 'active-op'
          }`}
          variant={'secondary'}
        >
          <strong
            style={{ minWidth: '20px' }}
            className="d-flex justify-content-center smallp"
          >
            0.0
          </strong>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default function OriginalSpend({ model, height = 250, ...props }) {
  const queryClient = useQueryClient()
  const [showPercentages, setShowPercentages] = useState(true)
  const { token } = useAuth()

  const { data, isLoading } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      let data = null
      try {
        const response = await getMMMOptimizedTable({
          modelId: model.id,
          is_outcome_optimization: false,
          weekly: false,
          original: true,
          token,
        })
        if (response.ok) data = await response.json()
      } catch (e) {
        console.info('Error retrieving mmm optimized table spend')
      }
      return data
    },
    { staleTime: Infinity },
  )
  useEffect(() => {
    if (!data && model) {
      const to = setInterval(() => {
        queryClient.invalidateQueries(['mmm-optimized-table-spend', model.id])
      }, 3000)
      return () => clearInterval(to)
    }
  }, [data])

  const datasets = useMemo(() => {
    if (data) {
      const colorMap = generateMMMColorMap(model)
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const datasets = data.data.reduce((ac, d) => {
        ac[d[keymap['index']]] = {
          spend: d[keymap['Media spend']],
          color: colorMap[d[keymap['index']]],
        }
        return ac
      }, {})

      const { total } = data.data.reduce(
        (acc, d) => {
          acc.total += d[keymap['Media spend']]
          return acc
        },
        { total: 0 },
      )
      Object.entries(datasets).forEach(([k, v]) => {
        v.spendPerc = (100 * v.spend) / total
      })
      const keys = Object.keys(datasets)
        .sort((a, b) => datasets[b].spendPerc - datasets[a].spendPerc)
        .slice(0, 10)
      return keys.reduce((ac, k) => {
        ac[k] = datasets[k]
        return ac
      }, {})
    }

    return null
    // eslint-disable-next-line
  }, [data])

  const shownData = datasets
    ? [
        Object.entries(datasets).map(([k, v]) => ({
          id: k,
          color: v.color,
          label: k,
          value: v.spendPerc,
        })),
        Object.entries(datasets).map(([k, v]) => ({
          id: k,
          color: v.color,
          label: k,
          value: v.spend,
        })),
      ]
    : null

  const csvData = shownData
    ? [
        ['channel', 'absolute effect', 'relative effect'],
        ...Object.entries(datasets).map(([k, v]) => [k, v.spend, v.spendPerc]),
      ]
    : null

  return (
    <Row
      className="h-100 data-holder relative"
      data-csv={encodeURIComponent(JSON.stringify(csvData))}
      data-filename={`current_media_mix__${model.id}`}
    >
      <SelectModel
        showPercentages={showPercentages}
        setShowPercentages={setShowPercentages}
      />
      {isLoading || !shownData ? (
        <Loader />
      ) : (
        <>
          <Col
            className="graph relative"
            xs={8}
            style={{ minHeight: `${height}px` }}
          >
            <ResponsivePie
              {...nivoProps}
              fit={false}
              data={showPercentages ? shownData[0] : shownData[1]}
              margin={{
                top: 10,
                bottom: 10,
                left: 0,
                right: 20,
              }}
              innerRadius={0.25}
              colors={(d) => d.data.color}
              padAngle={0.7}
              cornerRadius={0}
              height={height}
              activeOuterRadiusOffset={8}
              valueFormat={
                showPercentages ? (n) => `${round(n, 0)}%` : (n) => abbrNum(n)
              }
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]],
              }}
              enableArcLabels={true}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={15}
              arcLinkLabelsTextColor={(d) => d.data.color}
              arcLinkLabelsThickness={1}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={15}
              arcLabelsTextColor={'white'}
              defs={[]}
              fill={[]}
              legends={[]}
              layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
              {...props}
            />
          </Col>
          <Col
            xs={4}
            className="px-0 overflow-hidden flex items-center relative"
            style={{
              // maxWidth: `calc(100% - ${height + 130}px`,
              maxHeight: `${height}px`,
            }}
          >
            <div className="w-100">
              {(Array.isArray(shownData?.[0]) ? shownData?.[0] : [])
                .slice(0, 10)
                .map((d) => (
                  <Row key={d.id} className="items-center">
                    <Col
                      xs={12}
                      className="pe-0 flex items-center"
                      style={{ maxWidth: '20px' }}
                    >
                      <span
                        className="mmm-legend-ball"
                        style={{ backgroundColor: `${d.color}` }}
                      ></span>
                    </Col>
                    <Col
                      className="px-0 inline-flex items-start"
                      xs={12}
                      style={{ maxWidth: 'calc(100% - 20px)' }}
                    >
                      <div
                        title={d.id}
                        className="inline-flex items-start text-truncate w-100 ms-1 min-h-full"
                        style={{ marginTop: '0px', fontSize: '10px' }}
                      >
                        <strong>{d.id}</strong>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}
