import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { Routes, Route, useNavigate } from 'react-router-dom'

import ModelContent from './ModelContent'
import AppTable from './DataTable'
import ContentNavbar from './ContentNavbar'
import { useAuth } from '../providers/AuthProvider'
import mmmLogo from '../assets/logos/MMM_icon_c.svg'
import UserGuide from './UserGuide'
import License from './License'
import { useTranslation } from 'react-i18next'

const Content = ({ ...props }) => {
  const { user, isEssential, showStartTrial } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (user) {
      const script = document.createElement('script')
      script.src = '//js-eu1.hs-scripts.com/143941512.js'
      document.body.appendChild(script)
    }
  }, [user])

  useEffect(() => {
    if (user?.plan === 'Free') navigate('/user-guide')
  }, [user?.plan])
  if (!user) {
    return (
      <Container id="main-content" fluid>
        <Row className={`${isEssential ? 'essential-plan' : 'pro-plan'}`}>
          <Col
            className="relative flex flex-col items-center justify-center"
            xs={12}
            style={{ minHeight: 'calc(100vh - 500px)' }}
          >
            <img src={mmmLogo} alt="Loading" className="w-fit loading-item" />
            <span className="mt-2 h5 loading-tooltip">Loading</span>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container
      id="main-content"
      className={`${isEssential ? 'essential-plan' : ''} relative px-[40px] ${showStartTrial ? 'pt-[60px]' : 'pt-[40px]'}`}
      fluid
    >
      <Routes>
        <Route
          path="user-guide"
          element={
            <>
              <ContentNavbar />
              <UserGuide />
            </>
          }
        />
        <Route index element={<AppTable />} />
        <Route
          path="license"
          element={
            <>
              <ContentNavbar />
              <License />
            </>
          }
        />
        <Route
          path="activity"
          element={
            <>
              <ContentNavbar />
              <div>Activity</div>
            </>
          }
        />
        <Route
          path="issues"
          element={
            <>
              <ContentNavbar />
              <div>Issues</div>
            </>
          }
        />
        <Route path="/model/:modelId" element={<ModelContent {...props} />} />
      </Routes>
    </Container>
  )
}

export default Content
