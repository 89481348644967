import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import mmmLogo from '../../assets/logos/MMM_icon_c.svg'
import ButtonMMM from '../ButtonMMM'
import { useAuth } from '../../providers/AuthProvider'

export default function Splash({
  connectors,
  currentStep,
  setCurrentStep,
  setShowPlanData,
}) {
  const { user } = useAuth()
  const { t } = useTranslation()
  const validConnectors = Array.isArray(connectors) && connectors.length > 0

  return (
    <Row>
      <Col className="flex justify-center" xs={12}>
        <Image src={mmmLogo} alt="Morpheus" width={156} />
      </Col>
      <Col className="text-5xl font-bold text-center mt-3" xs={12}>
        <span>{t('Welcome to')}</span>{' '}
        <span className="font-span-gradient">{t('Morpheus')}</span>
      </Col>
      <Col xs={12} className="mt-2 text-center text-3xl font-bold">
        {t('Marketing Mix Modeling')}
      </Col>
      <Col xs={12} className="text-center text-xl font-bold mt-5">
        {t("Let's")}
        {'  '}
        <span className="font-span-gradient">{t('set up your data')}</span>
        {'  '}
        {t('for the best insights.')}
      </Col>
      <Col className="text-center text-lg  mt-5" xs={12}>
        {t(
          validConnectors
            ? "We've detected active Dataslayer connectors."
            : "We didn't detect any active Dataslayer connectors.",
        )}
      </Col>
      <Col className="text-center text-lg  mt-1" xs={12}>
        {t(
          validConnectors
            ? 'Ready to get started?'
            : "Let's get you connected!",
        )}
      </Col>
      <Col className="mt-5 flex justify-center" xs={12}>
        <ButtonMMM
          onClick={() => {
            if (user?.plan === 'Trial') {
              setShowPlanData(true)
            }
            setCurrentStep(currentStep + 1)
          }}
        >
          {t('GET STARTED')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}
